import { AxiosError } from "axios";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import FormControl from "components/Container/Content/FormControl/FormControl";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import Input from "components/Input/Input";
import Alert from "components/Popup/Alert/Alert";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import TextArea from "components/TextArea/TextArea";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAiCore } from "hooks/feature/aiCore/useAiCore";
import { useEffect, useState } from "react";
import styles from "styles/module/information.module.scss";
import { AiCoreParams, AiCoreRegistParams, AiCoreTask } from "types/feature/aiCore/aiCore";
import { TASK_VIEW_TYPE } from "types/feature/project/project";
import { isValidString } from "utils/common-utils";
import { AICoreInformationProps } from "./AICoreInformation.type";

export default function AICoreInformation({ taskName, modelName, modelId, setTaskName, setModelName, setModelId, setDescription, description, aiCoreId, isDeleted, isRegist }: AICoreInformationProps) {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getTaskList, updateAICore, deleteAICore, postAiCore, getCheckModelID } = useAiCore();

  const [isShowAlert, setIsShowAlert] = useState(false);

  const [taskOption, setTaskOption] = useState<CommonOptionType>();

  const [editTaskOption, setEditTaskOption] = useState(taskOption);
  const [editModelName, setEditModelName] = useState(modelName);
  const [editModelId, setEditModelId] = useState(modelId);
  const [editDescription, setEditDescription] = useState(description);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isErrorModelId, setIsErrorModelId] = useState(false);
  const [isErrorModelName, setIsErrorModelName] = useState(false);

  const [isDuplicatedId, setIsDuplicatedId] = useState(false);

  /* Task Name Options */
  const [taskNameOptions, setTaskNameOptions] = useState<CommonOptionType[]>([]);

  useEffect(() => {
    fetchTaskList();
  }, []);

  useEffect(() => {
    setEditTaskOption(taskOption);
    taskOption && setTaskName(taskOption.label);
  }, [taskOption]);

  useEffect(() => {
    setTaskOption(taskNameOptions.find(i => i.label === taskName) as CommonOptionType)
  }, [taskName]);

  useEffect(() => {
    setEditModelName(modelName);
  }, [modelName]);

  useEffect(() => {
    setEditModelId(modelId);
  }, [modelId]);

  useEffect(() => {
    setEditDescription(description);
  }, [description]);

  useEffect(() => {
    setIsErrorModelName(editModelName ? editModelName.length > 100 || !isValidString(editModelName, [' '], true) : false);
  }, [editModelName]);

  useEffect(() => {
    editModelId && setIsErrorModelId(editModelId.length > 200 || !isValidString(editModelId, ['-', '_']));
  }, [editModelId]);

  useEffect(() => {
    !editModelId && setIsDuplicatedId(false);
    (isRegist || isEditMode) && editModelId !== '' && fetchCheckDuplicationId();
  }, [editModelId, editTaskOption])

  useEffect(() => {
    setIsSaveDisabled(!editTaskOption || !editModelName || !editModelId || isErrorModelName || isErrorModelId || isDuplicatedId);
  }, [editTaskOption, editModelName, editModelId, isErrorModelName, isErrorModelId, isDuplicatedId]);

  const handleModelNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditModelName(value);
  }

  const handleModelIdInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditModelId(value);
  }

  const fetchCheckDuplicationId = async () => {
    if (!editTaskOption) return;
    const result = await getCheckModelID(editTaskOption.value.code, editModelId);
    setIsDuplicatedId(result instanceof AxiosError);
  }

  const handleChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditDescription(e.target.value);
  }

  const handleCancelClick = () => {
    setEditTaskOption(taskOption);
    setEditModelName(modelName);
    setEditModelId(modelId);
    setEditDescription(description);
    setIsEditMode(false);
    isRegist && navigateToReplacePath(RoutePath.aicore);
  }

  const handleSaveClick = async () => {
    const result = isRegist ? await fetchPostAiCore() : await fetchUpdateAiCore();
    if (result) {
      editTaskOption && setTaskOption(editTaskOption);
      setModelName(editModelName);
      setModelId(editModelId);
      setDescription(editDescription);
      setIsEditMode(false);
      isRegist && navigateToReplacePath(`${RoutePath.aicoreDetail}?${URL_QUERY.AICORE_ID}=${result.id}`);
    }
  }

  const handleDeleteClick = () => {
    setIsShowAlert(true);
  }

  const handleEditClick = () => {
    setIsEditMode(true);
  }

  const fetchTaskList = async () => {
    const result = await getTaskList();
    const options: CommonOptionType[] = [];
    result.forEach((task) => {
      const taskValue = task.code;
      const option: CommonOptionType = {
        label: TASK_VIEW_TYPE[taskValue],
        value: task,
        disabled: taskValue === 'IMAGE_SUPER_RESOLUTION'
      }
      options.push(option);
    });

    setTaskNameOptions(options);
  }

  const fetchUpdateAiCore = async () => {
    if (!aiCoreId) return;
    const param: AiCoreParams = {
      id: aiCoreId,
      modelName: editModelName,
      description: editDescription,
    }
    const result = await updateAICore(param);
    return result;
  }

  const fetchDeleteAiCore = async () => {
    if (!aiCoreId) return;
    const result = await deleteAICore(aiCoreId);
    if (result) {
      setIsShowAlert(false);
      navigateToReplacePath(RoutePath.aicore);
    }
  }

  const fetchPostAiCore = async () => {
    const param: AiCoreRegistParams = {
      task: (editTaskOption?.value as AiCoreTask).code,
      modelName: editModelName,
      modelId: editModelId,
      description: editDescription
    };
    const result = await postAiCore(param);
    return result;
  }

  return (
    <>
      <div className={styles["aicore-information__container"]}>
        <ContentWrap>
          <SubTitleContainer title="Information" textType={TextType.h3} iconPath={ICON.ICON_INFORMATION} rightComponent={
            <div className={styles["right-btn__area"]}>
              <div className={styles["btn-comp"]}>
                {isRegist && (
                  <>
                    <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                    <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                  </>
                )}
                {isDeleted !== undefined && !isDeleted && !isRegist && (
                  <>
                    {isEditMode ? (
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                        <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                      </>
                    ) :
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Delete" onClick={handleDeleteClick}></Button>
                        <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Edit" onClick={handleEditClick}></Button>
                      </>
                    }
                  </>
                )}
                {isDeleted !== undefined && isDeleted &&
                  <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Deleted" disabled></Button>
                }
              </div>
            </div>
          }></SubTitleContainer>
          <ContentBox>
            <FormControl name="Task Name" required height={56}
              control={
                // <div className={styles["control_task__wrap"]}>
                //   <EditableSelectBox width={360} maxLength={100} selected={editableSelectedOption} setSelected={setEditableSelectedOption} options={editableTypeOptions} setOptions={setEditableTypeOptions} onChangeSelect={handleChangeDisplayType} placeholder="Please input task name"></EditableSelectBox>
                //   {/* <Checkbox label="Test Mode" id="testMode"></Checkbox> */}
                // </div>
                // <Input placeholder="Please input task name" width={360} maxLength={100}></Input>
                isRegist || isEditMode ?
                  <SelectBox selected={editTaskOption} setSelected={setEditTaskOption} placeholder="Please select task" options={taskNameOptions} width={360} disabled={isEditMode}></SelectBox> :
                  <span className={styles["info-span"]}>{taskName}</span>
              }
            ></FormControl>
            <FormControl name="Model Name" required height={56}
              control={
                isRegist || isEditMode ?
                  <Input showWarning={isErrorModelName} warningText="You can only up to 100 character in enter english, korean, numbers, and space." placeholder="Please input model name" defaultValue={editModelName} onChange={handleModelNameInputValue} width={360}></Input> :
                  <span className={styles["info-span"]}>{modelName}</span>
              }
            ></FormControl>
            <FormControl name="Model ID" required height={56}
              control={
                isRegist || isEditMode ?
                  <Input debounce showWarning={isErrorModelId || isDuplicatedId} warningText={isDuplicatedId ? "A duplicate id exists." : `You can only up to 200 character in enter english, numbers, and hyphens (-).`} placeholder="Please input model id" defaultValue={editModelId} onChange={handleModelIdInputValue} width={360} disabled={isEditMode}></Input> :
                  <span className={styles["info-span"]}>{modelId}</span>
              }
            ></FormControl>
            <FormControl name="Description"
              control={
                isRegist || isEditMode ?
                  <TextArea name='text' maxLength={5000} value={editDescription} onChange={handleChangeDescription} placeholder="Please input the text" width={480} ></TextArea> :
                  // <span className={styles["info-span"]}>{description}</span>
                  <span className={styles["description"]}>{description?.split('\n').map((line, i) => {
                    return (
                      <span key={i}>{line}</span>
                    )
                  })}</span>
              }
            ></FormControl>
          </ContentBox>
        </ContentWrap>
      </div>
      {isShowAlert &&
        <Alert
          onClosePopup={() => setIsShowAlert(false)}
          firstButton={
            <Button color={BUTTON_COLOR.white} text="Cancel" onClick={() => setIsShowAlert(false)} width={120}></Button>
          }
          secondButton={
            <Button color={BUTTON_COLOR.black} text="Confirm" onClick={() => fetchDeleteAiCore()}></Button>
          }
        >
          <span>If you delete, you can’t restore it.<br />Do you want to proceed?</span>
        </Alert>
      }
    </>

  )
}