import { TextType } from 'components/Title/Title.type';
import { MouseEvent } from 'react';

export type ChipButtonProps = {
  loading?: boolean;
  text?: string;
  textColor?: ChipTextColor;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color?: ChipButtonColor;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactElement;
  buttonStyle?: string;
  textStyle?: TextType;
  id?: string;
  iconPath?: string;
  width?: number;
  iconWidth?: number;
  iconHeight?: number;
};

export const CHIP_BUTTON_COLOR = {
  blue: 'blue',
  black: 'black',
  gray: 'gray',
} as const;

export type ChipButtonColor = (typeof CHIP_BUTTON_COLOR)[keyof typeof CHIP_BUTTON_COLOR];

export const CHIP_TEXT_COLOR = {
  gray: 'gray',
  green: 'green',
  white: 'white',
  black: 'black'
}

export type ChipTextColor = (typeof CHIP_TEXT_COLOR)[keyof typeof CHIP_TEXT_COLOR];