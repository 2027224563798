import classNames from "classnames";
import { PROCESSING_VIEW_TYPE, ProcessingType } from "components/Request/ProjectRequest.type";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { getEE2UserDomain } from "utils/common-utils";
import styles from "./List.module.scss";
import { Column, ListProps } from './List.type';

export default function List({ columns, datas, colStyles }: ListProps) {

  const initColumnHeader = useMemo(() => {
    return columns.map((column, index) => (
      <th key={index} style={column.align && { textAlign: column.align }}>{column.header}</th>
    ));
  }, []);

  const initEmptyRow = useMemo(() => {
    return <tr className={styles["-no-data"]}>
      <td colSpan={columns.length}>
        <div className={styles["-no-data-div"]}>No data available</div>
      </td>
    </tr>;
  }, []);


  const getLinkBtn = (url: string) => {
    return <Link to={`${getEE2UserDomain()}/${url}`}><span className={''}>{'Go To Main'}</span></Link>
  };

  /**
   * @description 2024.07.31 comment by kay : 코드 복잡성을 줄이고자 분리... 
   *              column field에 따라 element를 반환한다
   * @param row - any
   * @param column - Column
   * @return Element
   */
  const initColumnInner = (row: any, column: Column) => {
    switch (column.field) {
      case 'url': return getLinkBtn(row[column.field]);
      case 'iconButton': {
        return <div className={styles["icon-button"]} onClick={() => column.onClick && column.onClick(row)}>{row[column.field]}</div>
      }
      case 'processing': {
        return <span>{PROCESSING_VIEW_TYPE[row[column.field] as ProcessingType]}</span>;
      }
      default: {
        return <span onClick={() => column.onClick && column.onClick(row['id'])}>{row[column.field]}</span>;
      }
    }
  }

  const initRowColumn = (row: any, column: Column, colIndex: number) => {
    return <td key={colIndex} className={classNames(column.className && column.className.map(name => styles[name]), (column.field === 'status' || column.field === 'processing') && styles[String(row[column.field]).toLowerCase()])} style={column.align && { textAlign: column.align }}>
      {initColumnInner(row, column)}
    </td>
  }

  const initListRow = () => {
    return datas.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {columns.map((column, colIndex) => initRowColumn(row, column, colIndex))}
      </tr>
    ))
  }

  return (
    <table className={styles["list-table"]}>
      <colgroup>
        {colStyles?.map((style, index) => (
          <col key={index} style={style} />
        ))}
      </colgroup>
      <thead>
        <tr className={styles["list-row-tr"]}>
          {initColumnHeader}
        </tr>
      </thead>
      <tbody>
        {datas.length === 0 ? initEmptyRow : initListRow()}
      </tbody>
    </table>
  )
}