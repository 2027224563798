import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ChipContainer from "components/Container/Chip/ChipContainer";
import { ICON } from "constants/icons";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useEffect, useMemo, useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { searchStore } from "stores/search-stores";
import { setFilterQuery } from "utils/filter-utils";
import styles from "./Filter.module.scss";
import { FilterDatas, FilterOptionType, FilterProps } from "./Filter.type";
import FilterRow from "./FilterRow/FilterRow";

export default function Filter({ filterDatas, setFilterDatas }: FilterProps) {
  const { filterOptions, setFilterOptions } = searchStore();
  const [showContent, setShowContent] = useState(false);
  const [showSelectAllBtn, setShowSelectAllBtn] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState<boolean | undefined>();
  const [titleCheckedArr, setTitleCheckedArr] = useState<boolean[]>([]);
  const [checkedFilterOptions, setCheckedFilterOptions] = useState<string[]>([]);
  const [filterHeaderMap] = useState<Map<string, boolean>>(new Map());
  const { setSearchParam } = useCustomSearchParams();

  useEffect(() => {
    if (!filterOptions) return;
    const checked = filterOptions.flatMap(section => section.values.filter(item => item.checked));
    filterOptions && setTotalCount(checked.length)
    const upatedCheckedArr = [...titleCheckedArr];
    filterOptions?.forEach((data, i) => {
      upatedCheckedArr[i] = data.values.filter(i => i.checked).length === data.values.filter(i => !i.disabled).length;
    });
    setTitleCheckedArr(upatedCheckedArr);
    setCheckedFilterOptions(checked.map(item => item.label));
    filterOptions && setTotalCount(filterOptions.flatMap(section => section.values.filter(item => item.checked)).length)
  }, [filterOptions]);

  useEffect(() => {
    return () => { filterHeaderMap.clear() }
  }, [])

  // useEffect(() => {
  // }, [filterOptions])

  const initFilterHaederMap = () => {
    filterOptions?.forEach((data) => filterHeaderMap.set(data.title, false));
  }

  const handleSelectAll = (isCheck: boolean) => {
    if (filterOptions && setFilterOptions) {
      const updatedFilterDatas = [...filterOptions];
      updatedFilterDatas.map(data => data.values.forEach(e => e.checked = e.disabled ? e.checked : isCheck));
      setFilterOptions(updatedFilterDatas);
      updatedQueryValue(updatedFilterDatas);
    }
    for (const key of Array.from(filterHeaderMap.keys())) updateAllSelectBy(key, isCheck);
    setShowSelectAllBtn(!isCheck);
    setIsSelectAll(isCheck);
  }

  const updateAllSelectBy = (key: string, is: boolean) => {
    filterHeaderMap.set(key, is);
  }

  const updateFilterDatas = (title: string, value: FilterOptionType[]) => {
    if (!filterOptions || !setFilterOptions) return;
    const updatedFilterDatas = [...filterOptions];
    updatedFilterDatas.map(data => data.title === title ? data.values = value : data);
    setFilterOptions(updatedFilterDatas);
  }

  const handleOutsideClick = () => {
    setShowContent(false)
  }

  const removeChip = (name: string) => {
    if (!filterOptions || !setFilterOptions) return;
    const updatedFilterOptions = [...filterOptions];
    updatedFilterOptions.forEach(section => {
      section.values.forEach(item => {
        if (item.label === name) {
          item.checked = false;
        }
      });
    });
    setFilterOptions && setFilterOptions(updatedFilterOptions);
    updatedQueryValue(updatedFilterOptions);
  }

  const updatedQueryValue = (updatedFilterOptions: FilterDatas[]) => {
    updatedFilterOptions.forEach((option) => {
      setFilterQuery(option.title, option.values, setSearchParam);
    })
  }

  const initFilterButton = () => {
    return <button className={classNames(styles["button__container"], { [styles["opened"]]: showContent }, { [styles["selected"]]: totalCount > 0 })} onClick={() => setShowContent(!showContent)}>
      <div className={styles["title__wrap"]}>
        <img width={10} height={8.5} src={totalCount > 0 && !showContent ? ICON.ICON_FILTER_BLACK : ICON.ICON_FILTER_GRAY} alt='' />
        <span>Filter</span>
      </div>
    </button>;
  }

  const initFilterHeader = () => {
    return <div className={styles["content__header"]}>
      <div className={styles["title__wrap"]}>
        <img width={10} height={8.5} src={ICON.ICON_FILTER_BLACK} alt='' />
        <span>Filter</span>
        <span className={styles["-total-count"]}>{totalCount}</span>
      </div>
      {initFilterButtonWrap()}
    </div>
  }

  const initFilterButtonWrap = () => {
    return <div className={styles["button__wrap"]}>
      {totalCount > 0 && <Button text="Clear" color={BUTTON_COLOR.white} size={BUTTON_SIZE[45]} onClick={() => handleSelectAll(false)} />}
      {showSelectAllBtn && <Button text="Select All" color={BUTTON_COLOR.white} size={BUTTON_SIZE[45]} width={73} onClick={() => handleSelectAll(true)} />}
    </div>;
  }

  const initFilterBody = useMemo(() => {
    filterHeaderMap.size === 0 && initFilterHaederMap();
    return filterOptions?.map((data, i) => {
      return <FilterRow key={i} titleChecked={titleCheckedArr[i]} props={data} totalCount={totalCount} setTotalCount={setTotalCount} isSelectAll={isSelectAll} setChildenAllSelect={updateAllSelectBy} isChildSelectAll={filterHeaderMap.get(data.title)!} updatedFilterDatas={updateFilterDatas}></FilterRow>
    })
  }, [filterOptions]);

  return (
    <div className={styles["filter__container"]}>
      {initFilterButton()}
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <div className={styles["filter_content__container"]} style={{ display: showContent ? 'inline-flex' : 'none' }}>
          {initFilterHeader()}
          <div className={styles["content__area"]}>
            <div className={styles["filter__grid"]}>
              {initFilterBody}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
      <ChipContainer chips={checkedFilterOptions} onRemove={removeChip}></ChipContainer>
    </div>
  )
}