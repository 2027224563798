import React from 'react';
import styles from './ModalContainer.module.scss';
import { ModalContainerProps } from './ModalContainer.type';

export default function ModalContainer({ children }: ModalContainerProps) {
  return (
    <div className={styles["modal__container"]} onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}>
      <div className={styles["modal__wrap"]}>{children}</div>
    </div>
  );
};
