import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import Alert from "components/Popup/Alert/Alert";
import MyPagePopup from "components/Popup/MyPagePopup/MyPagePopup";
import { ICON } from "constants/icons";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAuth } from "hooks/feature/login/useAuth";
import { useState } from "react";
import styles from "./Sidebar.module.scss";

export default function Sidebar() {
  const { navigateToReplacePath } = useCustomNavigate();
  const [showMyPage, setShowMyPage] = useState(false);
  const [showLogout, setShowLogout] = useState(false);

  const { postLogout } = useAuth();

  const handleLogout = async () => {
    await postLogout();
    navigateToReplacePath(RoutePath.login);
  }

  return (
    <div className={styles["sidebar__container"]}>
      <button className={styles["button__wrap"]}>
        {/* <div className={styles["-bar"]}></div>
        <div className={styles["-icon__wrap"]}>
          <div className={classNames(styles["-icon"])}>
            <img src={ICON.ICON_CHANGE_ROLE} style={{ width: 27, height: 23 }} alt="" />
          </div>
        </div> */}
        <div className={styles["-bar"]}></div>
        <div className={styles["-icon__wrap"]} onClick={() => setShowMyPage(true)}>
          <div className={classNames(styles["-icon"])}>
            <img src={ICON.ICON_USER} style={{ width: 24, height: 24 }} alt="" />
          </div>
        </div>
        <div className={styles["-bar"]}></div>
        <div className={styles["-icon__wrap"]} onClick={() => setShowLogout(true)}>
          <div className={classNames(styles["-icon"])}>
            <img src={ICON.ICON_EXIT} style={{ width: 20, height: 19 }} alt="" />
          </div>
        </div>
      </button>
      {showMyPage &&
        <MyPagePopup onClosePopup={() => setShowMyPage(false)}></MyPagePopup>
      }
      {showLogout &&
        <Alert onClosePopup={() => setShowLogout(false)}
          firstButton={
            <Button color={BUTTON_COLOR.black} text="Confirm" onClick={handleLogout} width={120}></Button>
          }>
          <span>Do you want to leave Earth Eye?</span>
        </Alert>
      }
    </div>
  )
}