import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import Alert from "components/Popup/Alert/Alert";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { useAiCore } from "hooks/feature/aiCore/useAiCore";
import { useEffect, useMemo, useState } from "react";
import styles from "styles/module/editrow.module.scss";
import { AiCoreVersionParams, AiCoreVersionResponse } from "types/feature/aiCore/aiCore";
import { setVersion } from "utils/common-utils";
import VersionRow from "./EditRow/VersionRow";
import { VersionType } from "./EditRow/VersionRow.type";
import { VersionProps } from "./Version.type";

export default function AICoreVersion({ aiCoreId, isDeleted }: VersionProps) {
  const { getAICoreVersionList, postAiCoreVersion, deleteAICoreVersion, updateAICoreVersion } = useAiCore();

  const [isShowAlert, setIsShowAlert] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState<number | undefined>();
  const [versionData, setVersionData] = useState<VersionType[]>([]);
  // const [versionList, setVersionList] = useState<string[]>([]); //중복 체크를 위한 버전 리스트

  const activeVersionLength = useMemo(() => {
    return versionData.filter(version => !version.isDeleted).length;
  }, [versionData])

  const columns: Column[] = [
    { header: 'Version', field: 'version', align: ALIGN_TYPE.center, required: true },
    { header: 'Release Note', field: 'note', align: ALIGN_TYPE.left },
    { header: '', field: 'buttons', align: ALIGN_TYPE.center, required: false },
  ]

  useEffect(() => {
    fetchVersionList();
  }, [aiCoreId]);


  const handleClickAddVersion = () => {
    const newNumber = 0;
    const newVersionValue = versionData.length > 0 ? String(setVersion(versionData[0].version, 0.1)) : 1.0;
    const newVersion: VersionType = {
      no: newNumber,
      version: String(newVersionValue),
      editMode: true,
      isSaved: false,
      isDeleted: false,
      note: ''
    }
    let updatedVersionData = [...versionData, newVersion];
    updatedVersionData.sort((a, b) => { return Number(b.version) - Number(a.version) }).map((version, i) => version.no = i + 1);
    setVersionData(updatedVersionData);
  }

  const handleClickSaveVersion = async (version: VersionType): Promise<boolean> => {
    const result = await fetchPostVersion(version);
    if (result) {
      await fetchVersionList();
      return true;
    }
    return false;
  }

  const handleClickDeleteVersion = (isSaved: boolean, id?: number, index?: number,) => {
    if (id) {
      setDeleteTargetId(id);
      if (isSaved) {
        setIsShowAlert(true);
      }
    } else if (index !== undefined) {
      let updatedVersionData = [...versionData];
      updatedVersionData.splice(index, 1);
      setVersionData(updatedVersionData);
      setIsShowAlert(false);
      setDeleteTargetId(undefined);
    }
  }

  const handleClickDeleteConfirm = async (targetId?: number) => {
    const versionId = deleteTargetId !== undefined ? deleteTargetId : targetId;
    if (versionId === undefined) {
      setIsShowAlert(false);
      return;
    }

    const result = await fetchDeleteVersion(versionId);
    if (result) {
      await fetchVersionList();
      setIsShowAlert(false);
      setDeleteTargetId(undefined);
      return true;
    }
    return false;
  }

  const handleUpdateVersion = async (version: VersionType): Promise<boolean> => {
    const result = await fetchUpdateVersion(version);
    if (result) {
      await fetchVersionList();
      return true;
    }
    return false;
  }

  const fetchVersionList = async () => {
    const result = await getAICoreVersionList(aiCoreId);
    const versionList: VersionType[] = [];
    result.sort((a, b) => { return Number(b.version) - Number(a.version) })
    result.forEach((version, i) => {
      const item: VersionType = {
        no: i + 1,
        id: version.id,
        version: version.version,
        note: version.releaseNote,
        editMode: false,
        isSaved: true,
        isDeleted: version.isDeleted
      }
      versionList.push(item);
    });
    setVersionData(versionList);
    // setVersionList(versionList.map(i => i.version));
  }

  const fetchDeleteVersion = async (versionId: number) => {
    return await deleteAICoreVersion(aiCoreId, versionId);
  }

  const fetchPostVersion = async (version: VersionType): Promise<AiCoreVersionResponse> => {
    const param: AiCoreVersionParams = {
      aiCoreId: aiCoreId,
      version: version.version,
      releaseNote: version.note || ''
    }
    return postAiCoreVersion(param);
  }

  const fetchUpdateVersion = async (version: VersionType): Promise<AiCoreVersionResponse> => {
    const param: AiCoreVersionParams = {
      aiCoreId: aiCoreId,
      id: version.id,
      version: version.version,
      releaseNote: version.note || ''
    }
    return await updateAICoreVersion(param);
  }

  return (
    <>
      <ContentWrap>
        <SubTitleContainer title="Version" textType={TextType.h3} iconPath={ICON.ICON_SETTING}
          rightComponent={
            isDeleted === undefined ? <></> :
              !isDeleted ?
                <div className={styles["btn__area"]}>
                  <Button color={BUTTON_COLOR.black} text="Add Version" size={"size80"} iconPath={ICON.ICON_PLUS} width={112} onClick={handleClickAddVersion}></Button>
                </div> : <></>
          }></SubTitleContainer>

        <ContentBox>
          <table className={styles["table-aicore-detail"]}>
            <colgroup>
              <col width='9%'></col>
              <col width='83%'></col>
              <col width='8%'></col>
            </colgroup>
            <thead>
              <tr className={styles["list-row-tr"]}>
                {columns.map((column, index) => (
                  <th key={index} style={column.align && { textAlign: column.align }}>{column.header}{column.required && <span className={styles["-required"]}> *</span>}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {versionData.length === 0 && (
                <tr className={styles["-no-data"]}>
                  <td colSpan={columns.length}>
                    <div className={styles["-no-data-div"]}>No data available</div>
                  </td>
                </tr>
              )}
              {versionData.map((data, i) => (
                <VersionRow key={i} index={i + 1} version={data} isDeleteDisable={activeVersionLength === 1} aiCoreId={aiCoreId} onDelete={() => handleClickDeleteVersion(data.isSaved ? data.isSaved : false, data.id, i)} onUpdate={handleUpdateVersion} onSave={handleClickSaveVersion} setDeleteTargetId={setDeleteTargetId}></VersionRow>
              ))}
            </tbody>
          </table>
        </ContentBox>
        <div className={styles["split-line"]}></div>

      </ContentWrap>
      {isShowAlert &&
        <Alert
          onClosePopup={() => setIsShowAlert(false)}
          firstButton={
            <Button color={BUTTON_COLOR.white} text="Cancel" onClick={() => setIsShowAlert(false)} width={120}></Button>
          }
          secondButton={
            <Button color={BUTTON_COLOR.black} text="Confirm" onClick={() => handleClickDeleteConfirm()}></Button>
          }
        >
          <span>If you delete, you can’t restore it.<br />Do you want to proceed?</span>
        </Alert>
      }
    </>

  )
}