import classNames from "classnames";
import { MAP_OPTIONS, MAP_SCALES } from "constants/map/map-option";
import { useEffect, useState } from "react";
import { mapStore } from "stores/map-stores";
import { convert3857To4326 } from "utils/map/openlayers-utils";
import styles from "./ViewControl.module.scss";

export default function ViewControl() {
  const { map } = mapStore();
  const [meter, setMeter] = useState("0 m");
  const [feet, setFeet] = useState("0 ft");
  const [scale, setScale] = useState("1:1");
  const [latLng, setLatLng] = useState("0, 0");

  useEffect(() => {
    if (map) {
      map.on("moveend", handleTitlesLoaded);
      map.on("pointermove", handleMousemoveMap);
    }
  }, [map]);

  const handleTitlesLoaded = () => {
    const zoom = map?.getView().getZoom();
    const center = map?.getView().getCenter();
    if (typeof zoom === "undefined" || typeof center === "undefined") {
      return;
    }

    const [lng, lat] = convert3857To4326(center);

    const scale =
      (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);

    const minScale = Math.floor(scale * MAP_OPTIONS.MIN_SCALE_WIDTH);
    const maxScale = Math.ceil(scale * MAP_OPTIONS.MAX_SCALE_WIDTH);

    const ratio = Math.round(591657550.5 / Math.pow(2, zoom - 1));

    for (let i = 0; i < MAP_SCALES.length; i++) {
      if (i !== MAP_SCALES.length - 1) {
        if (
          (minScale <= MAP_SCALES[i].value &&
            MAP_SCALES[i].value <= maxScale) ||
          (minScale > MAP_SCALES[i].value && maxScale < MAP_SCALES[i + 1].value)
        ) {
          setupScaleValues(MAP_SCALES[i], ratio);
          break;
        }
      } else {
        setupScaleValues(MAP_SCALES[i], ratio);
      }
    }
  };

  const handleMousemoveMap = (e: any) => {
    const [lng, lat] = convert3857To4326(e.coordinate);
    setLatLng(`${lat.toFixed(3)}, ${lng.toFixed(3)}`);
  };

  const setupScaleValues = (
    scaleTextValues: { value: number; displayValue: string },
    ratio: number
  ) => {
    setMeter(scaleTextValues.displayValue);
    setFeet(`${Math.round(scaleTextValues.value * 3.28084)} ft`);
    setScale(`1:${ratio}`);
  };

  return (
    <div className={classNames(styles["map-view-control__container"])}>
      <ul>
        <li className={styles["-info-1"]}>
          <span>{meter}</span>
          <div className={styles["-u-line"]}></div>
        </li>
        <li className={styles["-cap-1"]}></li>
        <li className={styles["-info-2"]}>
          <span>{feet}</span>
          <div className={styles["-u-line"]}></div>
        </li>
        <li className={styles["-cap-2"]}></li>
        <li className={styles["-info-3"]}>
          <span className={styles["__icon"]}></span>
          <span>{latLng}</span>
        </li>
        <li className={styles["-cap-2"]}></li>
        <li className={styles["-info-4"]}>
          <span>{scale}</span>
        </li>
      </ul>
    </div>
  );
}
