import ContentContainer from "components/Container/Content/ContentContainer/ContentContainer";
import BlockTitleContainer from "components/Container/Title/BlockTitleContainer/BlockTitleContainer";
import AICoreInformation from "components/Information/AICore/AICoreInformation";
import { TextType } from "components/Title/Title.type";
import AICoreVersion from "components/Version/Version";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAiCore } from "hooks/feature/aiCore/useAiCore";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function AICoreDetail() {
  const location = useLocation();
  const { navigateToReplacePath, navigateToBack } = useCustomNavigate();
  const { getAICoreDetail } = useAiCore();

  const [isRegist, setIsRegist] = useState(location.pathname === RoutePath.aicoreDetailRegist);
  const [aiCoreId, setAiCoreId] = useState<number>();

  const [taskName, setTaskName] = useState('');
  const [modelName, setModelName] = useState('');
  const [modelId, setModelId] = useState('');
  const [description, setDescription] = useState('');
  const [isDeleted, setIsDeleted] = useState<boolean>();

  useEffect(() => {
    setIsRegist(location.pathname === RoutePath.aicoreDetailRegist);
    const url = new URLSearchParams(location.search);
    const aicoreId = url.get(URL_QUERY.AICORE_ID);
    if (!isRegist && !aicoreId) {
      navigateToReplacePath(RoutePath.aicore);
    }
    setAiCoreId(Number(aicoreId));
  }, [location]);

  useEffect(() => {
    fetchAICoreDetail();
  }, [aiCoreId]);

  const fetchAICoreDetail = async () => {
    if (!aiCoreId) return;
    const result = await getAICoreDetail(aiCoreId);
    setTaskName(result.task.name);
    setModelName(result.coreName);
    setModelId(result.modelId);
    setDescription(result.description);
    setIsDeleted(result.isDeleted);
  }

  return (
    <>
      <BlockTitleContainer title="AI Core Information" textType={TextType.h2} backButton onClickBackButton={navigateToBack}></BlockTitleContainer>
      <ContentContainer>
        <AICoreInformation taskName={taskName} setTaskName={setTaskName} modelName={modelName} setModelName={setModelName} modelId={modelId} setModelId={setModelId} description={description} setDescription={setDescription} aiCoreId={aiCoreId} isDeleted={isDeleted} isRegist={isRegist} ></AICoreInformation>
        {!isRegist && aiCoreId &&
          <AICoreVersion aiCoreId={aiCoreId} isDeleted={isDeleted}></AICoreVersion>
        }
      </ContentContainer>
    </>
  )
}