
import IconButton from "components/IconButton/IconButton";
import Title from "components/Title/Title";
import { ICON } from "constants/icons";
import styles from "./BlockTitleContainer.module.scss";
import { BlockTitleContainerProps } from './BlockTitleContainer.type';

export default function BlockTitleContainer({
  title,
  textType,
  rightComponent,
  backButton,
  onClickBackButton
}: BlockTitleContainerProps) {

  return (
    <div className={styles["block-title-container__container"]}>
      <div className={styles["block-title__wrap"]}>
        <div className={styles["left"]}>
          {backButton && <IconButton iconPath={ICON.ICON_ARROW_LEFT_BACK} onClick={onClickBackButton} buttonStyle="border-black"></IconButton>}
          <Title text={title} textType={textType} />
        </div>
        {rightComponent}
      </div>
    </div>
  );
};
