import { ICON } from 'constants/icons';
import styles from './ChipContainer.module.scss';
import { ChipContainerProps } from './ChipContainer.type';

export default function ChipContainer({ chips, onRemove }: ChipContainerProps) {
  return (
    <div className={styles["chip__container"]}>
      {chips.map(chip => (
        <div className={styles["-chip"]} key={chip}>
          <span>{chip}</span>
          <img src={ICON.ICON_CHIP_X} alt='' onClick={() => onRemove(chip)}></img>
        </div>
      ))}
    </div>
  );
};
