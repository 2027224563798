
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import IconButton from "components/IconButton/IconButton";
import ImageDataSetPopup from "components/Popup/ImageDataSetPopup/ImageDataSetPopup";
import { TextType } from "components/Title/Title.type";
import { idfParams } from "constants/auth";
import { DATE_FORMAT } from "constants/common";
import { ICON } from "constants/icons";
import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import dayjs from "dayjs";
import { useIdf } from "hooks/feature/idf/useIdf";
import { IMAGE_DATASET_IMAGE_CLASSIFICATION, IMAGE_DATASET_IMAGE_TYPE } from "pages/ImageData/ImageData.type";
import { useEffect, useMemo, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { projectStore } from "stores/project-stores";
import { ImageryInfoParams, ImageryInfoResponse, InforOriginal } from "types/feature/idf/idf";
import { getLocalStorage } from "utils/auth/localStorage-utils";
import { byteToGiga } from "utils/common-utils";
import RequestImageContentBox from "./RequestImageContentBox";
import { FileDataType, ImageDataType } from "./RequestImageContentBox.type";
import { RequestFileType, RequestImageSetType } from "./RequestImageDataSet.type";

export default function RequestImageDataSet() {
  const [isFindIDF, setIsFindIDF] = useState(false);
  const { imageDataIds, selectedVersion } = projectStore();
  const { setIdfPathList } = imageDataSetStore();
  const { getIdfToken, getImageryInfo } = useIdf();
  const { reset, projectImageDataSet, requestImageDataSet, filterStartDate, filterEndDate, filterBbox, setRequestImageDataSet, setProjectImageDataSet } = imageDataSetStore();
  const [isLoad, setIsLoad] = useState(false);
  const [isSelectable, setIsSelectable] = useState(false);

  useEffect(() => {
    setIsLoad(false);
    fetchImageryList();
  }, [imageDataIds, filterStartDate, filterEndDate, filterBbox]);

  useEffect(() => {
    setIsSelectable(selectedVersion !== undefined);
  }, [selectedVersion]);

  const defaultImageSet = [
    {
      no: 1,
      pathIdx: 0,
      pathName: 'IDF',
      images: []
    }
  ]

  const getFileImages = (files: InforOriginal[], pathIdx: number, isReload?: boolean) => {
    const targetPath = requestImageDataSet.find(i => i.pathIdx === pathIdx);
    const checkedIdxList = targetPath?.images.map(i => { return i.checked && i.idx });
    const result: RequestFileType[] = [];
    files.forEach((file, i) => {
      const data: RequestFileType = {
        no: i + 1,
        idx: file.idx,
        pathIndex: pathIdx,
        name: file.file_name,
        size: `${file.file_size ? byteToGiga(file.file_size) : 0} GB`,
        checked: isReload || !targetPath || !checkedIdxList ? false : checkedIdxList?.includes(file.idx)
      }
      result.push(data);
    });
    return result;
  }

  const parseImageSetData = (images: ImageryInfoResponse[], isReload?: boolean) => {
    const imageList: RequestImageSetType[] = [];
    const checkedIdxList = requestImageDataSet.map(i => { return i.checked && i.pathIdx });
    images.forEach((image, i) => {
      const data: RequestImageSetType = {
        pathIdx: image.idx,
        no: i + 1,
        pathName: image.data_name || '-',
        images: getFileImages(image.information_original_file || [], image.idx, isReload),
        resolution: image.resolution,
        provider: image.provides,
        classification: image.classification,
        createdDate: dayjs(image.time_get_image).format(DATE_FORMAT),
        titleUrl: image.api_url,
        swLat: image.sw_lat,
        swLong: image.sw_long,
        neLat: image.ne_lat,
        neLong: image.ne_long,
        mode: image.image_type ? IMAGE_DATASET_IMAGE_TYPE[image.image_type] : undefined,
        checked: isReload ? false : checkedIdxList.includes(image.idx),
      }
      imageList.push(data);
    });
    setProjectImageDataSet(imageList);
    setIsLoad(true);
  }

  const fetchIdfToken = async () => {
    await getIdfToken(idfParams);
  }

  const fetchImageryList = async (isReload?: boolean) => {
    let accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    if (!accessKey) {
      await fetchIdfToken();
      accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    }

    if (imageDataIds.length === 0) {
      setIdfPathList([]);
      return;
    }

    const params: ImageryInfoParams = {
      access_key: accessKey,
      bbox: filterBbox,
      image_data_id: imageDataIds,
      begin_date: filterStartDate,
      end_date: filterEndDate
    }

    const result = await getImageryInfo(params);
    if (!result || !result.data) return;
    setIdfPathList(result.data);
    parseImageSetData(result.data, isReload);
  }

  const handleReloadClick = () => {
    fetchImageryList(true);
  }

  const setContentBox = (dataset: RequestImageSetType[]) => {
    return dataset.map((data, i) => {
      const fileList: FileDataType[] = [];
      data.images.forEach((image, i) => {
        const file: FileDataType = {
          no: i + 1,
          name: image.name,
          mode: data.mode || '-',
          classification: (data.classification && IMAGE_DATASET_IMAGE_CLASSIFICATION[data.classification]) || '-',
          resolution: data.resolution || '',
          size: image.size,
          provider: data.provider || '-',
          createdDate: data.createdDate || '-',
          iconButton: <IconButton iconPath={ICON.ICON_DELETE} onClick={() => handleRemoveFileItem(image.pathIndex, image.idx)}></IconButton>
        }
        fileList.push(file);
      })
      const imageData: ImageDataType = {
        no: i + 1,
        idx: data.pathIdx,
        pathName: data.pathName,
        images: fileList
      }
      return (
        <RequestImageContentBox imageData={imageData} key={i}></RequestImageContentBox>
      )
    });
  }

  const initRequestContentBox = useMemo(() => {
    return (
      requestImageDataSet.length === 0 ? setContentBox(defaultImageSet) : setContentBox(requestImageDataSet)
    )
  }, [requestImageDataSet])

  const handleRemoveFileItem = (pathIdx: number, fileIdx: number) => {
    unCheckFileFromProjectImageSet(pathIdx, fileIdx);
    removeFileFromRequestImageSet(pathIdx, fileIdx)
  }

  const unCheckFileFromProjectImageSet = (pathIdx: number, fileIdx: number) => {
    const updatedProjectImageSet = [...projectImageDataSet];
    const targetPath = updatedProjectImageSet.find(i => i.pathIdx === pathIdx);
    if (!targetPath) return;
    const targetFile = targetPath.images.find(i => i.idx === fileIdx);
    if (!targetFile) return;

    targetFile.checked = false;

    if (targetPath.images.filter(i => i.checked).length === 0) {
      targetPath.checked = false;
    }

    setProjectImageDataSet(updatedProjectImageSet);
  }

  const removeFileFromRequestImageSet = (pathIdx: number, fileIdx: number) => {
    const updatedRequestImageSet = [...requestImageDataSet];
    const targetPath = updatedRequestImageSet.find(i => i.pathIdx === pathIdx);
    if (!targetPath) return;
    const targetFile = targetPath.images.find(i => i.idx === fileIdx);
    if (!targetFile) return;

    targetPath.images.splice(targetPath.images.indexOf(targetFile), 1);

    if (targetPath.images.filter(i => i.checked).length === 0) {
      updatedRequestImageSet.splice(updatedRequestImageSet.indexOf(targetPath), 1);
    }

    setRequestImageDataSet(updatedRequestImageSet);
  }

  useEffect(() => {
    reset();
    return reset();
  }, []);

  return (
    <>
      <ContentWrap>
        <SubTitleContainer title="Image data Set" textType={TextType.h3} iconPath={ICON.ICON_IMAGE_BLACK} required={true} rightComponent={
          <Button color={BUTTON_COLOR.black} text="Select Image" size={"size80"} iconPath={ICON.ICON_PLUS} width={100} onClick={() => setIsFindIDF(true)} disabled={!isSelectable}></Button>
        }></SubTitleContainer>
        {initRequestContentBox}
      </ContentWrap>
      {isFindIDF &&
        <ImageDataSetPopup onClosePopup={() => setIsFindIDF(false)} onReloadClick={handleReloadClick} isLoad={isLoad}></ImageDataSetPopup>
      }
    </>
  )
}