import { useAxios } from 'api/useAxios';
import axios from 'axios';
import { HTTP_HEADERS } from 'constants/auth';
import { EndPoint } from 'data/endPoint';
import { RoutePath } from 'data/router/paths';
import { authStore } from 'stores/auth-stores';
import { LoginInfo, LoginParams } from 'types/feature/login/login';
import { extractUserRolesAtToken } from 'utils/auth/authorization-utils';
import { logoutLocalStorage } from 'utils/auth/localStorage-utils';

export const useAuth = () => {
  const { api } = useAxios();
  const { setAuthenticationInfo, initialize } = authStore();

  const postLogin = async ({ username, password }: LoginParams) => {
    try {
      const response = await axios.post<LoginInfo>(`${process.env.REACT_APP_BASE_URL}${EndPoint.login}`, { username, password }, { withCredentials: true });
      const {data, headers} = response;
      storeAuthenticationInfo(data, {
        memberId: headers[HTTP_HEADERS.DABEEO_MEMBER_ID],
        companyId: headers[HTTP_HEADERS.DABEEO_COMPANY_ID],
        companyUrl: headers[HTTP_HEADERS.DABEEO_COMPANY_URL]
      });
      return response;
    } catch (e: any) {
      // const error = (e as AxiosError);
      return e;
      // TODO: 로그인 실패 처리
    }
  }

  const postRefresh = async () => {
    try {
      const { data, headers } = await axios.post<LoginInfo>(`${process.env.REACT_APP_BASE_URL}${EndPoint.refresh}`, {}, { withCredentials: true });
      storeAuthenticationInfo(data, {
        memberId: headers[HTTP_HEADERS.DABEEO_MEMBER_ID],
        companyId: headers[HTTP_HEADERS.DABEEO_COMPANY_ID],
        companyUrl: headers[HTTP_HEADERS.DABEEO_COMPANY_URL]
      });
      return data;
    } catch (e) {
      logoutLocalStorage();
      if (window.location.pathname.indexOf(RoutePath.login) > -1) return;
      window.location.href = RoutePath.login;
    }
  }

  const postLogout = async () => {
    const response = await api.post(`${EndPoint.logout}`);
    logoutLocalStorage();
    initialize();
    return response;
  }

  const resetPassword = async (name: string, emailOrId: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${EndPoint.resetPassword}?name=${name}&emailOrId=${emailOrId}`);
      return response;
    } catch (e) {
      // console.log(e);
    }
    
  }

  const redirectUser = (companyUrl: string) => {
    window.location.href = `${process.env.REACT_APP_EE_USER_URL}/${companyUrl}`;
  }

  const storeAuthenticationInfo = (loginInfo: LoginInfo, { memberId, companyId, companyUrl }: { memberId: number, companyId: number, companyUrl: string }) => {
    setAuthenticationInfo({
      accessToken: loginInfo.access_token,
      userRoles: extractUserRolesAtToken(loginInfo.access_token),
      memberId,
      companyId,
      companyUrl,
    });
  }

  return {
    postLogin,
    postRefresh,
    postLogout,
    resetPassword,
    redirectUser
  }
}