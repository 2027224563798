export const EndPoint = {
  //idf
  idfImageryDatas: 'api/imagery/info',
  idfToken: 'api/imagery/create_token',
  idfProvider: 'api/imagery/provider/:accessKey',

  //login
  login: '/auth/token',
  logout: '/auth/logout',
  refresh: '/auth/refresh',
  resetPassword: '/auth/reset-password',

  //project
  projects: '/admin/project',
  project: '/admin/project/:projectId',
  projectCompanies: '/admin/project/company',
  projectMembers: '/admin/project/:companyId/member',

  //request
  request: '/admin/project/:projectId/request/:requestId',
  requestProject: '/admin/project/:projectId/request',
  requestImageType: '/admin/project/request/image-type',
  requestImageClassification: '/admin/project/request/image-classification',
  requestCoreTask: '/admin/project/request/core/task',
  requestCoreList: '/admin/project/request/core/list',

  //company
  company: '/admin/company',
  companyDetail: '/admin/company/:companyId',
  companyUrls: '/admin/company/url',
  companyCheckUrl: '/admin/company/url/:url',
  companyCheckUserName: '/admin/company/member/username/:username',
  companyCheckUserEmail: '/admin/company/member/email/:email',

  //member
  members: '/admin/company/:companyId/member',
  member: '/admin/company/:companyId/member/:memberId',
  memberUserName: '/admin/company/member/username',
  memberResetPassword: '/admin/company/:companyId/member/:memberId/reset-password',

  //enterprise
  enterprises: '',
  enterprise: '',

  //account member
  memberMyPage: '/member/:memberId',
  memberChangePassword: '/member/:memberId',

  //aicore
  aiCore: '/admin/core',
  aiCoreDetail: '/admin/core/:aiCoreId',
  aiCoreVersion: '/admin/core/:id/version',
  aiCoreVersionDetail: '/admin/core/:id/version/:versionId',
  aiCoreTask: '/admin/core/task',
  aiCoreCheckVersion: '/admin/core/:id/version/:version',
  aiCoreCheckModelId: '/admin/core/:task/modelId/:modelId'
} 