import classNames from "classnames";
import Tooltip from "components/Tooltip/Tooltip";
import { INTERACTION_PROPS_TYPES, INTERACTION_TYPES, LAYER_PROPS_TYPES, LAYER_TYPES } from "constants/map/map";
import Transform from "ol-ext/interaction/Transform";
import { shiftKeyOnly } from "ol/events/condition";
import { boundingExtent } from "ol/extent";
import { Polygon } from "ol/geom";
import { Draw } from 'ol/interaction.js';
import { SketchCoordType } from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import { transformExtent } from "ol/proj";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from 'ol/style';
import { useEffect, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { mapStore } from "stores/map-stores";
import { getInteractionByProps } from "utils/map/openlayers-utils";
import { ImageDataSetPopupProps } from "./DrawContro.type";
import styles from "./DrawControl.module.scss";

export default function DrawControl({ setShowCustomCursor, setStartDrawing, setIsExistBoundingBox, isDrawMode, setIsDrawMode }: ImageDataSetPopupProps) {
  const { map } = mapStore();
  const [isNotFitZoom, setIsNotFitZoom] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { setFilterBbox } = imageDataSetStore();

  useEffect(() => {
    if (!isDrawMode) exitDrawMode();
  }, [isDrawMode]);

  useEffect(() => {
    if (map) {
      map.on("moveend", handleChangedZoom);
    }
  }, [map]);

  const handleChangedZoom = () => {
    const view = map?.getView();
    const zoom = view?.getZoom();

    if (!zoom) return;
    setIsNotFitZoom(!zoom || zoom < 7 || zoom > 16);
    if ((!zoom || zoom < 7 || zoom > 16) && map) {
      exitDrawMode();
    }
  };

  const addTransform = () => {
    const transformInteraction = new Transform({
      selection: true,
      enableRotatedTransform: false,
      addCondition: shiftKeyOnly,
      hitTolerance: 2,
      translateFeature: true,
      scale: true,
      rotate: false,
      translate: false,
      stretch: false,
      keepRectangle: true,
    });

    transformInteraction.set(INTERACTION_PROPS_TYPES.INTERACTION_TYPE, INTERACTION_TYPES.TRANSFORM);
    map?.addInteraction(transformInteraction);

    // Handle rotate on first point
    let firstPoint = false;
    let startRadius = 10;

    transformInteraction.on(['select'], (e: any) => {
      if (firstPoint && e.features && e.features.getLength()) {
        transformInteraction.setCenter(e.features.getArray()[0].getGeometry().getFirstCoordinate());
      }
    });

    transformInteraction.on('scaling', function (e: any) {
      if (firstPoint) {
        transformInteraction.setCenter(e.features.getArray()[0].getGeometry().getFirstCoordinate());
      }
      if (e.features.getLength() === 1) {
        var feature = e.features.item(0);
        feature.set('radius', startRadius * Math.abs(e.scale[0]));
      }

    });

    transformInteraction.on('translateend', (e: any) => {
      const bbox = transformExtent((e.feature.getGeometry() as Polygon)?.getExtent(), 'EPSG:3857', 'EPSG:4326');
      setFilterBbox(`${bbox[1]}, ${bbox[0]}, ${bbox[3]}, ${bbox[2]}`);
    });

    transformInteraction.on('scaleend', (e: any) => {
      const bbox = transformExtent((e.feature.getGeometry() as Polygon)?.getExtent(), 'EPSG:3857', 'EPSG:4326');
      setFilterBbox(`${bbox[1]}, ${bbox[0]}, ${bbox[3]}, ${bbox[2]}`);

    });
  }

  const setHandleStyle = () => {
    if (!map) return;
    const transformInteraction = getInteractionByProps(map, INTERACTION_TYPES.TRANSFORM);
    if (!(transformInteraction instanceof Transform)) return;
    transformInteraction.setDefaultStyle();
  }

  // 직사각형 그리기 함수
  const createRectangle = (coordinates: SketchCoordType) => {
    const start = coordinates[0] as number[];
    const end = coordinates[1] as number[];
    const minX = Math.min(start[0], end[0]);
    const maxX = Math.max(start[0], end[0]);
    const minY = Math.min(start[1], end[1]);
    const maxY = Math.max(start[1], end[1]);

    return new Polygon([[
      [minX, minY], [maxX, minY],
      [maxX, maxY], [minX, maxY],
      [minX, minY]
    ]]);
  }

  const addDrawInteractions = () => {
    if (!map) return;
    // 벡터 소스 및 레이어 설정
    const source = new VectorSource();
    const layer = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: 'transparent'
        }),
        stroke: new Stroke({
          color: '#EF37FF',
          width: 2
        })
      })
    });
    layer.set(LAYER_PROPS_TYPES.LAYER_TYPE, LAYER_TYPES.AOI_LAYER);
    map.addLayer(layer);

    // 직사각형 그리기
    const drawInteraction = new Draw({
      source: source,
      type: 'Circle',
      geometryFunction: function (coordinates, geometry) {
        if (!geometry) {
          geometry = createRectangle(coordinates);
        } else {
          const newCoordinates = createRectangle(coordinates).getCoordinates();
          geometry.setCoordinates(newCoordinates);
        }
        return geometry;
      },
      style: new Style({
        stroke: new Stroke({
          color: '#EF37FF',
          width: 2
        }),
      }),
    });

    map.getTargetElement().style.cursor = 'crosshair';
    setShowCustomCursor(true);
    drawInteraction.on('drawstart', (event) => {
      setStartDrawing(true);
    })
    drawInteraction.on('drawend', (event) => {
      addTransform();
      setHandleStyle();
      map?.removeInteraction(drawInteraction);
      map.getTargetElement().style.cursor = 'default';
      setShowCustomCursor(false);
      setIsExistBoundingBox(true);
      setFitViewByBox(event.feature.getGeometry() as Polygon);
      const bbox = transformExtent((event.feature.getGeometry() as Polygon)?.getExtent(), 'EPSG:3857', 'EPSG:4326');
      setFilterBbox(`${bbox[1]}, ${bbox[0]}, ${bbox[3]}, ${bbox[2]}`);
    });
    drawInteraction.set(INTERACTION_PROPS_TYPES.INTERACTION_TYPE, INTERACTION_TYPES.DRAW);
    map.addInteraction(drawInteraction);
  }

  const removeDrawInteraction = () => {
    if (!map) return;
    const drawInteraction = getInteractionByProps(map, INTERACTION_TYPES.DRAW);
    if (drawInteraction instanceof Draw) {
      map?.removeInteraction(drawInteraction);
    }
    const drawLayer = map?.getAllLayers().find(i => i.get(LAYER_PROPS_TYPES.LAYER_TYPE) === LAYER_TYPES.AOI_LAYER);
    if (drawLayer instanceof VectorLayer) {
      drawLayer.dispose();
      map?.removeLayer(drawLayer);
    }
  }

  const removeTransformInteraction = () => {
    if (!map) return;
    const transformInteraction = getInteractionByProps(map, INTERACTION_TYPES.TRANSFORM);
    if (transformInteraction instanceof Transform) {
      map?.removeInteraction(transformInteraction);
    }
  }

  const setFitViewByBox = (feature: Polygon) => {
    const coordinate = feature.getCoordinates()[0];
    const extent = boundingExtent(coordinate);
    map?.getView().fit(extent, { padding: [80, 80, 80, 80] });
  }

  const handleClickDrawMode = () => {
    if (isDrawMode) {
      setFilterBbox(null);
      exitDrawMode();
    }
    else {
      if (isNotFitZoom) {
        return;
      }
      setIsDrawMode(true);
      addDrawInteractions();
    }
  }

  const handleMouseEnter = () => {
    setShowTooltip(isNotFitZoom);
  }

  const handleMouseLeave = () => {
    setShowTooltip(false);
  }

  const exitDrawMode = () => {
    if (!map) return;
    removeDrawInteraction();
    removeTransformInteraction();
    map.getTargetElement().style.cursor = 'default';
    setShowCustomCursor(false);
    setStartDrawing(false);
    setIsExistBoundingBox(false);
    setIsDrawMode(false);
  }

  return (
    <div className={classNames(styles["draw-control__container"])}>
      <button className={classNames(styles["draw-btn"], { [styles["draw-on"]]: isDrawMode }, { [styles["draw-off"]]: !isDrawMode })} onClick={handleClickDrawMode} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}></button>
      {showTooltip &&
        <div className={styles["tooltip__area"]}>
          <Tooltip text={`AOI(Area of Interest) is only available between zoom levels 7 and 15.\n Please zoom in or out`} arrowAlign="right"></Tooltip>
        </div>
      }
    </div>
  );
}
