import { useNavigate } from "react-router-dom";
export default function useCustomNavigate() {
  const navigate = useNavigate();

  const navigateToReplacePath = (
    replacePath: string | number,
    stateProps?: string | number
  ) => {
    // const isLogin = getLocalStorage(LOCAL_STORAGE_KEYS.EE_LOGIN_STATUS) === 'true';
    // if(!isLogin) {navigate(RoutePath.login); return;}
    
    navigate(String(replacePath), { state: stateProps });
  };

  const navigateToBack = () => {
    navigate(-1);
  }

  return {
    navigateToReplacePath,
    navigateToBack
  }
}