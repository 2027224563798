import ICON_ARROW from 'assets/images/icons/arrow/icon_arrow.svg';
import ICON_ARROW_ACTIVE_DOWN from 'assets/images/icons/arrow/icon_arrow_active_down.svg';
import ICON_ARROW_ACTIVE_UP from 'assets/images/icons/arrow/icon_arrow_active_up.svg';
import ICON_ARROW_CALENDAR_DOUBLE_LEFT from 'assets/images/icons/arrow/icon_arrow_calendar_double_left.svg';
import ICON_ARROW_CALENDAR_DOUBLE_RIGHT from 'assets/images/icons/arrow/icon_arrow_calendar_double_right.svg';
import ICON_ARROW_CALENDAR_LEFT from 'assets/images/icons/arrow/icon_arrow_calendar_left.svg';
import ICON_ARROW_CALENDAR_RIGHT from 'assets/images/icons/arrow/icon_arrow_calendar_right.svg';
import ICON_ARROW_CLOSE_DOWN from 'assets/images/icons/arrow/icon_arrow_close_down.svg';
import ICON_ARROW_FILTER_DOWN from 'assets/images/icons/arrow/icon_arrow_filter_down.svg';
import ICON_ARROW_LEFT from 'assets/images/icons/arrow/icon_arrow_left.svg';
import ICON_ARROW_LEFT_BACK from 'assets/images/icons/arrow/icon_arrow_left_back.svg';
import ICON_ARROW_OPEN_UP from 'assets/images/icons/arrow/icon_arrow_open_up.svg';
import ICON_ARROW_RIGHT from 'assets/images/icons/arrow/icon_arrow_right.svg';
import ICON_ARROW_SORT_DOWN from 'assets/images/icons/arrow/icon_arrow_sort_down.svg';
import ICON_ARROW_SORT_UP from 'assets/images/icons/arrow/icon_arrow_sort_up.svg';
import ICON_ARROW_THANSIGN_RIGHT from 'assets/images/icons/arrow/icon_arrow_thansign_right.svg';
import ICON_ARROW_TOAST_BUBBLE from 'assets/images/icons/arrow/icon_arrow_toast_bubble.svg';
import ICON_CHECK from 'assets/images/icons/check/icon_check.svg';
import ICON_CHECK_WHITE from 'assets/images/icons/check/icon_check_white.svg';
import ICON_CHANGE_ROLE from 'assets/images/icons/icon_change_role.svg';
import ICON_CHIP_X from 'assets/images/icons/icon_chip_x.svg';
import ICON_CLOSE_POPUP from 'assets/images/icons/icon_close_popup.svg';
import ICON_CLOSE_POPUP_GRAY from 'assets/images/icons/icon_close_popup_gray.svg';
import ICON_DELETE from 'assets/images/icons/icon_delete.svg';
import ICON_EDIT from 'assets/images/icons/icon_edit.svg';
import ICON_EXIT from 'assets/images/icons/icon_exit.svg';
import ICON_FILTER_BLACK from 'assets/images/icons/icon_filter_black.svg';
import ICON_FILTER_GRAY from 'assets/images/icons/icon_filter_gray.svg';
import ICON_FOLDER from 'assets/images/icons/icon_folder.svg';
import ICON_IMAGE from 'assets/images/icons/icon_image.svg';
import ICON_IMAGE_BLACK from 'assets/images/icons/icon_image_black.svg';
import ICON_INFO from 'assets/images/icons/icon_info.svg';
import ICON_INFORMATION from 'assets/images/icons/icon_information.svg';
import ICON_MEMBER from 'assets/images/icons/icon_member.svg';
import ICON_MESSAGE from 'assets/images/icons/icon_message.svg';
import ICON_PLUS from 'assets/images/icons/icon_plus.svg';
import ICON_RELOAD_BLACK from 'assets/images/icons/icon_reload_black.svg';
import ICON_RELOAD_WHITE from 'assets/images/icons/icon_reload_white.svg';
import ICON_REQUEST from 'assets/images/icons/icon_request.svg';
import ICON_SETTING from 'assets/images/icons/icon_setting.svg';
import ICON_USER from 'assets/images/icons/icon_user.svg';
import ICON_X from 'assets/images/icons/icon_x.svg';
import ICON_TRIANGLE_RIGHT_BLACK from 'assets/images/icons/triangle/icon_triangle_right_black.svg';
import ICON_TRIANGLE_RIGHT_WHITE from 'assets/images/icons/triangle/icon_triangle_right_white.svg';
import LOGO_DABEEO from 'assets/images/logos/logo_dabeeo.svg';
import LOGO_EARTHEYE from 'assets/images/logos/logo_eartheye.svg';
import LOGO_EARTHEYE_BLACK from 'assets/images/logos/logo_eartheye_black.svg';
import LOGO_EARTHEYE_LOGIN from 'assets/images/logos/logo_eartheye_login.svg';

export const ICON = {
  LOGO_EARTHEYE,
  LOGO_EARTHEYE_BLACK,
  LOGO_EARTHEYE_LOGIN,
  LOGO_DABEEO,

  ICON_CHANGE_ROLE,
  ICON_USER,
  ICON_EXIT,

  ICON_RELOAD_BLACK,
  ICON_RELOAD_WHITE,

  ICON_ARROW,
  ICON_ARROW_ACTIVE_UP,
  ICON_ARROW_ACTIVE_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_LEFT_BACK,
  ICON_ARROW_SORT_DOWN,
  ICON_ARROW_SORT_UP,
  ICON_ARROW_CALENDAR_LEFT,
  ICON_ARROW_CALENDAR_RIGHT,
  ICON_ARROW_CALENDAR_DOUBLE_LEFT,
  ICON_ARROW_CALENDAR_DOUBLE_RIGHT,
  ICON_ARROW_OPEN_UP,
  ICON_ARROW_CLOSE_DOWN,
  ICON_ARROW_THANSIGN_RIGHT,
  ICON_ARROW_FILTER_DOWN,
  ICON_ARROW_TOAST_BUBBLE,

  ICON_CHECK,
  ICON_CHECK_WHITE,

  ICON_FILTER_GRAY,
  ICON_FILTER_BLACK,

  ICON_PLUS,

  ICON_INFORMATION,
  ICON_SETTING,

  ICON_EDIT,
  ICON_DELETE,
  ICON_X,
  ICON_CHIP_X,

  ICON_INFO,
  ICON_MEMBER,
  ICON_REQUEST,
  ICON_CLOSE_POPUP,
  ICON_CLOSE_POPUP_GRAY,

  ICON_TRIANGLE_RIGHT_BLACK,
  ICON_TRIANGLE_RIGHT_WHITE,

  ICON_MESSAGE,

  ICON_FOLDER,
  ICON_IMAGE,
  ICON_IMAGE_BLACK
}