import { IdfTokenParams } from "types/feature/idf/idf";

export const HTTP_HEADERS = {
  DABEEO_MEMBER_ID: 'dabeeo-member-id',
  DABEEO_COMPANY_ID: 'dabeeo-company-id',
  DABEEO_COMPANY_URL: 'dabeeo-company-url'
}

export const TOKEN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  PROJECT_ADMIN: 'PROJECT_ADMIN',
  USER: 'USER'
} as const

export const idfParams: IdfTokenParams = {
  uid: 'hanskim',
  upw: 'bau4cvghpp'
}

export const ADMIN_ROLES = {
  SUPER: 'SUPER',
  PROJECT: 'PROJECT',
} as const;
