import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ListContainer from "components/Container/List/ListContainer/ListContainer";
import SearchContainer from "components/Container/List/SearchContainer/SearchContainer";
import TitleContainer from "components/Container/Title/TitleContainer/TitleContainer";
import List from "components/List/List";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import ListHeader from "components/List/ListHeader/ListHeader";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { SEARCH_QUERY_KEYS } from "constants/searchQueryKey";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useCompany } from "hooks/feature/company/useCompany";
import { useEffect, useState } from "react";
import { searchStore } from "stores/search-stores";
import { OrderType } from "types/common/common";
import { CompanyListParams } from "types/feature/company/company";
import { setListNumber } from "utils/common-utils";
import styles from "./Company.module.scss";
import { CompanyData } from "./Company.type";

export default function Company() {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getCompanyList } = useCompany();
  const { setDisplayTypeOptions, setSelectedOption, searchReset, displayTypeOptions } = searchStore();
  const { searchParams, getSearchParam, setInitialParam } = useCustomSearchParams();

  /* List */
  const [companyList, setCompanyList] = useState<CompanyData[]>([]);

  const onClickColumn = (id: number) => {
    const target = companyList.find(i => i.id === id);
    if (!target) return;
    navigateToReplacePath(`detail?${URL_QUERY.COMPANY_ID}=${target.id}`);
  }

  const columns: Column[] = [
    { header: 'No', field: 'no', className: ['no'] },
    { header: 'Company name', field: 'companyName', className: ['blue-bold', 'ellipsis'], align: ALIGN_TYPE.left, onClick: onClickColumn },
    { header: '', field: 'url', className: ['link-btn'], align: ALIGN_TYPE.center },
    { header: 'Latest Update', field: 'updatedDate' },
    { header: 'Registration', field: 'createdDate' },
  ]
  const colStyles: React.CSSProperties[] = [
    { width: '6%' },
    { width: '58%' },
    { width: '8%' },
    { width: '14%' },
    { width: '14%' },
  ];

  useEffect(() => {
    setInitialData();
    return () => searchReset();
  }, []);

  useEffect(() => {
    setInitialParam();
  }, [displayTypeOptions])

  useEffect(() => {
    fetchCompanyList();
  }, [searchParams]);

  const setInitialData = () => {
    const options = [
      { label: 'Latest update date', value: 'updatedDate' },
      { label: 'Registration date', value: 'createdDate' },
      { label: 'Company name', value: 'companyName' },
    ];
    setDisplayTypeOptions(options);
    setSelectedOption(options[0]);
  }

  const fetchCompanyList = async () => {
    const params: CompanyListParams = {
      sort: getSearchParam(SEARCH_QUERY_KEYS.SORT) || '',
      direction: getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) as OrderType || '',
      searchKeyword: getSearchParam(SEARCH_QUERY_KEYS.SEARCH_KEYWORD) as string || ''
    }

    const result = await getCompanyList(params);

    if (result) {
      const list: CompanyData[] = [];
      result.content.forEach((data, i) => {
        const company: CompanyData = {
          no: setListNumber(i, result.content.length, getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) === "ASC"),
          id: data.id,
          url: data.url,
          companyName: data.name,
          updatedDate: data.updateDate,
          createdDate: data.createDate
        };
        list.push(company);
      });
      setCompanyList(list);
    }
  }

  return (
    <div className={styles["company__container"]}>
      <TitleContainer title="Company" textType={TextType.h2} rightComponent={<Button color={BUTTON_COLOR.blue} text="Registration" size={"size80"} iconPath={ICON.ICON_PLUS} width={106} onClick={() => navigateToReplacePath(RoutePath.companyDetailRegist)}></Button>}
      ></TitleContainer>
      <SearchContainer>
        <SearchFilter isFilter={false} isCalendar={false}></SearchFilter>
      </SearchContainer>
      <ListContainer>
        <ListHeader total={companyList.length}></ListHeader>
        <List columns={columns} datas={companyList} colStyles={colStyles}></List>
      </ListContainer>
    </div>
  )
}