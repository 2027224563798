import { useAxios } from "api/useAxios";
import { AxiosError } from "axios";
import { EndPoint } from "data/endPoint";
import { ChangePassword, MyPage } from "types/feature/member/accountMember";

export const useAccountMember = () => {
  const { api } = useAxios();

  const getInfo = async (memberId: number) => {
    return await api.get<MyPage>(
      EndPoint.memberMyPage.replace(":memberId", String(memberId))
    );
  };

  const updatePassword = async (memberId: number, data: ChangePassword) => {
    try {
      await api.patch(
        EndPoint.memberChangePassword.replace(":memberId", String(memberId)),
        data
      );
      return true;
    } catch (e) {
      return (e as AxiosError);
    }
  };

  return {
    getInfo,
    updatePassword,
  };
};
