import classNames from "classnames";
import Checkbox from "components/CheckBox/CheckBox";
import { RequestFileType } from "components/RequestDetail/ImageDataSet/RequestImageDataSet.type";
import { ICON } from "constants/icons";
import { LAYER_PROPS_TYPES, LAYER_TYPES } from "constants/map/map";
import { Feature } from "ol";
import { boundingExtent } from "ol/extent";
import { Polygon } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import { Stroke, Style } from "ol/style";
import { useEffect, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { mapStore } from "stores/map-stores";
import { projectStore } from "stores/project-stores";
import styles from "./DataSetPathItem.module.scss";
import { DataSetPathItemProps } from "./DataSetPathItem.type";

export default function DataSetPathItem({ path, index, arrow = false, isOpen = false }: DataSetPathItemProps) {
  const [showContent, setShowContent] = useState(isOpen);
  const { map } = mapStore();
  const { projectImageDataSet, setProjectImageDataSet } = imageDataSetStore();
  const { selectedTask } = projectStore();
  const [pathChecked, setPathChecked] = useState(path.checked);

  useEffect(() => {
    setPathChecked(path.checked);
    path.checked ? makeTileLayer() : removeTile();
  }, [path.checked]);

  const handleClickIcon = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowContent(!showContent);
  }

  const makeTileLayer = () => {
    setTileBBox();

    if (map?.getAllLayers().find(layer => layer.get(LAYER_PROPS_TYPES.LAYER_TYPE) === LAYER_TYPES.TILE_LAYER && layer.get(LAYER_PROPS_TYPES.LAYER_TILE_DATA_NAME) === path.pathName) || !path.titleUrl) return;
    const tileLayer = new TileLayer({
      source: new XYZ({
        url: path.titleUrl
      })
    });

    tileLayer.set(LAYER_PROPS_TYPES.LAYER_TYPE, LAYER_TYPES.TILE_LAYER);
    tileLayer.set(LAYER_PROPS_TYPES.LAYER_TILE_DATA_NAME, path.pathName);
    map?.addLayer(tileLayer);
  }

  const setTileBBox = () => {
    removeBbox();

    if (path.swLat && path.swLong && path.neLat && path.neLong) {
      const polygon = new Polygon([[
        [path.swLong, path.swLat],
        [path.swLong, path.neLat],
        [path.neLong, path.neLat],
        [path.neLong, path.swLat],
        [path.swLong, path.swLat]
      ]]).transform('EPSG:4326', 'EPSG:3857');

      const feature = new Feature(polygon);

      const vectorSource = new VectorSource({
        features: [feature],
        strategy: bboxStrategy
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          stroke: new Stroke({
            color: '#4CFFEA',
            width: 3
          })
        })
      });

      vectorLayer.set(LAYER_PROPS_TYPES.LAYER_TILE_DATA_NAME, path.pathName);
      vectorLayer.set(LAYER_PROPS_TYPES.LAYER_TYPE, LAYER_TYPES.BBOX_LAYER);
      map?.addLayer(vectorLayer);

      const coordinate = (polygon as Polygon).getCoordinates()[0];
      const extent = boundingExtent(coordinate);
      map?.getView().fit(extent, { padding: [80, 80, 80, 80] });
    }
  }

  const removeTile = () => {
    removeBbox(true);
    if (!map) return;
    const targetLayers = map.getAllLayers().filter(layer => layer.get(LAYER_PROPS_TYPES.LAYER_TYPE) === LAYER_TYPES.TILE_LAYER && layer.get(LAYER_PROPS_TYPES.LAYER_TILE_DATA_NAME) === path.pathName);
    targetLayers.map(layer => {
      layer.dispose();
      map.removeLayer(layer);
    });
  }

  const removeBbox = (unCheck?: boolean) => {
    if (!map) return;
    const targetLayer = map.getAllLayers().find(layer =>
      unCheck ? layer.get(LAYER_PROPS_TYPES.LAYER_TYPE) === LAYER_TYPES.BBOX_LAYER && layer.get(LAYER_PROPS_TYPES.LAYER_TILE_DATA_NAME) === path.pathName :
        layer.get(LAYER_PROPS_TYPES.LAYER_TYPE) === LAYER_TYPES.BBOX_LAYER
    );
    if (!targetLayer) return;
    targetLayer.dispose();
    map.removeLayer(targetLayer);
  }

  const handleClickPath = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedImageSet = [...projectImageDataSet];
    updatedImageSet.map(i => {
      if (i.pathIdx === path.pathIdx) {
        i.images.forEach((image) => {
          image.checked = !i.checked;
        });
        return i.checked = !i.checked;
      }
    });
    setProjectImageDataSet(updatedImageSet);
  }

  const handleClickFile = (file: RequestFileType) => {
    path.images.map((image) => {
      if (image.idx === file.idx) {
        return image.checked = !image.checked;
      }
    });

    path.checked = path.images.filter(i => i.checked).length > 0;

    const updatedImageSet = [...projectImageDataSet];
    updatedImageSet.map((imageSet) => {
      if (imageSet.pathIdx === path.pathIdx) {
        return path;
      }
    });

    setProjectImageDataSet(updatedImageSet);
  }

  return (
    <>
      <div className={classNames(styles["list-item"], { [styles["-checked"]]: pathChecked })} onClick={handleClickPath}>
        <Checkbox label={path.pathName} id={`path${index}`} checked={pathChecked as boolean}></Checkbox>
        {arrow &&
          <img className={classNames(styles["-arrow"], { [styles["-rotate"]]: showContent })} src={ICON.ICON_TRIANGLE_RIGHT_BLACK} alt="" onClick={(e) => handleClickIcon(e)}></img>
        }
      </div>
      {showContent &&
        <div className={styles["list-content"]}>
          {path.images && path.images.map((data, i) => {
            return (
              <div className={styles["list-row"]} key={i}>
                <Checkbox label={data.name} width={327} checked={data.checked as boolean} id={`path${index}item${data.idx}`} onChange={() => handleClickFile(data)} isToopTip></Checkbox>
                <span style={{ width: 90 }}>{path.resolution}</span>
                <span style={{ width: 100 }}>{path.provider}</span>
                <span style={{ width: 70 }}>{data.size}</span>
                <span style={{ width: 90 }} className={styles["-create-date"]}>{path.createdDate}</span>
              </div>
            )
          })}
        </div>
      }
    </>
  );
};
