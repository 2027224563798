import classNames from "classnames";
import styles from "./Radio.module.scss";
import { RadioProps } from './Radio.type';


export default function Radio({ value, label, disabled, checked, width, height, onChange }: RadioProps) {
  return (
    <div className={styles["radio__container"]} style={{ width: width, height: height }}>
      <input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type='radio'
        id={label}
        value={value || ''}
        name='radio'
        className={styles["-radio"]}
      />

      {label && (
        <label
          className={classNames(styles["-label"], { [styles["-checked"]]: checked }, { [styles["-disabled"]]: disabled })}
          htmlFor={label}
        >
          {label}
        </label>
      )}
    </div>
  );
};
