import { AxiosError } from "axios";
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import Checkbox from "components/CheckBox/CheckBox";
import Header from "components/Header/Header";
import Input from "components/Input/Input";
import PasswordPopup from "components/Popup/PasswordPopup/PasswordPopup";
import { ICON } from "constants/icons";
import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import { LOGIN_ERROR_CODE } from "constants/login";
import { useAuth } from "hooks/feature/login/useAuth";
import { ChangeEvent, useEffect, useState } from "react";
import { commonStore } from "stores/common-stores";
import { LoginError, LoginParams } from "types/feature/login/login";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "utils/auth/localStorage-utils";
import styles from "./Login.module.scss";

export default function Login() {
  const { setIsLoading } = commonStore();
  const [formData, setFormData] = useState<LoginParams>({
    username: '',
    password: '',
  });

  const [isRemember, setIsRemeber] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isFindPassword, setIsFindPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { postLogin } = useAuth();
  const checkMsg = "Please check your ID or password.";
  const forgotMsg = "The number of login attempts has been exceeded. \n Please contact the administrator.";

  useEffect(() => {
    if (getLocalStorage(LOCAL_STORAGE_KEYS.EE_LOGIN_USER_NAME)) {
      setFormData({
        username: getLocalStorage(LOCAL_STORAGE_KEYS.EE_LOGIN_USER_NAME),
        password: ''
      });
      setIsRemeber(true);
    }
  }, [])

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.username.length === 0 || formData.password.length === 0) {
      setIsError(true);
      return;
    }

    const loginData: LoginParams = {
      ...formData,
      // password: window.btoa(formData.password), // password base64로 인코딩
    };

    setIsLoading(true);
    const response = await postLogin(loginData);
    if (!response || response instanceof AxiosError) {
      setIsError(true);
      const errorResponse = (response as AxiosError).response && ((response as AxiosError).response?.data) as LoginError;
      if (errorResponse) {
        setErrorMessage(errorResponse.code === LOGIN_ERROR_CODE.LOCK_MEMBER ? forgotMsg : checkMsg);
      }
    } else {
      isRemember ? setLocalStorage(LOCAL_STORAGE_KEYS.EE_LOGIN_USER_NAME, formData.username) : removeLocalStorage(LOCAL_STORAGE_KEYS.EE_LOGIN_USER_NAME);
    }
    setIsLoading(false);
  };

  const handleChangeLoginForm = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <div className={styles["login__container"]}>
      <Header menusVisible={false}></Header>
      <div className={styles["login__body"]}>
        <form className={styles["login__wrap"]} onSubmit={handleLogin}>
          <img src={ICON.LOGO_EARTHEYE_LOGIN} style={{ width: 197, height: 68 }} alt="" />
          <div className={styles["input__area"]}>
            <Input width={360} colorMode="dark" placeholder="Please enter your ID" onChange={handleChangeLoginForm} id='username' showWarning={isError} defaultValue={formData.username || ''}></Input>
            <Input width={360} colorMode="dark" placeholder="Please enter your password" type="password" onChange={handleChangeLoginForm} id='password' showWarning={isError} defaultValue={formData.password || ''}></Input>
            <div className={styles["check__area"]}>
              <Checkbox label="Remember ID" colorMode="dark" id="rememberID" checked={isRemember || false} onChange={() => setIsRemeber(!isRemember)} ></Checkbox>
            </div>
          </div>

          {isError &&
            <div className={styles["warning__area"]}>
              {errorMessage}
            </div>
          }

          <Button color={BUTTON_COLOR.blue} width={360} text="LOG IN" type="submit"></Button>
          <div className={styles["forgot_pwd__area"]}>
            <span onClick={() => setIsFindPassword(true)}>Forgot your Password?</span>
          </div>
        </form>
        <div className={styles["bottom__wrap"]}>
          <div className={styles["text-gray1"]}>Please contact dabeeo if you can't log in</div>
          <div className={styles["text-gray2"]}>cs.eartheye@dabeeo.com</div>
          <div className={styles["text-gray1"]}>Eartheye v.2.0</div>
          <img className={styles["bottom-logo"]} src={ICON.LOGO_DABEEO} style={{ width: 80, height: 17.4 }} alt="" />
        </div>
      </div>
      {isFindPassword &&
        <PasswordPopup onClosePopup={() => setIsFindPassword(false)}></PasswordPopup>
      }
    </div>
  )
}