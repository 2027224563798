import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ListContainer from "components/Container/List/ListContainer/ListContainer";
import SearchContainer from "components/Container/List/SearchContainer/SearchContainer";
import TitleContainer from "components/Container/Title/TitleContainer/TitleContainer";
import List from "components/List/List";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import ListHeader from "components/List/ListHeader/ListHeader";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { SEARCH_QUERY_KEYS } from "constants/searchQueryKey";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useAiCore } from "hooks/feature/aiCore/useAiCore";
import { useEffect, useState } from "react";
import { searchStore } from "stores/search-stores";
import { OrderType } from "types/common/common";
import { AiCoreListParams, STATUS_TYPE, STATUS_VIEW_TYPE, StatusType } from "types/feature/aiCore/aiCore";
import { TASK_TYPE, TASK_VIEW_TYPE, TaskType } from "types/feature/project/project";
import { setListNumber } from "utils/common-utils";
import { setFilterQuery } from "utils/filter-utils";
import styles from './AICore.module.scss';
import { AICoreData } from "./AICore.type";
export default function AICore() {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getAiCoreList } = useAiCore();
  const { displayTypeOptions, setFilterOptions, setDisplayTypeOptions, setSelectedOption, searchReset } = searchStore();
  const { searchParams, setInitialParam, getSearchParam, setSearchParam } = useCustomSearchParams();

  /* List */
  const [aiCoreList, setAiCoreList] = useState<AICoreData[]>([]);
  const onClickColumn = (id: number) => {
    const target = aiCoreList.find(i => i.id === id);
    if (!target) return;
    navigateToReplacePath(`detail?${URL_QUERY.AICORE_ID}=${target.id}`);
  }

  const columns: Column[] = [
    { header: 'No', field: 'no', className: ['no'] },
    { header: 'Status', field: 'status', className: ['chip'] },
    { header: 'Task', field: 'task' },
    { header: 'Model', field: 'model', align: ALIGN_TYPE.left, className: ['blue-bold', 'ellipsis'], onClick: onClickColumn },
    { header: 'Last Version', field: 'lastVersion' },
    { header: 'Latest Update', field: 'updatedDate' },
    { header: 'Registration', field: 'createdDate' },
  ]
  const colStyles: React.CSSProperties[] = [
    { width: '6%' },
    { width: '9%' },
    { width: '17%' },
    { width: '29%' },
    { width: '11%' },
    { width: '14%' },
    { width: '14%' },
  ];

  useEffect(() => {
    setInitialData();
    return () => searchReset();
  }, []);

  useEffect(() => {
    setInitialParam();
  }, [displayTypeOptions])

  useEffect(() => {
    fetchAiCoreList();
  }, [searchParams]);

  const setInitialData = () => {
    const options = [
      { label: 'Latest update date', value: 'updatedDate' },
      { label: 'Registration date', value: 'createdDate' },
      { label: 'Version', value: 'version' },
      { label: 'Model', value: 'model' },
    ];
    setDisplayTypeOptions(options);
    !getSearchParam(SEARCH_QUERY_KEYS.SORT) && setSelectedOption(options[0]);
    const taskFilterQueryParam = getSearchParam(SEARCH_QUERY_KEYS.TASK)?.split(',');
    const statusFilterQueryParam = getSearchParam(SEARCH_QUERY_KEYS.STATUS)?.split(',');
    const initFilterOptions = [
      {
        title: 'Task',
        values: [
          { label: TASK_VIEW_TYPE.OBJECT_DETECTION, value: TASK_TYPE.OBJECT_DETECTION, checked: taskFilterQueryParam?.includes(TASK_TYPE.OBJECT_DETECTION) || false },
          { label: TASK_VIEW_TYPE.SEGMENTATION, value: TASK_TYPE.SEGMENTATION, checked: taskFilterQueryParam?.includes(TASK_TYPE.SEGMENTATION) || false },
          { label: TASK_VIEW_TYPE.CHANGE_DETECTION, value: TASK_TYPE.CHANGE_DETECTION, checked: taskFilterQueryParam?.includes(TASK_TYPE.CHANGE_DETECTION) || false },
          // { label: TASK_VIEW_TYPE.IMAGE_SUPER_RESOLUTION, value: TASK_TYPE.IMAGE_SUPER_RESOLUTION, checked: taskFilterQueryParam?.includes(TASK_TYPE.IMAGE_SUPER_RESOLUTION) || false },
        ]
      },
      {
        title: 'Status',
        values: [
          { label: STATUS_VIEW_TYPE.ACTIVE, value: STATUS_TYPE.ACTIVE, checked: statusFilterQueryParam?.includes(STATUS_TYPE.ACTIVE) || true },
          { label: STATUS_VIEW_TYPE.DELETED, value: STATUS_TYPE.DELETED, checked: statusFilterQueryParam?.includes(STATUS_TYPE.DELETED) || false },
        ]
      }
    ];
    setFilterOptions(initFilterOptions);
    setFilterQuery(initFilterOptions[1].title, initFilterOptions[1].values, setSearchParam)
  }

  const fetchAiCoreList = async () => {
    const params: AiCoreListParams = {
      sort: getSearchParam(SEARCH_QUERY_KEYS.SORT) || '',
      direction: getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) as OrderType || '',
      searchKeyword: getSearchParam(SEARCH_QUERY_KEYS.SEARCH_KEYWORD) as string || '',
      task: getSearchParam(SEARCH_QUERY_KEYS.TASK)?.split(',') as TaskType[] || [],
      status: getSearchParam(SEARCH_QUERY_KEYS.STATUS)?.split(',') as StatusType[] || [],
    }

    const result = await getAiCoreList(params);

    if (result) {
      const list: AICoreData[] = [];
      result.content.forEach((data, i) => {
        const task = data.task;
        const aicore: AICoreData = {
          no: setListNumber(i, result.content.length, getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) === "ASC"),
          id: data.id,
          status: data.isDeleted ? 'Deleted' : 'Active',
          task: TASK_VIEW_TYPE[task as TaskType],
          model: data.modelName,
          lastVersion: data.version,
          updatedDate: data.updatedDate,
          createdDate: data.createdDate
        };
        list.push(aicore);
      });
      setAiCoreList(list);
    }
  }

  return (
    <div className={styles["aicore__container"]}>
      <TitleContainer title="AI Core" textType={TextType.h2}
        rightComponent={<Button color={BUTTON_COLOR.blue} text="Registration" size={"size80"} iconPath={ICON.ICON_PLUS} width={106} onClick={() => navigateToReplacePath(RoutePath.aicoreDetailRegist)}></Button>}
      ></TitleContainer>
      <SearchContainer>
        <SearchFilter isCalendar={false}></SearchFilter>
      </SearchContainer>
      <ListContainer>
        <ListHeader total={aiCoreList.length}></ListHeader>
        <List columns={columns} datas={aiCoreList} colStyles={colStyles}></List>
      </ListContainer>
    </div>
  )
}