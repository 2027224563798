export type ListProps = {
  columns: Column[],
  datas: any[],
  colStyles?: React.CSSProperties[],
  isScroll?: boolean,
  maxHeight?: number
}

export type Column = {
  header: string;
  field: string;
  align?: AlignType;
  required?: boolean;
  className?: string[];
  isTooltip?: boolean;
  tooltipText?: string;
  onClick?: (param?: any) => void;
}

export type AlignType = (typeof ALIGN_TYPE)[keyof typeof ALIGN_TYPE];

export const ALIGN_TYPE = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;
