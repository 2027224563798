import { useAxios } from "api/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { EndPoint } from "data/endPoint";
import { MemberParams, MemberResponse, UpdateMemberParams } from "types/feature/member/member";

export const useMember = () => {
  const { api } = useAxios();

  //Company Member 목록 조회
  const getCompanyMembers = async (companyId: number) => {
    const result = await api.get<MemberResponse[]>(EndPoint.members.replace(':companyId', String(companyId)));
    return result;
  }

  //Company Member 생성
  const postCompanyMember = async ({ companyId, nickname, username, email }: MemberParams) => {
    const result = await api.post<AxiosResponse>(EndPoint.members.replace(':companyId', String(companyId)), { companyId, nickname, username, email });
    return result;
  }

  //Company Member 삭제
  const deleteCompanyMember = async (companyId: number, memberId: number) => {
    const result = await api.delete<AxiosResponse>(EndPoint.member.replace(':companyId', String(companyId)).replace(':memberId', String(memberId)));
    return result;
  }

  //Company Member 수정
  const updateCompanyMember = async (memberId: number, { id, companyId, nickname, email }: UpdateMemberParams) => {
    const result = await api.patch<AxiosResponse>(EndPoint.member.replace(':companyId', String(companyId)).replace(':memberId', String(memberId)), { id, companyId, nickname, email });
    return result;
  }

  // Company Member Password 초기화
  const resetMemberPassword = async (companyId: number, memberId: number) => {
    let result = true;
    try {
      await api.patch<AxiosResponse>(EndPoint.memberResetPassword.replace(':companyId', String(companyId)).replace(':memberId', String(memberId)));
    } catch (e) {
      result = false;
    } finally {
      return result;
    }
  }

  //Company UserName 중복 체크용 API
  const getCheckMemberUserName = async (username: string) => {
    try {
      const result = await api.get(EndPoint.companyCheckUserName.replace(':username', username));
      return result;
    } catch (e) {
      return (e as AxiosError);
    }
  }

  //Company Email 중복 체크용 API
  const getCheckMemberEmail = async (email: string) => {
    try {
      const result = await api.get(EndPoint.companyCheckUserEmail.replace(':email', email));
      return result;
    } catch (e) {
      return (e as AxiosError);
    }
  }

  //Enterprise Member 목록 조회
  const getEnterpriseMembers = async (enterpriseId: number) => {
    // const result = await api.get<MemberResponse[]>(EndPoint.members.replace(':enterpriseId', String(enterpriseId)));
    // return result;
  }

  //Enterprise Member 생성
  const postEnterpriseMember = async ({ enterpriseId, nickname, username, email }: MemberParams) => {
    // const result = await api.post<AxiosResponse>(EndPoint.members.replace(':enterpriseId', String(enterpriseId)), { enterpriseId, nickname, username, email });
    // return result;
  }

  //Enterprise Member 삭제
  const deleteEnterpriseMember = async (enterpriseId: number, memberId: number) => {
    // const result = await api.delete<AxiosResponse>(EndPoint.member.replace(':enterpriseId', String(enterpriseId)).replace(':memberId', String(memberId)));
    // return result;
  }

  //Enterprise Member 수정
  const updateEnterpriseMember = async (memberId: number, { id, enterpriseId, nickname, email }: UpdateMemberParams) => {
    // const result = await api.patch<AxiosResponse>(EndPoint.member.replace(':enterpriseId', String(enterpriseId)).replace(':memberId', String(memberId)), { id, enterpriseId, nickname, email });
    // return result;
  }

  return {
    getCompanyMembers,
    postCompanyMember,
    deleteCompanyMember,
    updateCompanyMember,
    resetMemberPassword,
    getCheckMemberUserName,
    getCheckMemberEmail,
    getEnterpriseMembers,
    postEnterpriseMember,
    deleteEnterpriseMember,
    updateEnterpriseMember
  }
}