import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import FormControl from "components/Container/Content/FormControl/FormControl";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import Input from "components/Input/Input";
import Alert from "components/Popup/Alert/Alert";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useCompany } from "hooks/feature/company/useCompany";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authStore } from "stores/auth-stores";
import styles from "styles/module/information.module.scss";
import { CompanyParams, CompanyRegistParams } from "types/feature/company/company";
import { getEE2UserDomain, isValidString } from "utils/common-utils";
import { CompanyInformationProps } from "./CompanyInformation.type";

export default function CompanyInformation({ companyId, setCompanyId, companyName, setCompanyName, url, setUrl, isRegist }: CompanyInformationProps) {
  const { navigateToReplacePath } = useCustomNavigate();
  const { setCompany, updateCompany, deleteCompany, getCheckCompanyUrl } = useCompany();
  const { memberId } = authStore();

  const [isShowAlert, setIsShowAlert] = useState(false);

  // const [isSavedInfo, setIsSavedInfo] = useState(companyId !== null);
  const [editCompanyName, setEditCompanyName] = useState(companyName);
  const [editUrl, setEditUrl] = useState(url);

  const [isShowRegist, setIsShowRegist] = useState(isRegist);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState(false);
  const [isErrorUrl, setIsErrorUrl] = useState(false);

  const [isDuplicatedURL, setIsDuplicatedURL] = useState(false);

  useEffect(() => {
    setIsShowRegist(isRegist);
  }, [isRegist]);

  useEffect(() => {
    setEditCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    setEditUrl(url);
  }, [url])

  useEffect(() => {
    setIsErrorCompanyName(editCompanyName ? editCompanyName.length > 200 : false);
  }, [editCompanyName]);

  useEffect(() => {
    (isRegist || isEditMode) && editUrl !== url && fetchCheckDuplicationUrl();
    !editUrl && setIsDuplicatedURL(false);
    setIsErrorUrl(editUrl ? editUrl.length > 20 || !isValidString(editUrl, ['-']) : false);
  }, [isDuplicatedURL, editUrl])

  useEffect(() => {
    const isNotChanged = editCompanyName === companyName && editUrl === url;
    setIsSaveDisabled(isNotChanged || !editCompanyName || !editUrl || isErrorCompanyName || isErrorUrl || isDuplicatedURL);
  }, [editCompanyName, editUrl, isErrorCompanyName, isErrorUrl, isDuplicatedURL]);

  const fetchCheckDuplicationUrl = async () => {
    const result = await getCheckCompanyUrl(editUrl);
    setIsDuplicatedURL(result instanceof AxiosError);
  }

  const handleNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditCompanyName(value);
  };

  const handleUrlInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEditUrl(value);
  };

  const handleCancelClick = () => {
    setEditUrl(url);
    setEditCompanyName(companyName);
    setIsEditMode(false);
    isRegist && navigateToReplacePath(RoutePath.company);
  }

  const handleSaveClick = async () => {
    const companyInfo: CompanyRegistParams = {
      name: editCompanyName,
      url: editUrl,
    }

    let result;
    if (isRegist) {
      result = await setCompany(companyInfo);
    } else {
      if (!companyId || !memberId) return;
      const info: CompanyParams = {
        id: companyId,
        name: editCompanyName,
        memberId: memberId
      }
      result = await updateCompany(companyId, info);
    }

    if (result) {
      setCompanyName(editCompanyName);
      setUrl(editUrl);
      setIsEditMode(false);
      navigateToReplacePath(RoutePath.companyDetail, result.id);
      setCompanyId(result.id);
    }
  }

  const fetchDeleteCompany = async () => {
    if (!companyId) return;
    await deleteCompany(companyId);
    setIsShowAlert(false);
    navigateToReplacePath(RoutePath.company);
  }

  const handleDeleteClick = async () => {
    setIsShowAlert(true);
  }

  const handleEditClick = () => {
    setIsEditMode(true);
  }

  return (
    <>
      <div className={styles["company-information__container"]}>
        <ContentWrap>
          <SubTitleContainer title="Information" textType={TextType.h3} iconPath={ICON.ICON_INFORMATION} required={true} rightComponent={
            <div className={styles["right-btn__area"]}>
              <div className={styles["btn-comp"]}>
                {isShowRegist && (
                  <>
                    <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                    <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                  </>
                )}
                {!isShowRegist && (
                  <>
                    {isEditMode ? (
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                        <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                      </>
                    ) :
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Delete" onClick={handleDeleteClick}></Button>
                        <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Edit" onClick={handleEditClick}></Button>
                      </>
                    }
                  </>
                )}
              </div>

            </div>
          }></SubTitleContainer>
          <ContentBox>
            <FormControl name="Name" required height={56}
              control={
                isRegist || isEditMode ?
                  <Input showWarning={isErrorCompanyName} warningText="Please enter less than 200 characters." defaultValue={editCompanyName} onChange={handleNameInputValue} placeholder="Please input company name" width={360}></Input> :
                  <span className={styles["info-span"]}>{companyName}</span>
              }
            ></FormControl>
            <FormControl name="URL" required tooltip tooltipText={`You can only\n• Enter lowercase letters in English\n• Enter up to 20 characters\n• Special characters can only be '-(hyphen)'`} tooltipTextAlign="left" height={56}
              control={
                isRegist || isEditMode ?
                  <div className={styles["url-form"]}>
                    <div className={styles["url"]}>{`${getEE2UserDomain()}/`}</div>
                    <Input debounce showWarning={isErrorUrl || isDuplicatedURL} warningText={isDuplicatedURL ? "A duplicate url exists." : "Please check url form."} defaultValue={editUrl} onChange={handleUrlInputValue} placeholder="Please enter a domain" width={170} disabled={isEditMode}></Input>
                  </div> :
                  <Link to={`${getEE2UserDomain()}/${url}`}>
                    <span className={classNames([styles["info-span"], styles['url-color']])} >{`${getEE2UserDomain()}/${url}`}</span>
                  </Link>

              }
            ></FormControl>
          </ContentBox>
        </ContentWrap>
      </div>
      {isShowAlert &&
        <Alert
          onClosePopup={() => setIsShowAlert(false)}
          firstButton={
            <Button color={BUTTON_COLOR.white} text="Cancel" onClick={() => setIsShowAlert(false)} width={120}></Button>
          }
          secondButton={
            <Button color={BUTTON_COLOR.black} text="Confirm" onClick={() => fetchDeleteCompany()}></Button>
          }
        >
          <span>If you delete, you can’t restore it.<br />Do you want to proceed?</span>
        </Alert>
      }
    </>

  )
}