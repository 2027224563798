import Tooltip from "components/Tooltip/Tooltip";
import { ICON } from "constants/icons";
import { useState } from "react";
import styles from "./FormControl.module.scss";
import { FormControlProps } from './FormControl.type';

export default function FormControl({ name, control, labelWidth, required, height, tooltip, tooltipText, tooltipTextAlign }: FormControlProps) {

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  }

  const handleMouseLeave = () => {
    setShowTooltip(false);
  }

  return (
    <div className={styles["control__wrap"]} style={{ minHeight: height }}>
      <div className={styles["label__wrap"]} style={{ minWidth: labelWidth }}>
        <span>{name}</span>
        {tooltip && (
          <div className={styles["info__wrap"]}>
            <img src={ICON.ICON_INFO} alt="" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
            {showTooltip &&
              <div className={styles["tooltip__area"]}>
                <Tooltip text={tooltipText as string} arrowAlign="top" textAlign={tooltipTextAlign}></Tooltip>
              </div>
            }
          </div>
        )}
        {required && (
          <span className={styles["-required"]}>*</span>
        )}
      </div>
      {control}
    </div>
  );
};
