export const SEARCH_QUERY_KEYS = {
  SORT: 'sort',
  DIRECTION: 'direction',
  SEARCH_KEYWORD: 'searchKeyword',
  TASK: 'Task',
  COMPANY: 'Company',
  LIMIT_START: 'limitStart',
  LIMIT_END: 'limitEnd',
  MODE: 'Mode',
  CLASSIFICATION: 'Classification',
  PROVIDER: 'Provider',
  RESOLUTION: 'Resolution',
  STATUS: 'Status'
}

export const SEARCH_QUERY_KEYS_ARR = Object.values(SEARCH_QUERY_KEYS);
//[SEARCH_QUERY_KEYS.SORT, SEARCH_QUERY_KEYS.DIRECTION, SEARCH_QUERY_KEYS.SEARCH_KEYWORD]