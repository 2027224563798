import styles from './Highlighting.module.scss';
import { HighlightingProps } from "./Highlighting.type";

export default function Highlighting({ value, keyword }: HighlightingProps) {
  const splitString = (input: string, searchString: string): [string, string] => {
    const index = input.indexOf(searchString);

    if (index === -1) {
      return [input, ''];
    }

    const firstPart = input.substring(0, index);
    const secondPart = input.substring(index + searchString.length);

    return [firstPart, secondPart];
  }

  return value.includes(keyword) && keyword !== '' ? (
    <span className={styles["highlight__wrap"]}>
      {splitString(value, keyword)[0]}
      <div className={styles["highlight"]}>{keyword}</div>
      {splitString(value, keyword)[1]}
    </span>
  ) : (
    <span className={styles["highlight__wrap"]}>{value}</span>
  );
}