import styles from "./ContentBox.module.scss";
import { ContentBoxProps } from './ContentBox.type';

export default function ContentBox({ children, gap }: ContentBoxProps) {

  return (
    <div className={styles["content__box"]} style={{ gap: gap }}>
      {children}
    </div>
  );
};