import React from "react";

export type ProjectRequestProps = {
  isRegist?: boolean;
};

export const PROCESSING_TYPE = {
  ON_STANDBY: 'ON_STANDBY',
  ANALYZING: 'ANALYZING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
} as const;

export type ProcessingType = (typeof PROCESSING_TYPE)[keyof typeof PROCESSING_TYPE];

export const PROCESSING_VIEW_TYPE = {
  ON_STANDBY: 'On Standby',
  ANALYZING: 'Analyzing',
  COMPLETED: 'Completed',
  FAILED: 'Failed'
} as const;

export type ProcessingViewType = (typeof PROCESSING_VIEW_TYPE)[keyof typeof PROCESSING_VIEW_TYPE];


export type RequestType = {
  id: number;
  no: number;
  coreName: string;
  name?: string;
  processing?: ProcessingType;
  imageCount?: number;
  // userID?: string;
  requestDate?: string;
  isResult?: boolean;
  button?: React.ReactElement;
}