
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import FormControl from "components/Container/Content/FormControl/FormControl";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import Input from "components/Input/Input";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { useEffect } from "react";
import { RequestInformationProps } from "./RequestInformation.type";

export default function RequestInformation({ requestName, setRequestName, isErrorRequestName, setIsErrorRequestName }: RequestInformationProps) {

  useEffect(() => {
    setIsErrorRequestName(requestName ? requestName.length > 200 : false);
  }, [requestName]);

  const handleNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setRequestName(value);
  };

  return (
    <>
      <ContentWrap>
        <SubTitleContainer title="Information" textType={TextType.h3} iconPath={ICON.ICON_INFORMATION} required={true}></SubTitleContainer>
        <ContentBox>
          <FormControl name="Name" height={56}
            control={
              <Input showWarning={isErrorRequestName} warningText="Please enter less than 200 characters." value={requestName} onChange={handleNameInputValue} placeholder="Please input request name" width={360}></Input>
            }
          ></FormControl>
        </ContentBox>
      </ContentWrap>
    </>

  )
}