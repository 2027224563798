import { AxiosError } from "axios";
import Input from "components/Input/Input";
import { ALIGN_TYPE } from "components/List/List.type";
import EditableTableButtons from "components/Table/EditableTable/EditableTableButtons/EditableTableButtons";
import TextArea from "components/TextArea/TextArea";
import { useAiCore } from "hooks/feature/aiCore/useAiCore";
import { useEffect, useState } from "react";
import styles from "styles/module/editrow.module.scss";
import { VersionRowProps, VersionType } from "./VersionRow.type";

export default function VersionRow({ index, version, isDeleteDisable = false, aiCoreId, onDelete, onUpdate, onSave, setDeleteTargetId }: VersionRowProps) {
  const { getCheckVersion } = useAiCore();
  const [isSavedInfo, setIsSavedInfo] = useState(version.isSaved);
  const [isEditMode, setIsEditMode] = useState(version.editMode);

  const [rowIndex, setRowIndex] = useState(index);
  const [versionValue, setVersionValue] = useState(version.version);
  const [editVersionValue, setEditVersionValue] = useState(version.version);
  const [note, setNote] = useState(version.note);
  const [editNote, setEditNote] = useState(version.note);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isErrorVersion, setIsErrorVersion] = useState(false);
  const [isErrorNote, setIsErrorNote] = useState(false);
  const [isDuplicatedError, setIsDuplicatedError] = useState(false);

  useEffect(() => {
    setValues(version);
  }, [version]);

  useEffect(() => {
    setRowIndex(index);
  }, [index]);

  useEffect(() => {
    setIsErrorNote(editNote ? editNote.length > 5000 : false);
  }, [editNote]);

  useEffect(() => {
    const normalizedValue = normalizeNumber(editVersionValue);
    normalizedValue !== versionValue ? fetchCheckDuplication(normalizedValue) : setIsDuplicatedError(false);
    setIsErrorVersion(normalizedValue ? isDuplicatedError : false);
  }, [editVersionValue, isDuplicatedError])

  useEffect(() => {
    const isNotChanged = version.isSaved && editNote === note && editVersionValue === versionValue;
    setIsSaveDisabled(isNotChanged || !editVersionValue || isErrorVersion || isErrorNote);
  }, [editVersionValue, editNote, isErrorVersion, isErrorNote]);

  const setValues = ({ version, note, editMode, isSaved }: VersionType) => {
    setVersionValue(version);
    setEditVersionValue(version);
    setNote(note);
    setEditNote(note);
    setIsEditMode(editMode);
    setIsSavedInfo(isSaved);
  }

  const normalizeNumber = (inputValue: string) => {
    const regex = /^\d*\.?\d*$/;
    if (regex.test(inputValue)) {
      if (!inputValue.includes('.')) {
        return `${inputValue}.0`;
      }

      if (inputValue.endsWith('.')) {
        return `${inputValue}0`;
      }

      return inputValue;
    } else {
      return inputValue;
    }
  }

  const handleChangeVersion = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const regex = /^(?!\.)\d*\.?\d{0,1}$/;
    if (regex.test(inputValue) || inputValue === '') {
      setIsErrorVersion(false);
      setEditVersionValue(inputValue);
    } else {
      setIsErrorVersion(true);
      return;
    }
  }

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditNote(e.target.value);
  }

  const handleSaveInfo = async () => {
    const updatedVersion: VersionType = {
      no: version.no,
      id: version.id,
      version: Number(editVersionValue).toFixed(1),
      note: editNote,
      editMode: false,
      isSaved: true,
      isDeleted: false,
    }

    const result = isSavedInfo ? await onUpdate(updatedVersion) : await onSave(updatedVersion);
    if (result) {
      setEditVersionValue(Number(editVersionValue).toFixed(1));
      setEditNote(editNote);
    }
  }

  const handleCancelInfo = () => {
    setIsEditMode(false);
    setEditVersionValue(versionValue);
    setEditNote(note);
    if (isSavedInfo) {
      setDeleteTargetId(undefined);
      return;
    }
    onDelete && onDelete();
    setDeleteTargetId(undefined);
  }

  const fetchCheckDuplication = async (versionValue: string) => {
    const result = await getCheckVersion(aiCoreId, versionValue);
    setIsDuplicatedError(result instanceof AxiosError);
  }

  return (
    <tr className={styles["version-row-tr"]}>
      <td className={styles["-version"]}>
        {isEditMode ? (
          <Input debounce showWarning={isErrorVersion} warningText={isDuplicatedError ? "A duplicate version exists." : "Please check version form."} defaultValue={editVersionValue} onChange={handleChangeVersion} width={80} textAlign={ALIGN_TYPE.center}></Input>
        ) : (
          <span>{versionValue}</span>
        )}
      </td>
      <td className={styles["-note"]}>
        {isEditMode ? (
          <TextArea name='text' maxLength={300} value={editNote} placeholder="Please input description" onChange={handleChangeNote} ></TextArea>
        ) : (
          <span>{note?.split('\n').map((line, i) => {
            return (
              <span key={i}>{line}</span>
            )
          })
          }</span>
        )}
      </td>
      <td>
        {version.isDeleted ? (
          <span className={styles["-deleted"]}>Deleted</span>
        ) :
          <EditableTableButtons
            onClickEdit={() => setIsEditMode(true)}
            onClickSave={handleSaveInfo}
            onClickCancel={handleCancelInfo}
            onClickDelete={() => onDelete()}
            isDeleteDisabled={isDeleteDisable}
            isEditing={isEditMode}
            setIsEditing={setIsEditMode}
            isSaveDisabled={isSaveDisabled}
          ></EditableTableButtons>
        }

      </td>
    </tr>
  )
}