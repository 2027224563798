import { useAxiosIDF } from 'api/useAxiosIDF';
import { AxiosResponse } from 'axios';
import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import { EndPoint } from 'data/endPoint';
import { IdfTokenParams, IdfTokenResponse, ImageryInfoParams, ImageryInfoResponse, ProviderInfoParams } from 'types/feature/idf/idf';
import { setLocalStorage } from 'utils/auth/localStorage-utils';

export const useIdf = () => {
  const  { apiIdf } = useAxiosIDF();

  const getIdfToken = async ({ uid, upw }: IdfTokenParams) => {
    try {
      const result = await apiIdf.post<IdfTokenResponse>(EndPoint.idfToken, {
        uid,
        upw
      });
      if(result) {
        if (result.code === "OK") {
          setLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN, result.access_key, 1);
        }
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getImageryInfo = async ({access_key, bbox, begin_date, end_date, image_data_id, sort_order, image_type, provides, resolution, status, classification, data_name}: ImageryInfoParams) => {
    try {
      const result = await apiIdf.post<AxiosResponse<ImageryInfoResponse[]>>(EndPoint.idfImageryDatas, {
        access_key, bbox, begin_date, end_date, image_data_id, sort_order, image_type, provides, resolution, status, classification, data_name
      });
  
      if(result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getProviderList = async ({ access_key }: ProviderInfoParams) => {
    try {
      const result = await apiIdf.get<AxiosResponse<string[]>>(EndPoint.idfProvider.replace(':accessKey', access_key));

      if(result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  }

  return {
    getIdfToken,
    getImageryInfo,
    getProviderList
  }
}