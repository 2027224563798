import axios, { AxiosError, AxiosInstance } from "axios";

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
  put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
  delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

export const useAxiosIDF = () => {
  const apiIdf: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_IDF,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  apiIdf.interceptors.request.use(
    async res => {
      const config = res;
      config.headers['accept'] = '*/*';

      return config;
    },
    error => Promise.reject(error),
  );

  apiIdf.interceptors.response.use(
    response => {
      return response.data;
    },
    (error: AxiosError) => {
      const { response } = error;

      if (response) {
        // apiIdf 에러
        // handleResponseError(error);
      }

      // 서버 장애, 네트워크 에러 등
      // navigate(RoutePath.serverError);
      throw error;
    },
  );

  return { apiIdf };
}