import ListContainer from "components/Container/List/ListContainer/ListContainer";
import SearchContainer from "components/Container/List/SearchContainer/SearchContainer";
import TitleContainer from "components/Container/Title/TitleContainer/TitleContainer";
import { FilterDatas } from "components/Filter/Filter.type";
import List from "components/List/List";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import ListHeader from "components/List/ListHeader/ListHeader";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { TextType } from "components/Title/Title.type";
import { idfParams } from "constants/auth";
import { DATE_FORMAT } from "constants/common";
import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import { SEARCH_QUERY_KEYS } from "constants/searchQueryKey";
import dayjs from "dayjs";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useIdf } from "hooks/feature/idf/useIdf";
import { useEffect, useState } from "react";
import { searchStore } from "stores/search-stores";
import { ImageryInfoParams } from "types/feature/idf/idf";
import { getLocalStorage } from "utils/auth/localStorage-utils";
import { byteToGiga, getKeyByValue, setListNumber } from "utils/common-utils";
import styles from "./ImageData.module.scss";
import { IMAGE_DATASET_CLASSIFICATION_TYPE, IMAGE_DATASET_CLASSIFICATION_VIEW_TYPE, IMAGE_DATASET_IMAGE_CLASSIFICATION, IMAGE_DATASET_IMAGE_STATUS, IMAGE_DATASET_IMAGE_TYPE, IMAGE_DATASET_RESOLUTION_VIEW_TYPE, IMAGE_DATASET_STATUS_TYPE, IMAGE_DATASET_STATUS_VIEW_TYPE, IMAGE_DATASET_TYPE_TYPE, IMAGE_DATASET_TYPE_VIEW_TYPE, ImageDataset } from "./ImageData.type";

export default function ImageData() {
  const { getIdfToken, getImageryInfo, getProviderList } = useIdf();
  const { displayTypeOptions, setFilterOptions, setDisplayTypeOptions, setSelectedOption, searchReset } = searchStore();
  const { searchParams, setInitialParam, getSearchParam } = useCustomSearchParams();

  /* List */
  const columns: Column[] = [
    { header: 'No', field: 'no', className: ['no'] },
    { header: 'Image data set', field: 'name', align: ALIGN_TYPE.left, className: ['blue-bold', 'imagedataset-name', 'ellipsis'] },
    { header: 'Mode', field: 'type' },
    { header: 'Classification', field: 'classification' },
    { header: 'Resolution', field: 'resolution' },
    { header: 'Size', field: 'size' },
    { header: 'Provider', field: 'provider' },
    { header: 'Status', field: 'status', className: ['chip'] },
    { header: 'Created', field: 'createDate' },
    { header: 'Latest update\n(Registration)', field: 'updateDate', className: ['preline'] },
  ]
  const colStyles: React.CSSProperties[] = [
    { width: '5%' },
    { width: '23%' },
    { width: '9%' },
    { width: '9%' },
    { width: '8%' },
    { width: '8%' },
    { width: '11%' },
    { width: '8%' },
    { width: '8%' },
    { width: '11%' },
  ];
  const [imageDatas, setImageDatas] = useState<ImageDataset[]>([]);

  useEffect(() => {
    setInitialData();
    fetchProviderList();
    return () => searchReset();
  }, []);

  useEffect(() => {
    setInitialParam();
  }, [displayTypeOptions])

  useEffect(() => {
    fetchImageryList();
  }, [searchParams]);

  const setInitialData = () => {
    const options = [
      { label: 'Latest update date', value: 'update_date' },
      { label: 'Created date', value: 'time_get_image' },
      { label: 'Registration date', value: 'insert_date' },
    ];
    setDisplayTypeOptions(options);
    setSelectedOption(options[0]);
  }

  const fetchIdfToken = async () => {
    await getIdfToken(idfParams);
  }

  const fetchImageryList = async () => {
    // if (!selectedOption) return;
    let accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    if (!accessKey) {
      await fetchIdfToken();
      accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    }
    const sort_order: { [key: string]: string } = {};
    const order = getSearchParam(SEARCH_QUERY_KEYS.SORT);
    const direction = getSearchParam(SEARCH_QUERY_KEYS.DIRECTION);
    if (order) {
      sort_order[order] = direction || "DESC";
    } else {
      sort_order['update_date'] = direction || "DESC";
    }
    const resolutionOptions = getSearchParam(SEARCH_QUERY_KEYS.RESOLUTION) ? getSearchParam(SEARCH_QUERY_KEYS.RESOLUTION)?.split(',') : [];
    let resolutionFilter = null;
    if (resolutionOptions && resolutionOptions.length > 0) {
      resolutionFilter = `~${resolutionOptions.reduce((prev, curr) => { return prev > curr ? prev : curr })}cm`;
    }

    const params: ImageryInfoParams = {
      access_key: accessKey,
      bbox: null,
      sort_order,
      begin_date: getSearchParam(SEARCH_QUERY_KEYS.LIMIT_START)?.replaceAll('.', '-') || null,
      end_date: getSearchParam(SEARCH_QUERY_KEYS.LIMIT_END)?.replaceAll('.', '-') || null,
      image_type: getSearchParam(SEARCH_QUERY_KEYS.MODE) ? getSearchParam(SEARCH_QUERY_KEYS.MODE)?.split(',') : [],
      provides: getSearchParam(SEARCH_QUERY_KEYS.PROVIDER) ? getSearchParam(SEARCH_QUERY_KEYS.PROVIDER)?.split(',') : [],
      resolution: resolutionFilter,
      status: getSearchParam(SEARCH_QUERY_KEYS.STATUS) ? getSearchParam(SEARCH_QUERY_KEYS.STATUS)?.split(',') : [],
      classification: getSearchParam(SEARCH_QUERY_KEYS.CLASSIFICATION) ? getSearchParam(SEARCH_QUERY_KEYS.CLASSIFICATION)?.split(',') : [],
      data_name: getSearchParam(SEARCH_QUERY_KEYS.SEARCH_KEYWORD) as string || '',
    }

    const result = await getImageryInfo(params);
    if (!result || !result.data) return;
    const updatedImageDataList: ImageDataset[] = [];
    result.data.forEach((data, i) => {
      const item: ImageDataset = {
        no: setListNumber(i, result.data.length, getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) === "ASC"),
        name: data.data_name,
        type: data.image_type ? IMAGE_DATASET_IMAGE_TYPE[data.image_type] : undefined,
        classification: data.classification ? IMAGE_DATASET_IMAGE_CLASSIFICATION[data.classification] : undefined,
        resolution: data.resolution,
        size: `${data.size ? byteToGiga(data.size) : 0} GB`,
        provider: data.provides,
        status: data.status !== undefined ? IMAGE_DATASET_IMAGE_STATUS[data.status] : undefined,
        createDate: dayjs(data.time_get_image).format(DATE_FORMAT),
        updateDate: `${dayjs(data.update_date).format(DATE_FORMAT)}\n(${dayjs(data.insert_date).format(DATE_FORMAT)})`
      }
      updatedImageDataList.push(item);
    })
    setImageDatas(updatedImageDataList);
  }

  const getModeValue = (value: string) => {
    return getKeyByValue(IMAGE_DATASET_IMAGE_TYPE, value);
  }

  const getStatusValue = (value: string) => {
    return getKeyByValue(IMAGE_DATASET_IMAGE_STATUS, value);
  }

  const getClassificationValue = (value: string) => {
    return getKeyByValue(IMAGE_DATASET_IMAGE_CLASSIFICATION, value);
  }

  const setChecked = (param: string, value: string | undefined) => {
    const filterQueryParam = getSearchParam(param)?.split(',');
    if (!filterQueryParam || !value) return false;

    return filterQueryParam.includes(value as string) || false
  }

  const fetchProviderList = async () => {
    let accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    if (!accessKey) {
      await fetchIdfToken();
      accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    }
    const response = await getProviderList({ access_key: accessKey });
    if (!response) return;
    const updatedFilterOptions: FilterDatas[] = [
      {
        title: 'Mode',
        values: [
          { label: IMAGE_DATASET_TYPE_VIEW_TYPE.satellite, value: getModeValue(IMAGE_DATASET_TYPE_TYPE.satellite), checked: setChecked(SEARCH_QUERY_KEYS.MODE, getModeValue(IMAGE_DATASET_TYPE_TYPE.satellite)) },
          { label: IMAGE_DATASET_TYPE_VIEW_TYPE.aerial, value: getModeValue(IMAGE_DATASET_TYPE_TYPE.aerial), checked: setChecked(SEARCH_QUERY_KEYS.MODE, getModeValue(IMAGE_DATASET_TYPE_TYPE.aerial)) },
          { label: IMAGE_DATASET_TYPE_VIEW_TYPE.drone, value: getModeValue(IMAGE_DATASET_TYPE_TYPE.drone), checked: setChecked(SEARCH_QUERY_KEYS.MODE, getModeValue(IMAGE_DATASET_TYPE_TYPE.drone)) },
        ]
      },
      {
        title: 'Provider',
        values: [
        ]
      },
      {
        title: 'Classification',
        values: [
          { label: IMAGE_DATASET_CLASSIFICATION_VIEW_TYPE.raw, value: getClassificationValue(IMAGE_DATASET_CLASSIFICATION_TYPE.raw), checked: setChecked(SEARCH_QUERY_KEYS.CLASSIFICATION, getClassificationValue(IMAGE_DATASET_CLASSIFICATION_TYPE.raw)) },
          { label: IMAGE_DATASET_CLASSIFICATION_VIEW_TYPE.orthophoto, value: getClassificationValue(IMAGE_DATASET_CLASSIFICATION_TYPE.orthophoto), checked: setChecked(SEARCH_QUERY_KEYS.CLASSIFICATION, getClassificationValue(IMAGE_DATASET_CLASSIFICATION_TYPE.orthophoto)) },
        ]
      },
      {
        title: 'Resolution',
        values: [
          { label: IMAGE_DATASET_RESOLUTION_VIEW_TYPE[10], value: 10, checked: setChecked(SEARCH_QUERY_KEYS.RESOLUTION, "10") },
          { label: IMAGE_DATASET_RESOLUTION_VIEW_TYPE[20], value: 20, checked: setChecked(SEARCH_QUERY_KEYS.RESOLUTION, "20") },
          { label: IMAGE_DATASET_RESOLUTION_VIEW_TYPE[30], value: 30, checked: setChecked(SEARCH_QUERY_KEYS.RESOLUTION, "30") },
          { label: IMAGE_DATASET_RESOLUTION_VIEW_TYPE[40], value: 40, checked: setChecked(SEARCH_QUERY_KEYS.RESOLUTION, "40") },
        ]
      },
      {
        title: 'Status',
        values: [
          { label: IMAGE_DATASET_STATUS_VIEW_TYPE.activation, value: getStatusValue(IMAGE_DATASET_STATUS_TYPE.activation), checked: setChecked(SEARCH_QUERY_KEYS.STATUS, getStatusValue(IMAGE_DATASET_STATUS_TYPE.activation)) },
          { label: IMAGE_DATASET_STATUS_VIEW_TYPE.disabled, value: getStatusValue(IMAGE_DATASET_STATUS_TYPE.disabled), checked: setChecked(SEARCH_QUERY_KEYS.STATUS, getStatusValue(IMAGE_DATASET_STATUS_TYPE.disabled)) },
        ]
      },
    ]

    response.data.forEach(provider => {
      const value: CommonOptionType = {
        label: provider,
        value: provider,
        checked: setChecked(SEARCH_QUERY_KEYS.PROVIDER, provider)
      };
      updatedFilterOptions.find(option => option.title === 'Provider')?.values.push(value);
    })
    setFilterOptions(updatedFilterOptions);
  }

  return (
    <div className={styles["imagedata__container"]}>
      <TitleContainer title="Image data" textType={TextType.h2}
        rightComponent={
          <></>
          // <Button color={BUTTON_COLOR.blue} text="Registration" size={"size80"} iconPath={ICON.ICON_PLUS} width={106} onClick={() => setIsShowPopup(true)} disabled></Button>
        }
      ></TitleContainer>
      <SearchContainer>
        <SearchFilter calendarPlaceholder="Created date"></SearchFilter>
      </SearchContainer>
      <ListContainer>
        <ListHeader total={imageDatas.length}></ListHeader>
        <List columns={columns} datas={imageDatas} colStyles={colStyles}></List>
      </ListContainer>
    </div>
  )
}