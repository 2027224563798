import { FilterDatas } from "components/Filter/Filter.type";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { create } from "zustand";

type SearchStoreState = {
  /* Filter */
  filterOptions: FilterDatas[];
  /* Order */
  displayTypeOptions: CommonOptionType[];
  selectedOption: CommonOptionType | undefined;
  isASC: boolean;
  /* Calendar */
  resetCalendar: boolean;
  initCalendarDay: (string | null)[];
  selectedCalendarDay: (string | null)[];
  /* Search Title */
  searchValue: string;
}

type SearchStoreActions = {
  /* Filter */
  setFilterOptions: (filterOptions: FilterDatas[]) => void;
  /* Order */
  setDisplayTypeOptions: (displayTypeOptions: CommonOptionType[]) => void;
  setSelectedOption: (selectedOption: CommonOptionType) => void;
  setIsASC: (isASC: boolean) => void;
  /* Calendar */
  setResetCalendar: (resetCalendar: boolean) => void;
  setInitCalendarDay: (initCalendarDay: (string | null)[]) => void;
  setSelectedCalendarDay: (selectedCalendarDay: (string | null)[]) => void;
  // handleDate: (dates: (string | null)[]) => void;
  /* Search Title */
  setSearchValue: (searchValue: string) => void;
  handleInputValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchReset: () => void;

}

const initialState: SearchStoreState = {
  /* Filter */
  filterOptions: [],
  /* Order */
  displayTypeOptions: [],
  selectedOption: undefined,
  isASC: false,
  /* Calendar */
  resetCalendar: true,
  initCalendarDay: [],
  selectedCalendarDay: [],
  /* Search Title */
  searchValue: '',
}

export const searchStore = create<SearchStoreState & SearchStoreActions>((set, get) => ({
  /* Filter */
  filterOptions: initialState.filterOptions,
  setFilterOptions: (filterOptions: FilterDatas[]) => set({ filterOptions }),
  /* Order */ 
  displayTypeOptions: initialState.displayTypeOptions,
  setDisplayTypeOptions: (displayTypeOptions: CommonOptionType[]) => set({ displayTypeOptions }),
  selectedOption: initialState.selectedOption,
  setSelectedOption: (selectedOption: CommonOptionType) => set({ selectedOption }),
  isASC: initialState.isASC,
  setIsASC: (isASC: boolean) => set({ isASC }),
  /* Calendar */
  resetCalendar: initialState.resetCalendar,
  setResetCalendar: (resetCalendar: boolean) => set({ resetCalendar }),
  initCalendarDay: initialState.initCalendarDay,
  setInitCalendarDay: (initCalendarDay: (string | null)[]) => set({ initCalendarDay}),
  selectedCalendarDay: initialState.selectedCalendarDay,
  setSelectedCalendarDay: (selectedCalendarDay: (string | null)[]) => set({ selectedCalendarDay}),
  // handleDate: (dates: (string | null)[]) => get().setSelectedCalendarDay(dates),
  /* Search Title */
  searchValue: initialState.searchValue,
  setSearchValue: (searchValue: string) => set({ searchValue }),
  handleInputValue: (e: React.ChangeEvent<HTMLInputElement>) => get().setSearchValue(e.target.value),
  searchReset: () => {
    set(initialState)
  }
}))