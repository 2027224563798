import { AxiosError } from "axios";
import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import Input from "components/Input/Input";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import EditableTableButtons from "components/Table/EditableTable/EditableTableButtons/EditableTableButtons";
import { ADMIN_ROLES } from "constants/auth";
import { ICON } from "constants/icons";
import { useMember } from "hooks/feature/member/useMember";
import { useEffect, useState } from "react";
import styles from "styles/module/editrow.module.scss";
import { isValidString, validateEmail } from "utils/common-utils";
import { MemberRowProps, MemberType } from "./MemberRow.type";

export default function MemberRow({ index, member, type, onSave, onDelete, onUpdate, onResetPassword, setDeleteTargetId }: MemberRowProps) {
  const { getCheckMemberUserName, getCheckMemberEmail } = useMember();

  const [isSavedInfo, setIsSavedInfo] = useState(member.isSaved);
  const [isEditMode, setIsEditMode] = useState(member.editMode);

  const [rowIndex, setRowIndex] = useState(index);
  const [userName, setUserName] = useState(member.userName);
  const [editName, setEditName] = useState(member.userName);
  const [userID, setUserID] = useState(member.userID);
  const [editID, setEditID] = useState(member.userID);
  const [email, setEmail] = useState(member.email);
  const [editEmail, setEditEmail] = useState(member.email);
  const [grade, setGrade] = useState<'super' | 'project' | undefined>(undefined);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isErrorUserName, setIsErrorUserName] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isErrorId, setIsErrorId] = useState(false);

  const [isDuplicatedUserID, setIsDuplicatedUserID] = useState(false);
  const [isDuplicatedEmail, setIsDuplicatedEmail] = useState(false);

  const [editGradeOption, setEditGradeOption] = useState<CommonOptionType | undefined>(undefined);
  const [gradeNameOptions, setGradeNameOptions] = useState<CommonOptionType[]>([]);

  useEffect(() => {
    const options: CommonOptionType[] = [
      { label: ADMIN_ROLES.SUPER, value: ADMIN_ROLES.SUPER },
      { label: ADMIN_ROLES.PROJECT, value: ADMIN_ROLES.PROJECT },
    ]
    setGradeNameOptions(options);
  }, [type]);

  useEffect(() => {
    setValues(member);
  }, [member]);

  useEffect(() => {
    setRowIndex(index)
  }, [index])

  useEffect(() => {
    setIsErrorUserName(editName ? editName.length < 2 || editName.length > 50 : false);
  }, [editName]);

  useEffect(() => {
    (!member.isSaved || member.editMode) && editName !== '' && fetchCheckDuplicationUserID();
    setIsErrorId(editID ? editID.length < 5 || editID.length > 15 || !isValidString(editID, ['-', '_', '.']) : false);
  }, [editID]);

  useEffect(() => {
    (!member.isSaved || member.editMode) && editEmail !== '' && fetchCheckDuplicationEmail();
    setIsErrorEmail(editEmail ? !validateEmail(editEmail) : false);
  }, [editEmail]);

  useEffect(() => {
    const isNotChanged = member.isSaved && (editName === userName && editID === userID && editEmail === email);

    setIsSaveDisabled(isNotChanged || !editName || !editID || !editEmail || isErrorUserName || isErrorId || isErrorEmail || isDuplicatedEmail || isDuplicatedUserID);
  }, [editName, editID, editEmail, isErrorUserName, isErrorId, isErrorEmail, isDuplicatedEmail, isDuplicatedUserID]);

  const fetchCheckDuplicationUserID = async () => {
    if (!editID) return;
    const result = await getCheckMemberUserName(editID);
    setIsDuplicatedUserID(result instanceof AxiosError);
  }

  const fetchCheckDuplicationEmail = async () => {
    if (!editEmail) return;
    const result = await getCheckMemberEmail(editEmail);
    setIsDuplicatedEmail(result instanceof AxiosError);
  }

  const setValues = ({ userName, userID, email, editMode, isSaved }: MemberType) => {
    setUserName(userName);
    setUserID(userID);
    setEmail(email);
    setIsEditMode(editMode);
    setIsSavedInfo(isSaved);
  }

  const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditName(e.target.value);
  }

  const handleChangeUserID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditID(e.target.value);
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditEmail(e.target.value);
  }

  const handleSaveInfo = () => {
    const updatedMember: MemberType = {
      no: member.no,
      id: member.id,
      userName: editName,
      userID: editID,
      email: editEmail,
      editMode: false,
      isSaved: true
    }
    setValues(updatedMember);
    !isSavedInfo && editName && editID && editEmail && onSave(editName, editID, editEmail);
    isSavedInfo && onUpdate(updatedMember);
    setEditName(editName);
    setEditID(editID);
    setEditEmail(editEmail);
  }

  const handleCancelInfo = () => {
    setIsEditMode(false);
    setEditName(userName);
    setEditID(userID);
    setEditEmail(email);
    if (isSavedInfo) {
      setDeleteTargetId(undefined);
      return;
    }
    onDelete && onDelete();
    setDeleteTargetId(undefined);
  }

  const handleEditInfo = () => {
    setIsEditMode(true);
    setIsSaveDisabled(true);
  }

  return (
    <tr className={classNames(styles["member-row-tr"], { [styles["-warning"]]: isEditMode && (isErrorEmail || isErrorUserName || isErrorId || isDuplicatedEmail || isDuplicatedUserID) })}>
      <td className={styles["-index"]}>
        {index}
      </td>
      {type === "admin" &&
        <td>
          <SelectBox width={'100%'} placeholder="Select grade" selected={editGradeOption} setSelected={setEditGradeOption} options={gradeNameOptions}></SelectBox>
        </td>
      }
      <td>
        {isEditMode ? (
          <Input showWarning={isErrorUserName} warningText={`Please enter at least 2 to 50 characters.`} defaultValue={editName} onChange={handleChangeUserName} placeholder="Please input the user name"></Input>
        ) : (
          <span>{userName}</span>
        )}
      </td>
      <td>
        {isEditMode ? (
          <Input debounce showWarning={isErrorId || isDuplicatedUserID} warningText={isDuplicatedUserID ? 'A duplicate id exists.' : `Please check your id form.`} defaultValue={editID} onChange={handleChangeUserID} placeholder="Please input the user ID" disabled={member.isSaved}></Input>
        ) : (
          <span>{userID}</span>
        )}
      </td>
      <td className={styles["-email"]}>
        <div className={styles["email__wrap"]}>
          {isEditMode ? (
            <Input debounce showWarning={isErrorEmail || isDuplicatedEmail} warningText={isDuplicatedEmail ? 'A duplicate e-mail exists.' : `Please check your e-mail form.`} type="email" required={true} defaultValue={editEmail} onChange={handleChangeEmail} placeholder="Please input the e-mail address" containerStyle="width-100"></Input>
          ) : (
            <>
              <span>
                {email}
              </span>
              <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[45]} text="New password" width={127} iconPath={ICON.ICON_MESSAGE} onClick={() => onResetPassword(member.id)} />
            </>
          )}
        </div>
      </td>
      <td>
        <EditableTableButtons
          onClickEdit={handleEditInfo}
          onClickSave={handleSaveInfo}
          onClickCancel={handleCancelInfo}
          onClickDelete={onDelete}
          // isEditingDefault={isEditMode}
          isEditing={isEditMode}
          setIsEditing={setIsEditMode}
          isSaveDisabled={isSaveDisabled}
        ></EditableTableButtons>
      </td>
    </tr>
  )
}