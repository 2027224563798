import { Map } from "ol";
import { create } from "zustand";

type MapStore = {
  map: Map | undefined;
  setMap: (map: Map) => void;
};

export const mapStore = create<MapStore>((set) => ({
  map: undefined,
  setMap: (map) => set({ map }),
}));
