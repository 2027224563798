import { ImagePathType } from "components/Popup/IDFPopup/IDFPopup.type";
import { RequestImageSetType } from "components/RequestDetail/ImageDataSet/RequestImageDataSet.type";
import { ImageryInfoResponse } from "types/feature/idf/idf";
import { create } from "zustand";

type ImageDataSetStoreState = {
  idfPathList: ImageryInfoResponse[]; //api 응답으로 온 원본 데이터
  projectImageDataSet: RequestImageSetType[]; //프로젝트에 할당된 전체 이미지 데이터셋
  requestImageDataSet: RequestImageSetType[]; //요청에 선택된 이미지 데이터셋
  imagePathList: ImagePathType[]; //프로젝트에 할당할 전체 이미지 데이터셋
  // assignedPathList: ImagePathType[]; //프로젝트에 할당된 이미지 데이터셋
  filterStartDate: string | null;
  filterEndDate: string | null;
  filterBbox: string | null;
}

type ImageDataSetStoreActions = {
  setIdfPathList: (idfPathList: ImageryInfoResponse[]) => void;
  setProjectImageDataSet: (projectImageDataSet: RequestImageSetType[]) => void;
  setRequestImageDataSet: (requestImageDataSet: RequestImageSetType[]) => void;
  setImagePathList: (imagePathList: ImagePathType[]) => void;
  // setAssignedPathList: (assignedPathList: ImagePathType[]) => void;
  setFilterStartDate: (filterStartDate: string | null) => void;
  setFilterEndDate: (filterEndDate: string | null) => void;
  setFilterBbox: (filterBbox: string | null) => void;
  reset: () => void;  
};

const initialState: ImageDataSetStoreState = {
  filterStartDate: null,
  filterEndDate: null,
  filterBbox: null,

  idfPathList: [],
  projectImageDataSet: [],
  requestImageDataSet: [],
  imagePathList: [],
  // assignedPathList: [],
}

export const imageDataSetStore = create<ImageDataSetStoreState & ImageDataSetStoreActions>((set) => ({
  idfPathList: initialState.idfPathList,
  setIdfPathList: (idfPathList) => set({ idfPathList }),
  projectImageDataSet: initialState.projectImageDataSet,
  setProjectImageDataSet: (projectImageDataSet) => set({ projectImageDataSet }),
  requestImageDataSet: initialState.requestImageDataSet,
  setRequestImageDataSet: (requestImageDataSet) => set({ requestImageDataSet }),
  filterStartDate: initialState.filterStartDate,
  setFilterStartDate: (filterStartDate) => set({filterStartDate }),  
  filterEndDate: initialState.filterEndDate,
  setFilterEndDate: (filterEndDate) => set({ filterEndDate}),
  filterBbox: initialState.filterBbox,
  setFilterBbox: (filterBbox) => set({ filterBbox }),
  imagePathList: initialState.imagePathList,
  setImagePathList: (imagePathList) => set({ imagePathList }),
  // assignedPathList: initialState.assignedPathList,
  // setAssignedPathList: (assignedPathList) => set({ assignedPathList }),
  reset: () => {
    set(initialState)
  },
}));
