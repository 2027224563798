import { TextType } from 'components/Title/Title.type';
import { MouseEvent } from 'react';

export type ButtonProps = {
  loading?: boolean;
  text?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  color?: ButtonColor;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactElement;
  buttonStyle?: string;
  textStyle?: TextType;
  id?: string;
  iconPath?: string;
  width?: number;
  iconWidth?: number;
  iconHeight?: number;
};

export const BUTTON_COLOR = {
  blue: 'blue',
  black: 'black',
  white: 'white',
  red: 'red',
} as const;

export type ButtonColor = (typeof BUTTON_COLOR)[keyof typeof BUTTON_COLOR];

export const BUTTON_SIZE = {
  45: 'size45',
  80: 'size80',
  120: 'size120',
} as const;

export type ButtonSize = (typeof BUTTON_SIZE)[keyof typeof BUTTON_SIZE];
