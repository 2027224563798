import { TOKEN_ROLES } from "constants/auth";
import { RoutePath } from "data/router/paths";
import { TokenRoles } from "types/auth/auth";

type Menu = {
  path: string;
  name: string;
  children: string[],
  roles: TokenRoles[],
}

export const MENUS: Menu[] = [
  // { path: RoutePath.home, name: 'Home',
  //   children: [
  //     RoutePath.home,
  //     RoutePath.default
  //   ]
  // },
  
  // { path: RoutePath.enterprise, name: 'Enterprise', 
  //   children: [
  //     RoutePath.enterprise,
  //     RoutePath.enterpriseDetail
  //   ],
  //   roles: []
  // },
  { path: RoutePath.company, name: 'Company', 
    children: [
      RoutePath.company,
      RoutePath.companyDetail,
      RoutePath.companyDetailRegist,
      // RoutePath.administratorSetting
    ],
    roles: []
  },
  // { path: RoutePath.enterprise, name: 'Enterprise', 
  //   children: [
  //     RoutePath.enterprise,
  //     RoutePath.enterpriseDetail
  //   ],
  //   roles: []
  // },
  { path: RoutePath.aicore, name: 'AI Core',
    children: [
      RoutePath.aicore,
      RoutePath.aicoreDetail,
      RoutePath.aicoreDetailRegist
    ],
    roles: [TOKEN_ROLES.SUPER_ADMIN]
  },
  { path: RoutePath.imagedata, name: 'Image data',
    children: [
      RoutePath.imagedata,
    ],
    roles: []
  },
  { path: RoutePath.project, name: 'Project', 
    children: [
      RoutePath.project,
      RoutePath.projectDetail,
      RoutePath.projectDetailRegist,
      RoutePath.projectRequestDetail,
    ],
    roles: []
  },
  // { path: RoutePath.aoi, name: 'AOI', 
  //   children: [
  //     RoutePath.aoi,
  //   ]
  // },
  // { path: RoutePath.setting, name: 'Setting',
  //   children: [
  //     RoutePath.setting,
  //   ]
  // },
]