import axios, { AxiosError, AxiosInstance } from "axios";
import { HTTP_HEADERS } from "constants/auth";
import useHandleError from "hooks/common/useHandleError";
import { authStore } from "stores/auth-stores";

interface CustomInstance extends AxiosInstance {
  get<T>(...params: Parameters<AxiosInstance['get']>): Promise<T>;
  post<T>(...params: Parameters<AxiosInstance['post']>): Promise<T>;
  put<T>(...params: Parameters<AxiosInstance['put']>): Promise<T>;
  delete<T>(...params: Parameters<AxiosInstance['delete']>): Promise<T>;
}

export const useAxios = () => {
  const { handleResponseError } = useHandleError();
  const { accessToken, memberId, companyId } = authStore();

  const mediaApi: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  })

  mediaApi.interceptors.request.use(
    async res => {
      const config = res;

      if(accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      config.headers['Content-Type'] = 'multipart/form-data';

      if (memberId) {
        config.headers[HTTP_HEADERS.DABEEO_MEMBER_ID] = memberId;
      }
      if (companyId) {
        config.headers[HTTP_HEADERS.DABEEO_COMPANY_ID] = companyId;
      }

      return config;
    },
    error => Promise.reject(error),
  );

  const api: CustomInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });
  
  api.interceptors.request.use(
    async res => {
      const config = res;
      
      if(accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      config.headers['Content-Type'] = 'application/json; charset=utf-8';

      if (memberId) {
        config.headers[HTTP_HEADERS.DABEEO_MEMBER_ID] = memberId;
      }
      if (companyId) {
        config.headers[HTTP_HEADERS.DABEEO_COMPANY_ID] = companyId;
      }

      return config;
    },
    error => Promise.reject(error),
  );

  api.interceptors.response.use(
    response => {
      return response.data;
    },
    async (error: AxiosError) => {
      return (error.config?.baseURL === process.env.REACT_APP_BASE_URL) ? await handleResponseError(error) : Promise.reject(error);
    },
  );

  return { api, mediaApi };
}