import { create } from "zustand";

type CommonStore = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const commonStore = create<CommonStore>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
}));
