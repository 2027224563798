import { useAxios } from "api/useAxios";
import { AxiosError, AxiosResponse } from "axios";
import { EndPoint } from "data/endPoint";
import { CompanyListParams, CompanyListResponse, CompanyParams, CompanyRegistParams, CompanyRegistResponse, CompanyResponse } from "types/feature/company/company";

export const useCompany = () => {
  const { api } = useAxios();

  //Company 상세 조회
  const getCompanyDetail = async (companyId: number) => {
    const result = await api.get<CompanyResponse>(EndPoint.companyDetail.replace(':companyId', String(companyId)));
    return result;
  }

  //Company 수정
  const updateCompany = async (companyId: number, { id, name, memberId }: CompanyParams) => {
    const result = await api.put<CompanyResponse>(EndPoint.companyDetail.replace(':companyId', String(companyId)), { id, name, memberId });
    return result;
  }
  
  //Company 삭제
  const deleteCompany = async (companyId: number) => {
    const result = await api.patch<AxiosResponse>(EndPoint.companyDetail.replace(':companyId', String(companyId)));
    return result;
  }

  //Company 목록 조회
  const getCompanyList = async ({ page, size, sort = '', direction = '', searchStartDate = '', searchEndDate = '', searchKeyword = '' }: CompanyListParams) => {
    const result = await api.get<CompanyListResponse>(`${EndPoint.company}?sort=${sort}&direction=${direction}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}&searchKeyword=${searchKeyword}`);
    return result;
  }

  //Company 등록
  const setCompany = async ({ name, url }: CompanyRegistParams) => {
    const result = await api.post<CompanyRegistResponse>(EndPoint.company, { name, url });
    return result;
  }

  //Company URL 중복 체크용 목록 조회 (기존)
  const getCompanyUrls = async () => {
    const result = await api.get<string[]>(EndPoint.companyUrls);
    return result;
  }

  //Company URL 중복 체크용 API (신규)
  const getCheckCompanyUrl = async (url: string) => {
    try {
      const result = await api.get(EndPoint.companyCheckUrl.replace(':url', url));
      return result;
    } catch (e) {
      return (e as AxiosError);
    }
  }

  return {
    getCompanyDetail,
    updateCompany,
    deleteCompany,
    getCompanyList,
    setCompany,
    getCompanyUrls,
    getCheckCompanyUrl,
  }
}