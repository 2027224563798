import classNames from "classnames";
import styles from './Title.module.scss';
import { TextType } from "./Title.type";

type TitleProps = {
  text: string;
  textType: TextType;
  color?: string;
};

export default function Title({ text, textType, color }: TitleProps) {
  return <div className={classNames(styles[`${textType}`])}>{text}</div>;
};

