import classNames from "classnames";
import Checkbox from "components/CheckBox/CheckBox";
import { imageDataSetStore } from "stores/image-dataset-stores";
import styles from './IDFPathItem.module.scss';
import { IDFPathItemProps } from "./IDFPathItem.type";

export default function IDFPathItem({ path, index }: IDFPathItemProps) {
  const { imagePathList, setImagePathList } = imageDataSetStore();

  const handleClickPath = () => {
    path.checked = !path.checked;
    const updatedImagePathList = [...imagePathList];
    updatedImagePathList.map((imagePath) => {
      if (imagePath.idx === path.idx) {
        return path;
      }
    });
    setImagePathList(updatedImagePathList);
  }

  return (
    <>
      <div className={classNames(styles["list-item"])}>
        <Checkbox label={path.name} id={`path${index}`} checked={path.checked} onChange={handleClickPath}></Checkbox>
      </div>

    </>
  );
};
