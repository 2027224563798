
import classNames from "classnames";
import IconButton from "components/IconButton/IconButton";
import { ICON } from "constants/icons";
import styles from "./EditButton.module.scss";
import { EditButtonProps } from "./EditButton.type";

export default function EditableTableButtons({
  onClickDelete,
  onClickEdit,
  onClickCancel,
  onClickSave,
  buttonStyle,
  isEditing,
  setIsEditing,
  isSaveDisabled = false,
  isDeleteDisabled = false,
}: EditButtonProps) {
  return (
    <div className={classNames(styles["edit-button__container"], styles[`${buttonStyle}`])}>
      {!isEditing &&
        <>
          <IconButton iconPath={ICON.ICON_DELETE} onClick={onClickDelete} disabled={isDeleteDisabled}></IconButton>
          <IconButton iconPath={ICON.ICON_EDIT} onClick={() => { setIsEditing(true); onClickEdit && onClickEdit(); }}></IconButton>
        </>
      }
      {isEditing &&
        <>
          <IconButton iconPath={ICON.ICON_X} onClick={() => { onClickCancel && onClickCancel(); }} buttonStyle="btn-regist-cancel" ></IconButton>
          <IconButton iconPath={ICON.ICON_CHECK_WHITE} onClick={() => { setIsEditing(false); onClickSave && onClickSave(); }} buttonStyle="btn-regist-save" disabled={isSaveDisabled}></IconButton>
        </>
      }
    </div>
  )
}