import classNames from "classnames";
import styles from "./IconButton.module.scss";
import { IconButtonProps } from "./IconButton.type";

export default function IconButton({
  onClick,
  disabled,
  buttonStyle,
  iconPath,
  iconWidth,
  iconHeight
}: IconButtonProps) {

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles['icon_button__container'], styles[`${buttonStyle}`])}>
      <>
        {iconPath && <img src={iconPath} alt='' style={{ width: iconWidth ? iconWidth : 'auto', height: iconHeight ? iconHeight : 'auto' }} />}
      </>
    </button>
  )
}