import ContentContainer from "components/Container/Content/ContentContainer/ContentContainer";
import BlockTitleContainer from "components/Container/Title/BlockTitleContainer/BlockTitleContainer";
import CompanyInformation from "components/Information/Company/CompanyInformation";
import Member from "components/Member/Member";
import { TextType } from "components/Title/Title.type";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useCompany } from "hooks/feature/company/useCompany";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function CompanyDetail() {
  const location = useLocation();
  const { navigateToReplacePath, navigateToBack } = useCustomNavigate();
  const { getCompanyDetail } = useCompany();

  const [isRegist, setIsRegist] = useState(location.pathname === RoutePath.companyDetailRegist);

  const [companyId, setCompanyId] = useState<number>();
  const [companyName, setCompanyName] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    setIsRegist(location.pathname === RoutePath.companyDetailRegist);
    const url = new URLSearchParams(location.search);
    const id = url.get(URL_QUERY.COMPANY_ID);
    if (!isRegist && !id) {
      navigateToReplacePath(RoutePath.company);
    }
    setCompanyId(Number(id));
  }, [location]);

  useEffect(() => {
    fetchCompanyDetail();
  }, [companyId])

  const fetchCompanyDetail = async () => {
    if (!companyId) return;
    const result = await getCompanyDetail(companyId);
    setCompanyId(result.id);
    setCompanyName(result.name);
    setUrl(result.url);
  }

  return (
    <>
      <BlockTitleContainer title={`Company`} textType={TextType.h2} backButton onClickBackButton={navigateToBack}></BlockTitleContainer>
      <ContentContainer>
        <CompanyInformation companyId={companyId} setCompanyId={setCompanyId} companyName={companyName} setCompanyName={setCompanyName} url={url} setUrl={setUrl} isRegist={isRegist}></CompanyInformation>
        {!isRegist &&
          <Member targetId={companyId} type="company"></Member>
        }
        {/* <div className={styles["detail_flex_row"]}>
          <ProjectList></ProjectList>
          <AOIList></AOIList>
        </div> */}
      </ContentContainer>
    </>
  )
}