import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ModalContainer from "components/Container/Modal/ModalContainer";
import Input from "components/Input/Input";
import { useAuth } from "hooks/feature/login/useAuth";
import { useState } from "react";
import Popup from "../Popup";
import styles from './PasswordPopup.module.scss';
import { PasswordPopupProps } from "./PasswordPopup.type";

export default function PasswordPopup({ onClosePopup }: PasswordPopupProps) {
  const { resetPassword } = useAuth();

  const [name, setName] = useState('');
  const [emailOrId, setEmailOrId] = useState('');
  const warningMsg = 'Please check your e-mail or user ID';
  const [showWarning, setShowWarning] = useState(false);
  const handleResetPassword = async () => {
    if (name === '' || emailOrId === '') return;
    const response = await resetPassword(name, emailOrId);
    if (response) {
      onClosePopup();
    } else {
      setShowWarning(true);
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setName(inputValue);
  }

  const handleEmailOrIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setEmailOrId(inputValue);
  }

  return (
    <ModalContainer>
      <Popup headerText="Reset Password" onClosePopup={onClosePopup} bottomButtons={[
        <Button color={BUTTON_COLOR.white} text="Cancel" width={120} onClick={onClosePopup}></Button>,
        <Button color={BUTTON_COLOR.black} text="Send new password to e-mail" width={370} onClick={handleResetPassword} disabled={name === '' || emailOrId === ''}></Button>
      ]}>
        <div className={styles["reset-password__body"]}>
          <div className={styles["text__area"]}>
            <span className={styles["text-info"]}>If you've lost your password or wish to reset it, Please input your information.</span>
            <div className={styles["text__content"]}>
              <ul className={styles["text-ul"]}>
                <li className={styles["text-li"]}>
                  <div className={styles["-dot"]}></div>
                  <div>Your name and e-mail (or User ID) must match.</div>
                </li>
                <li className={styles["text-li"]}>
                  <div className={styles["-dot"]}></div>
                  <div>Press the <span className={styles["-bold"]}>Send new password to e-mail</span> button and we will send you a temporary password to your e-mail.</div>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles["input__area"]}>
            <Input placeholder="Please input your name" width={380} onChange={handleNameChange}></Input>
            <Input placeholder="Please input your e-mail or user ID" width={380} onChange={handleEmailOrIdChange}></Input>
          </div>
          {showWarning && <div className={styles["warning-text"]}>{warningMsg}</div>}
        </div>
      </Popup>
    </ModalContainer>
  );
};
