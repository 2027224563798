import { useAxios } from "api/useAxios";
import { AxiosError } from "axios";
import { EndPoint } from "data/endPoint";
import { AiCoreListParams, AiCoreListResponse, AiCoreParams, AiCoreRegistParams, AiCoreResponse, AiCoreTask, AiCoreVersion, AiCoreVersionParams, AiCoreVersionResponse } from "types/feature/aiCore/aiCore";
import { setArrayParam } from "utils/common-utils";

const TASK_KEY = 'task'
const STATUS_KEY = 'status'
export const useAiCore = () => {
  const { api } = useAxios();

  //AI Core 목록 조회
  const getAiCoreList = async ({ page, size, sort, direction, searchKeyword, task, status }: AiCoreListParams) => {    
    const result = await api.get<AiCoreListResponse>(`${EndPoint.aiCore}?${setArrayParam(TASK_KEY, task.map(i => String(i)))}&${setArrayParam(STATUS_KEY, status.map(i => String(i)))}&sort=${sort}&direction=${direction}&searchKeyword=${searchKeyword}`);
    return result;
  }

  //AI Core 상세 조회
  const getAICoreDetail = async (aiCoreId: number) => {
    const result = await api.get<AiCoreResponse>(EndPoint.aiCoreDetail.replace(':aiCoreId', String(aiCoreId)));
    return result;
  }

  //AI Core 등록
  const postAiCore = async (core: AiCoreRegistParams) => {
    const result = await api.post<AiCoreResponse>(EndPoint.aiCore, core);
    return result;
  }

  //AI Core 수정
  const updateAICore = async (core: AiCoreParams) => {
    const result = await api.put<AiCoreResponse>(EndPoint.aiCoreDetail.replace(':aiCoreId', String(core.id)), core);
    return result;
  }

  //AI Core 삭제
  const deleteAICore = async (aiCoreId: number) => {
    const result = await api.patch<AiCoreResponse>(EndPoint.aiCoreDetail.replace(':aiCoreId', String(aiCoreId)));
    return result;
  }

  //AI Core Version 목록 조회
  const getAICoreVersionList = async (aiCoreId: number) => {
    const result = await api.get<AiCoreVersion[]>(EndPoint.aiCoreVersion.replace(':id', String(aiCoreId)));
    return result;
  }

  //AI Core Version 등록
  const postAiCoreVersion = async (verson: AiCoreVersionParams) => {
    const result = await api.post<AiCoreVersionResponse>(EndPoint.aiCoreVersion.replace(':id', String(verson.aiCoreId)), verson);
    return result;
  }

  //AI Core Version 수정
  const updateAICoreVersion = async (version: AiCoreVersionParams) => {
    const result = await api.put<AiCoreVersionResponse>(EndPoint.aiCoreVersionDetail.replace(':id', String(version.aiCoreId)).replace(':versionId', String(version.id)), version);
    return result;
  }

  //AI Core Version 삭제
  const deleteAICoreVersion = async (aiCoreId: number, versionId: number) => {
    const result = await api.patch<AiCoreVersionResponse>(EndPoint.aiCoreVersionDetail.replace(':id', String(aiCoreId)).replace(':versionId', String(versionId)));
    return result;
  }

  //Task 목록 조회
  const getTaskList = async () => {
    const result = await api.get<AiCoreTask[]>(EndPoint.aiCoreTask);
    return result;
  }

  //AI Core Model ID 중복 체크용 API
  const getCheckModelID = async (task: string, modelId: string) => {
    try {
      const result = await api.get(EndPoint.aiCoreCheckModelId.replace(':task', task).replace(':modelId', modelId));
      return result;
    } catch (e) {
      return (e as AxiosError);
    }
  }

  //AI Core Version 중복 체크용 API
  const getCheckVersion = async (modelId: number, version: string) => {
    try {
      const result = await api.get(EndPoint.aiCoreCheckVersion.replace(':id', String(modelId)).replace(':version', version));
      return result;
    } catch (e) {
      return (e as AxiosError);
    }
    
  }

  return {
    getAICoreDetail,
    updateAICore,
    deleteAICore,
    updateAICoreVersion,
    deleteAICoreVersion,
    getAiCoreList,
    postAiCore,
    getAICoreVersionList,
    postAiCoreVersion,
    getTaskList,
    getCheckModelID,
    getCheckVersion
  }
}