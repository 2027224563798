import Checkbox from "components/CheckBox/CheckBox";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { ChangeEvent, useEffect, useState } from "react";
import { setFilterQuery } from "utils/filter-utils";
import { FilterOptionType } from "../Filter.type";
import styles from "./FilterRow.module.scss";
import { FilterRowProps } from "./FilterRow.type";

export default function FilterRow({ props, totalCount = 0, setTotalCount, isChildSelectAll, isSelectAll, setChildenAllSelect, updatedFilterDatas }: FilterRowProps) {
  const [checkOptions, setCheckOptions] = useState<FilterOptionType[]>(props.values);
  const [allChecked, setAllChecked] = useState(false);

  const { setSearchParam, setSearchParams } = useCustomSearchParams();

  useEffect(() => {
    setCheckOptions(props.values);
  }, [props.values]);

  useEffect(() => {
    setAllChecked(isChildSelectAll)
  }, [isChildSelectAll]);

  const updateTotalCount = (count: number) => {
    setTotalCount && setTotalCount(count);
  }

  const handleSelectOne = (e: ChangeEvent<HTMLInputElement>) => {
    let updatedList = checkOptions.map(opt => opt.label === e.target.value ? { ...opt, checked: e?.target.checked } : opt);
    setCheckOptions(updatedList);
    updateTotalCount(totalCount += e?.target.checked ? 1 : totalCount - 1);
    updatedFilterDatas && updatedFilterDatas(props.title, updatedList);
    setFilterQuery(props.title, updatedList, setSearchParam);
    setAllChecked(updatedList.filter(i => i.checked).length === updatedList.filter(i => !i.disabled).length);

  }

  const handleSelectHead = (e: ChangeEvent<HTMLInputElement>) => {
    handleSelectAll(e?.target.checked, false);
    setChildenAllSelect && setChildenAllSelect(props.title, e?.target.checked);
  }

  const handleSelectAll = (isCheck: boolean, isSelectAll: boolean) => {
    const count = checkOptions.filter(i => (isCheck ? !i.checked : i.checked) && !i.disabled).length;
    checkOptions.forEach((value) => value.disabled || (value.checked = isCheck));
    updatedFilterDatas && updatedFilterDatas(props.title, checkOptions);
    setFilterQuery(props.title, checkOptions, setSearchParam);
    updateTotalCount(totalCount += isCheck ? count : -count);
    setAllChecked(isCheck);
  }

  const initCheckOptions = () => {
    return checkOptions.map((value, i) => {
      return <Checkbox key={i} label={value.label} height={36} id={value.label} checked={value.checked || false} onChange={handleSelectOne} disabled={value.disabled} />
    });
  };

  return (
    <div className={styles["filter-row__container"]}>
      <Checkbox id={`filter${props.title}`} label={props.title} onChange={handleSelectHead} checked={allChecked || false}></Checkbox>
      <div className={styles["filter-row-values__wrap"]}>
        {initCheckOptions()}
      </div>
    </div>
  )
}