export function byteToGiga(bytes: number) {
  return Number((bytes / 1024 / 1024 / 1024).toFixed(2));
}

export function validateEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
  return emailRegex.test(email);
};

export function getKeyByValue(object: any, value: string) {
  return Object.keys(object).find(key => String(object[key]).toLowerCase() === value.toLowerCase());
}

export function setVersion(value: number | string, addValue: number | string) {
  return Math.round((Number(value) + Number(addValue)) * 10) / 10;
}

export const setStrFloor = (value: string | number, number: number) => {
  //number + 1번째 자리에서 버림
  return (Math.floor(Number(value) * Math.pow(10, number)) / Math.pow(10, number));
};

export const setStrFixed = (value: string | number, num: number) => {
  return Number(value).toFixed(num);
};

export const setStrLocaleString = (value: string | number, num: number) => {
  return setStrFloor(value, num).toLocaleString();
}

export const allowSpecificSpecialCharacters = (input: string, allowedChars: string[], allowKor: boolean): string => {
  const escapedAllowedChars = allowedChars.map(char => char.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('');
  const regExp = new RegExp(`[^a-zA-Z0-9${allowKor && `/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/`}${escapedAllowedChars}]`, 'g');

  return input.replace(regExp, '');
}

export const isValidString = (input: string, allowedChars: string[], allowKor?: boolean): boolean => {
  const escapedAllowedChars = allowedChars.map(char => char.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('');
  const regExp = new RegExp(`[^a-zA-Z0-9${allowKor && `ㄱ-ㅎ|ㅏ-ㅣ|가-힣`}${escapedAllowedChars}]`);

  // 불허용할 문자가 포함되어 있으면 false, 그렇지 않으면 true 반환
  return !regExp.test(input);
}

export const isValidPassword = (input: string) => {
  const regex = /^[A-Za-z0-9~!@#$%^()_\-={}\[\]|;:<>.,?\/]{8,16}$/;
  return regex.test(input);
}

export const setListNumber = (index: number, total: number, isASC: boolean) => {
  return isASC ? index + 1 : total - index;
}

export const setArrayParam = (key: string, list: Array<string>) => {
  return list.reduce((str, value) => {
    return `${str}&${key}=${value}`;
  }, '');
};

/**
 * @description 2024.07.31 added by kay 
 *              env로 설정한 EE2 User Domain을 반환한다. 
 * @returns {string}
 */
export const getEE2UserDomain = () => process.env.REACT_APP_EE_USER_URL;
