// import { testImageData } from "assets/testData/testImageDataSet";
import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import Calendar from "components/Calendar/Calendar";
import ModalContainer from "components/Container/Modal/ModalContainer";
import IconButton from "components/IconButton/IconButton";
import DrawControl from "components/Map/DrawControl/DrawControl";
import ViewControl from "components/Map/ViewControl/ViewControl";
import ZoomControl from "components/Map/ZoomControl/ZoomControl";
import Tooltip from "components/Tooltip/Tooltip";
import { ICON } from "constants/icons";
import { MAP_OPTIONS } from "constants/map/map-option";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { OSM } from "ol/source";
import { useEffect, useMemo, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { mapStore } from "stores/map-stores";
import { projectStore } from "stores/project-stores";
import { TASK_TYPE } from "types/feature/project/project";
import popupStyles from "../Popup.module.scss";
import DataSetPathItem from "./DataSetPathItem/DataSetPathItem";
import styles from "./ImageDataSetPopup.module.scss";
import { ImageDataSetPopupProps } from "./ImageDataSetPopup.type";

export default function ImageDataSetPopup({ onClosePopup, onReloadClick, isLoad }: ImageDataSetPopupProps) {
  const { setMap } = mapStore();
  const { projectImageDataSet, setProjectImageDataSet, setRequestImageDataSet, setFilterStartDate, setFilterEndDate, setFilterBbox } = imageDataSetStore();
  const { selectedTask } = projectStore();

  const [selectedCount, setSelectedCount] = useState(0);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setIsSelectDisabled(selectedTask?.value.code === TASK_TYPE.CHANGE_DETECTION && selectedCount !== 2);
  }, [selectedTask, selectedCount]);

  /* Calendar */
  const [resetCalendar, setResetCalendar] = useState(true);
  const [selectedCalendarDay, setSelectedCalendarDay] = useState<(string | null)[]>([]);
  const handleDate = (dates: (string | null)[]) => {
    setSelectedCalendarDay(dates);
  };

  useEffect(() => {
    return () => {
      setFilterStartDate(null);
      setFilterEndDate(null);
      setFilterBbox(null);
    }
  }, []);

  useEffect(() => {
    setFilterStartDate(selectedCalendarDay[0]);
    setFilterEndDate(selectedCalendarDay[1]);
  }, [selectedCalendarDay]);

  /* Map */
  const [showCustomCursor, setShowCustomCursor] = useState(false);
  const [showStartDrawing, setStartDrawing] = useState(false);
  const [isExistBoundingBox, setIsExistBoundingBox] = useState(false);
  const [isDrawMode, setIsDrawMode] = useState(false);

  const [styleLeft, setStyleLeft] = useState("");
  const [styleTop, setStyleTop] = useState("");

  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    isDrawMode && !isExistBoundingBox && setShowCustomCursor(true);
    const parentRect = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - parentRect.left;
    const offsetY = e.clientY - parentRect.top;
    setStyleLeft(offsetX + 16 + "px");
    setStyleTop(offsetY + 16 + "px");
  }

  const setupOpenlayersMap = () => {
    const view = new View({
      center: fromLonLat([127.766, 36.355]),
      zoom: 6.5,
      minZoom: MAP_OPTIONS.MIN_ZOOM,
      maxZoom: MAP_OPTIONS.MAX_ZOOM,
    });

    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: view
    });
    setMap(map);

    // (window as any).map = map;
  }

  useEffect(() => {
    let sum = 0;
    projectImageDataSet.forEach((imageset) => {
      if (imageset.checked) {
        sum += imageset.images.filter(i => i.checked).length;
      };
    });

    setSelectedCount(sum);
  }, [projectImageDataSet]);

  useEffect(() => {
    setupOpenlayersMap();
  }, []);

  const handleSelectClick = () => {
    const requestSet = projectImageDataSet.filter(i => i.checked).map((imageData) => {
      const newImages = imageData.images.filter(i => i.checked);
      imageData.images = newImages;
      return imageData;
    }) || [];
    setRequestImageDataSet(requestSet);
    onClosePopup && onClosePopup();
  }

  const handleReloadClick = () => {
    setResetCalendar(!resetCalendar);
    setFilterBbox(null);
    setIsDrawMode(false);
    setFilterStartDate(null);
    setFilterEndDate(null);
    setReloadProjectImageDataSet();
  }

  const setReloadProjectImageDataSet = () => {
    onReloadClick(true);
  }

  const initPathItem = useMemo(() => {
    return <>
      {
        projectImageDataSet.map((path, i) => (
          <DataSetPathItem path={path} index={i} key={i} arrow isOpen={i === 0}></DataSetPathItem>
        ))
      }</>

  }, [projectImageDataSet]);

  return (
    <ModalContainer>
      <div className={classNames(styles["image-dataset-popup__container"])}>
        <div className={styles["popup__header"]}>
          <div className={styles["-title"]}>IDF image data set</div>
          <button onClick={onClosePopup}><img src={ICON.ICON_CLOSE_POPUP_GRAY} alt=""></img></button>
        </div>
        <div className={styles["popup__body"]}>
          <div className={styles["filter__wrap"]}>
            <div className={styles["-period"]}>
              <span className={styles["period-span"]}>Period</span>
              <Calendar width={240} handleDate={handleDate} resetDate={resetCalendar}></Calendar>
            </div>
            <IconButton iconPath={ICON.ICON_RELOAD_BLACK} onClick={handleReloadClick}></IconButton>
          </div>
          <div className={styles["content__wrap"]}>
            <div className={popupStyles["list__area"]}>
              <div className={popupStyles["-list-header"]}>
                <div className={popupStyles["-space"]}></div>
                <div className={popupStyles["-col"]} style={{ width: 327 }}>
                  <span>File name</span>
                </div>
                <div className={popupStyles["-col"]} style={{ width: 90 }}>
                  <span>Resolution</span>

                  <div className={popupStyles["tooltip__wrap"]}>
                    <img src={ICON.ICON_INFO} alt="" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
                    {showTooltip &&
                      <div className={popupStyles["tooltip__area"]}>
                        <Tooltip text={"Analyzing images with a resolution of 40 cm or lower is recommended"} arrowAlign="top" textAlign={"center"}></Tooltip>
                      </div>
                    }
                  </div>
                </div>
                <div className={popupStyles["-col"]} style={{ width: 100 }}><span>Provider</span></div>
                <div className={popupStyles["-col"]} style={{ width: 70 }}><span>Size</span></div>
                <div className={popupStyles["-col"]} style={{ width: 91 }}><span className={styles["-create-date"]}>Created date</span></div>
              </div>
              <div className={popupStyles["-list__wrap"]}>
                {projectImageDataSet && isLoad && initPathItem}
              </div>
            </div>
            <div className={styles["map__area"]}>
              <div id="map" className={styles["map"]} onMouseMove={handleMouseEnter} onMouseLeave={() => setShowCustomCursor(false)}>
                {showCustomCursor &&
                  <div className="-guide-cursor" style={{ left: styleLeft, top: styleTop }}>{`Click to ${showStartDrawing ? 'end' : 'start'} drawing`}</div>
                }
              </div>
              <ViewControl></ViewControl>
              <ZoomControl></ZoomControl>
              <DrawControl setShowCustomCursor={setShowCustomCursor} setStartDrawing={setStartDrawing} setIsExistBoundingBox={setIsExistBoundingBox} isDrawMode={isDrawMode} setIsDrawMode={setIsDrawMode}></DrawControl>
            </div>
          </div>
        </div>
        <div className={styles["popup__bottom"]}>
          <Button text="Cancel" color={BUTTON_COLOR.white} width={120} onClick={onClosePopup}></Button>
          {selectedCount === 0 ?
            <Button text="Selected" color={BUTTON_COLOR.black} width={200} onClick={handleSelectClick} disabled></Button> :
            <Button color={BUTTON_COLOR.black} children={
              <div className={styles["-image-data-set-btn"]}>
                <span className={classNames(styles["-count"], { [styles["-disabled"]]: isSelectDisabled })}>{selectedCount}</span>
                <span> Selected</span>
              </div>
            } width={200} onClick={handleSelectClick} disabled={isSelectDisabled}></Button>
          }
        </div>
      </div>
    </ModalContainer>

  )
}