export const RoutePath = {
  login: '/login',
  
  home: '/',
  company: '/company',
  // enterprise: '/enterprise',
  aicore: '/aicore',
  imagedata: '/imagedata',
  project: '/project',
  aoi: '/aoi',
  setting: '/setting',
  uikit: '/uikit',
  accountPassword: '/account/password',
  
  aicoreDetail: '/aicore/detail',
  aicoreDetailRegist: '/aicore/detail/regist',
  projectDetail: '/project/detail',
  projectDetailRegist: '/project/detail/regist',
  projectRequestDetail: '/project/request/detail',

  companyDetail: '/company/detail',
  companyDetailRegist: '/company/detail/regist',
  // administratorSetting: '/company/adminSetting',

  // enterpriseDetail: '/enterprise/detail',

  default: '/'
}