import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import Calendar from "components/Calendar/Calendar";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import FormControl from "components/Container/Content/FormControl/FormControl";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import IconButton from "components/IconButton/IconButton";
import Input from "components/Input/Input";
import Alert from "components/Popup/Alert/Alert";
import IDFPopup from "components/Popup/IDFPopup/IDFPopup";
import MemberPopup from "components/Popup/MemberPopup/MemberPopup";
import { MemberData } from "components/Popup/MemberPopup/MemberPopup.type";
import Radio from "components/Radio/Radio";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { TextType } from "components/Title/Title.type";
import { DATE_FORMAT } from "constants/common";
import { ICON } from "constants/icons";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import dayjs from "dayjs";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useCompany } from "hooks/feature/company/useCompany";
import { useMember } from "hooks/feature/member/useMember";
import { useProject } from "hooks/feature/project/useProject";
import { useEffect, useRef, useState } from "react";
import { projectStore } from "stores/project-stores";
import styles from "styles/module/information.module.scss";
import { CompanyListParams } from "types/feature/company/company";
import { PERIOD_TYPE, PeriodType } from "types/feature/project/project";
import { ProjectInformationProps } from "./ProjectInformation.type";

export default function ProjectInformation({ isRegist }: ProjectInformationProps) {
  const { navigateToReplacePath } = useCustomNavigate();
  const { reset, projectId, projectName, setProjectName, projectCompanyId, setProjectCompanyId, projectCompany, setProjectCompany, fileName, setFileName, fileURL, setFileURL, periodValue, setPeriodValue, periodDateValue, setPeriodDateValue, periodStartDate, setPeriodStartDate, periodEndDate, setPeriodEndDate, editImageDataIds, setEditImageDataIds, imageDataIds, setImageDataIds } = projectStore();
  const { getCompanyList } = useCompany();
  const { postProject, updateProject, deleteProject } = useProject();
  const { getCompanyMembers } = useMember();

  const [isShowAlert, setIsShowAlert] = useState(false);

  const [members, setMembers] = useState<MemberData[]>([]);

  const [companyOptions, setCompanyOptions] = useState<CommonOptionType[]>();
  const [editCompanyOption, setEditCompanyOption] = useState(projectCompany);
  const [editFileName, setEditFileName] = useState('');
  const [editPeriodValue, setEditPeriodValue] = useState<PeriodType | undefined>();
  const [editPeriodDateValue, setEditPeriodDateValue] = useState<(string | null)[]>([]);
  const [editFileURL, setEditFileURL] = useState('');

  const [isFindIDF, setIsFindIDF] = useState(false);
  const [isFindMember, setIsFindMember] = useState(false);
  const [isShowContent, setIsShowContent] = useState(true);
  const [isOverFileSize, setIsOverFileSize] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [files, setFiles] = useState<FileList>();

  const [selectedCalendarDay, setSelectedCalendarDay] = useState<(string | null)[]>([]);
  const handleDate = (dates: (string | null)[]) => {
    setSelectedCalendarDay(dates);
    setEditPeriodDateValue(dates);
  };

  useEffect(() => {
    fetchCompanyList();
  }, []);

  useEffect(() => {
    fetchMemberList();
  }, [projectCompany])

  useEffect(() => {
    if (isRegist) {
      reset();
    }
  }, [isRegist]);

  useEffect(() => {
    setIsSaveDisabled(projectName.length === 0 || !editCompanyOption || (editPeriodValue === PERIOD_TYPE.limit && (editPeriodDateValue.length === 0 || editPeriodDateValue.includes('Invalid Date'))));
    !isRegist && isEditMode && setIsSaveDisabled(editCompanyOption?.value === companyOptions?.find(i => i.value.id === projectCompanyId)?.value && editPeriodDateValue === periodDateValue && editPeriodValue === periodValue && editFileURL === fileURL && editImageDataIds === imageDataIds);
  }, [editCompanyOption, editPeriodDateValue, editPeriodValue, editFileURL, editImageDataIds]);

  useEffect(() => {
    !isEditMode && setIsSaveDisabled(true);
  }, [isEditMode])

  useEffect(() => {
    setEditFileName(fileName);
  }, [fileName]);

  useEffect(() => {
    setEditImageDataIds(imageDataIds);
  }, [imageDataIds])

  useEffect(() => {
    setEditCompanyOption(companyOptions?.find(i => i.value.id === projectCompanyId));
  }, [projectCompanyId, companyOptions]);

  useEffect(() => {
    setEditPeriodValue(periodValue);
  }, [periodValue]);

  useEffect(() => {
    const startDate = periodDateValue[0] ? dayjs(periodDateValue[0], DATE_FORMAT).toDate() : undefined; //new Date(periodDateValue[0]) : undefined;
    const endDate = periodDateValue[1] ? dayjs(periodDateValue[1], DATE_FORMAT).toDate() : undefined; //new Date(periodDateValue[1]) : undefined;
    setPeriodStartDate(startDate);
    setPeriodEndDate(endDate);
  }, [periodDateValue]);

  useEffect(() => {
    const dateValue = [dayjs(periodStartDate).format(DATE_FORMAT), dayjs(periodEndDate).format(DATE_FORMAT)];
    setSelectedCalendarDay(dateValue);
    setEditPeriodDateValue(dateValue);
  }, [periodStartDate, periodEndDate]);

  useEffect(() => {
    setEditFileURL(fileURL);
  }, [fileURL]);

  const inputRef = useRef(null);

  const handleInputClick = () => {
    if (!inputRef.current) return;
    (inputRef.current as HTMLElement).click();
  }

  const handleSaveClick = async () => {
    const formData = new FormData();
    files && formData.append("file", files[0], files[0].name);
    formData.append("projectName", projectName);
    formData.append("companyId", String(editCompanyOption?.value.id));
    // formData.append("periodOfUse", editPeriodValue === PERIOD_TYPE.limit ? "true" : "false");
    formData.append("periodOfUse", "true");
    formData.append("limitStartPeriod", selectedCalendarDay[0] || '');
    formData.append("limitEndPeriod", selectedCalendarDay[1] || '');
    editImageDataIds.forEach((id) => {
      formData.append("imageDataSetList", String(id));
    });

    const result = isRegist ? await fetchPostProject(formData) : await fetchUpdateProject(formData);

    if (result) {
      setFileName(editFileName);
      setProjectCompanyId(editCompanyOption?.value.id);
      setProjectCompany(editCompanyOption);
      editPeriodValue && setPeriodValue(editPeriodValue);
      setPeriodDateValue(editPeriodDateValue);
      setIsEditMode(false);
      setFileURL(editFileURL);
      setImageDataIds(editImageDataIds);
      isRegist && navigateToReplacePath(`${RoutePath.projectDetail}?${URL_QUERY.PROJECT_ID}=${result.data.id}`);
    }
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return;
    const selectedFile = e.target.files[0];
    setFiles(e.target.files);
    if (selectedFile.size > 1024 ** 2 * 5) {
      setIsOverFileSize(true);
      console.log('용량 초과'); return;
    }
    setEditFileName(selectedFile.name);
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      setEditFileURL(e.target.result as string);
    }
    reader.readAsDataURL(selectedFile);
  }

  const handleNameInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setProjectName(value);
  };

  const handleCancelClick = () => {
    setEditFileName(fileName);
    setEditCompanyOption(companyOptions?.find(i => i.value.id === projectCompanyId));
    setEditPeriodValue(periodValue);
    setEditPeriodDateValue(periodDateValue);
    setEditFileURL(fileURL);
    setEditImageDataIds(imageDataIds);
    const startDate = periodDateValue[0] ? new Date(periodDateValue[0]) : undefined;
    const endDate = periodDateValue[1] ? new Date(periodDateValue[1]) : undefined;
    setPeriodStartDate(startDate);
    setPeriodEndDate(endDate);

    isRegist ? navigateToReplacePath(RoutePath.project) : setIsEditMode(false);
  }

  const handleDeleteClick = () => {
    setIsShowAlert(true);
  }

  const handleEditClick = () => {
    setIsEditMode(true);
  }

  const fetchPostProject = async (projectForm: FormData) => {
    const result = await postProject(projectForm);
    return result;
  }

  const fetchUpdateProject = async (projectForm: FormData) => {
    if (!projectId) return;
    const result = await updateProject(projectId, projectForm);
    return result;
  }

  const fetchCompanyList = async () => {
    const params: CompanyListParams = {};

    const result = await getCompanyList(params);

    if (result) {
      const list: CommonOptionType[] = [];
      result.content.forEach((data, i) => {
        const company: CommonOptionType = {
          label: data.name,
          value: data
        };
        list.push(company);
      });
      setCompanyOptions(list);
    }
  }

  const fetchMemberList = async () => {
    if (!projectCompany) return;
    const result = await getCompanyMembers(projectCompany.value.id);
    const datas: MemberData[] = [];
    result.forEach((data, i) => {
      const member: MemberData = {
        no: i + 1,
        id: data.id,
        name: data.nickname,
        userId: data.username,
        email: data.email,
        checked: false,
      };
      datas.push(member);
    });
    setMembers(datas);
  }

  const fetchDeleteProject = async () => {
    if (!projectId) return;
    const result = await deleteProject(projectId);
    setIsShowAlert(false);
    navigateToReplacePath(RoutePath.project);
  }

  return (
    <>
      <div className={styles["project-information__container"]}>
        <ContentWrap>
          <SubTitleContainer title="Information" textType={TextType.h3} iconPath={ICON.ICON_INFORMATION} required={true} rightComponent={
            <div className={styles["right-btn__area"]}>
              <div className={styles["btn-comp"]}>
                {isRegist && (
                  <>
                    <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                    <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                  </>
                )}
                {!isRegist && (
                  <>
                    {isEditMode ? (
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
                        <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Save" onClick={handleSaveClick} disabled={isSaveDisabled}></Button>
                      </>
                    ) :
                      <>
                        <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Delete" onClick={handleDeleteClick}></Button>
                        <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Edit" onClick={handleEditClick}></Button>
                      </>
                    }
                  </>
                )}
              </div>
              {isShowContent ? (
                <IconButton iconPath={ICON.ICON_ARROW_OPEN_UP} onClick={() => setIsShowContent(false)}></IconButton>
              ) : (
                <IconButton buttonStyle="black" iconPath={ICON.ICON_ARROW_CLOSE_DOWN} onClick={() => setIsShowContent(true)}></IconButton>
              )}
            </div>
          }></SubTitleContainer>
          {isShowContent ?
            <ContentBox gap={24}>
              <FormControl name="Name"
                control={
                  isRegist || isEditMode ?
                    <Input defaultValue={projectName} onChange={handleNameInputValue} placeholder="Please input project name" width={360} disabled={!isRegist && isEditMode}></Input> :
                    <span className={styles["info-span"]}>{projectName}</span>
                }
              ></FormControl>
              <FormControl name="Company"
                control={
                  isRegist || isEditMode ?
                    <SelectBox selected={editCompanyOption} setSelected={setEditCompanyOption} placeholder="Please select company name" width={360} options={companyOptions}></SelectBox> :
                    <div className={styles["company-setting"]}>
                      <span>{projectCompany?.label}</span>
                    </div>
                }
              ></FormControl>
              <FormControl name="Period of use"
                control={
                  <>
                    <div className={classNames(styles["control_period__wrap"], { [styles["show"]]: isRegist || isEditMode })}>
                      <Radio value={PERIOD_TYPE.unlimit} label="Unlimited" checked={editPeriodValue === PERIOD_TYPE.unlimit} onChange={() => setEditPeriodValue(PERIOD_TYPE.unlimit)} height={36} width={120}></Radio>
                      <Radio value={PERIOD_TYPE.limit} label="Limit period" checked={editPeriodValue === PERIOD_TYPE.limit} onChange={() => setEditPeriodValue(PERIOD_TYPE.limit)} height={36}></Radio>
                      <Calendar startDate={periodStartDate} endDate={periodEndDate} handleDate={handleDate} disabled={editPeriodValue === PERIOD_TYPE.unlimit}></Calendar>
                    </div>
                    <span className={classNames(styles["info-span"], styles["period"], { [styles["show"]]: !isRegist && !isEditMode })}>{periodValue === PERIOD_TYPE.unlimit ? 'Unlimit' : `${periodDateValue[0]} ~ ${periodDateValue[1]}`}</span>
                  </>
                }
              ></FormControl>
              <FormControl name="Logo Image"
                control={
                  isRegist || isEditMode ?
                    <div className={styles["control_logo__wrap"]}>
                      <div className={styles["filebox"]}>
                        <div className={styles["input__area"]}>
                          <Input defaultValue={editFileName} width={360} showWarning={isOverFileSize}></Input>
                          {isOverFileSize ? (
                            <div className={styles["warning-msg"]}>File upload failed: Please check the file capacity.</div>
                          ) : (
                            <ul>
                              <li className={styles["text-li"]}>
                                <div className={styles["-dot"]}></div>
                                <div>The recommended height size is 32px</div>
                              </li>
                              <li className={styles["text-li"]}>
                                <div className={styles["-dot"]}></div>
                                <div>Upload file of type: png,svg Only (Less than 5 MB)</div>
                              </li>
                            </ul>
                          )}
                        </div>
                        <label htmlFor="file">
                          <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Upload" onClick={handleInputClick}></Button>
                        </label>
                        <form name="photo" encType="multipart/form-data">
                          <input name="photo" type="file" id="file" ref={inputRef} accept=".svg,.png" onChange={(e) => handleChangeFile(e)}></input>
                        </form>
                      </div>
                      <div className={styles["image__area"]}>
                        <img src={editFileURL} alt=""></img>
                      </div>
                    </div> :
                    <div className={styles["control_logo_detail__wrap"]}>
                      <span className={styles["info-span"]}>{fileName}</span>
                      <div className={styles["image__area"]}>
                        <img src={fileURL} alt=""></img>
                      </div>
                    </div>
                }
              ></FormControl>
              <FormControl name="Image data set" control={
                <div>
                  {(isRegist || isEditMode) && (
                    editImageDataIds.length === 0 ?
                      <Button color={BUTTON_COLOR.black} text="Select" size={BUTTON_SIZE[80]} width={90} onClick={() => setIsFindIDF(true)}></Button> :
                      <Button color={BUTTON_COLOR.white} children={
                        <div className={styles["-image-data-set-btn"]}>
                          <span className={styles["-count"]}>{editImageDataIds.length}</span>
                          <span> Selected</span>
                        </div>
                      } size={BUTTON_SIZE[80]} width={110} onClick={() => setIsFindIDF(true)}></Button>
                  )}
                  {!isRegist && !isEditMode && (
                    <div className={styles["-image-data-set"]}>
                      <span className={styles["-count"]}>{imageDataIds.length}</span>
                      <span> Selected</span>
                    </div>
                  )}
                </div>
              }></FormControl>
            </ContentBox> :
            <div className={styles["split-line"]}></div>
          }
        </ContentWrap>
        {isFindIDF &&
          <IDFPopup onClosePopup={() => setIsFindIDF(false)}></IDFPopup>
        }
        {isFindMember &&
          <MemberPopup onClosePopup={() => setIsFindMember(false)} members={members} setMembers={setMembers}></MemberPopup>
        }
      </div>
      {isShowAlert &&
        <Alert
          onClosePopup={() => setIsShowAlert(false)}
          firstButton={
            <Button color={BUTTON_COLOR.white} text="Cancel" onClick={() => setIsShowAlert(false)} width={120}></Button>
          }
          secondButton={
            <Button color={BUTTON_COLOR.black} text="Confirm" onClick={() => fetchDeleteProject()}></Button>
          }
        ><span>If you delete, you can’t restore it.<br />Do you want to proceed?</span>
        </Alert>
      }
    </>


  )
}