
import styles from './ListContainer.module.scss';
import { ListContainerProps } from './ListContainer.type';
export default function ListContainer({ children }: ListContainerProps) {
  return (
    <div className={styles['list__container']}>
      {children}
    </div>
  );
};
