
import classNames from "classnames";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import IconButton from "components/IconButton/IconButton";
import List from "components/List/List";
import { Column } from "components/List/List.type";
import { ICON } from "constants/icons";
import styles from "pages/Project/RequestDetail/ProjectRequestDetail.module.scss";
import { useState } from "react";
import { RequestImageContentBoxProps } from "./RequestImageContentBox.type";
import { ImageDataType } from "./RequestImageDataSet.type";

export default function RequestImageContentBox({ imageData }: RequestImageContentBoxProps) {
  // const { requestImageDataSet, setRequestImageDataSet } = imageDataSetStore();
  const [isShowContent, setIsShowContent] = useState(true);

  const handleClickDeleteVersion = (imageSet: ImageDataType) => {
    // const updatedImageDataSet = _.cloneDeep(requestImageDataSet);
    // const pathTarget = updatedImageDataSet.find(i => i.idx === imageSet.pathIdx);
    // if (!pathTarget) return;
    // const fileIndex = pathTarget.images.findIndex(i => i.idx === imageSet.idx);
    // pathTarget.images.splice(fileIndex, 1);
    // pathTarget.images.forEach((image, i) => {
    //   image.no = i + 1;
    // })
    // // setRequestImageDataSet(updatedImageDataSet);
  }

  const columns: Column[] = [
    { header: 'No', field: 'no' },
    { header: 'Name', field: 'name', align: 'left', className: ['ellipsis'] },
    { header: 'Mode', field: 'mode' },
    { header: 'Classification', field: 'classification' },
    { header: 'Resolution', field: 'resolution' },
    { header: 'Provider', field: 'provider' },
    { header: 'Size', field: 'size' },
    { header: 'Created', field: 'createdDate' },
    { header: '', field: 'iconButton', onClick: handleClickDeleteVersion },
  ];

  const colStyles: React.CSSProperties[] = [
    { width: '6%' },
    { width: '30%' },
    { width: '9%' },
    { width: '11%' },
    { width: '9%' },
    { width: '11%' },
    { width: '9%' },
    { width: '9%' },
    { width: '6%' },
  ];

  return (
    <>
      <ContentBox>
        <div className={styles["request-image-data-set-header"]}>
          <div className={styles["text-area"]}>
            <span>{imageData.pathName}</span>
            <span className={classNames({ [styles["-gray"]]: imageData.images.length === 0 }, { [styles["-blue"]]: imageData.images.length > 0 })}>{imageData.images.length}</span>
          </div>
          {isShowContent ?
            (<IconButton iconPath={ICON.ICON_ARROW_OPEN_UP} onClick={() => setIsShowContent(false)}></IconButton>)
            :
            (<IconButton buttonStyle="black" iconPath={ICON.ICON_ARROW_CLOSE_DOWN} onClick={() => setIsShowContent(true)}></IconButton>)
          }
        </div>
        {isShowContent &&
          <>
            <div className={styles["request-image-data-set-split-line"]}></div>
            <div className={styles["request-image-data-set-table__area"]}>
              <List columns={columns} datas={imageData.images} colStyles={colStyles}></List>
            </div>
          </>
        }
      </ContentBox>
    </>
  )
}