import { TokenRoles } from "types/auth/auth";
import { create } from "zustand";


type AuthenticationInfo = { accessToken: string, userRoles: TokenRoles[], memberId: number; companyId: number, companyUrl: string };

type AuthStore = {
  userName: string | null;
  setUserName: (userName: string | null) => void;
  accessToken: string | null;
  setAccessToken: (accessToken: string | null) => void;
  userRoles: TokenRoles[];
  setUserRoles: (userRoles: TokenRoles[]) => void;
  memberId: number | null;
  setMemberId: (memberId: number | null) => void;
  companyUrl: string;
  companyId: number | null;
  setCompanyId: (memberId: number | null) => void;
  setAuthenticationInfo: (info: AuthenticationInfo) => void;
  initialize: () => void;
};

export const authStore = create<AuthStore>((set) => ({
  userName: null,
  setUserName: (userName: string | null) => set({ userName }),
  accessToken: null,
  setAccessToken: (accessToken: string | null) => set({ accessToken }),
  userRoles: [],
  setUserRoles: (userRoles) => set({ userRoles }),
  memberId: null,
  setMemberId: (memberId: number | null) => set({ memberId }),
  companyId: null,
  setCompanyId: (companyId: number | null) => set({ companyId }),
  companyUrl: '',
  setAuthenticationInfo: ({ accessToken, userRoles, memberId, companyId, companyUrl }) => set({ accessToken, userRoles, memberId, companyId, companyUrl }),
  initialize: () => set({ userName: null, accessToken: null, userRoles: [], memberId: null, companyUrl: '' })
}));
