import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ListContainer from "components/Container/List/ListContainer/ListContainer";
import SearchContainer from "components/Container/List/SearchContainer/SearchContainer";
import TitleContainer from "components/Container/Title/TitleContainer/TitleContainer";
import { FilterDatas } from "components/Filter/Filter.type";
import List from "components/List/List";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import ListHeader from "components/List/ListHeader/ListHeader";
import SearchFilter from "components/SearchFilter/SearchFilter";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { SEARCH_QUERY_KEYS } from "constants/searchQueryKey";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useProject } from "hooks/feature/project/useProject";
import { useEffect, useState } from "react";
import { projectStore } from "stores/project-stores";
import { searchStore } from "stores/search-stores";
import { OrderType } from "types/common/common";
import { ProjectListParams } from "types/feature/project/project";
import { setListNumber } from "utils/common-utils";
import styles from './Project.module.scss';
import { ProjectData } from "./Project.type";
export default function Project() {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getProjectList, getCompanyList } = useProject();
  const { filterOptions, displayTypeOptions, setFilterOptions, setDisplayTypeOptions, setSelectedOption, searchReset } = searchStore();
  const { reset } = projectStore();
  const { searchParams, setInitialParam, getSearchParam, setSearchParam } = useCustomSearchParams();

  /* List */
  const [projectList, setProjectList] = useState<ProjectData[]>([]);
  const onClickColumn = (id: number) => {
    const target = projectList.find(i => i.id === id);
    if (!target) return;
    navigateToReplacePath(`detail?${URL_QUERY.PROJECT_ID}=${target.id}`);
  }

  const columns: Column[] = [
    { header: 'No', field: 'no', className: ['no'] },
    { header: 'Name', field: 'name', align: ALIGN_TYPE.left, className: ['project-name', 'blue-bold', 'ellipsis'], onClick: onClickColumn },
    { header: 'Company', field: 'company' },
    // { header: 'Members', field: 'members' },
    { header: 'Period', field: 'period' },
    { header: 'Registration', field: 'createdDate' },
  ]

  const colStyles: React.CSSProperties[] = [
    { width: '6%' },
    { width: '68%' },
    { width: '14%' },
    // { width: '120px' },
    { width: '17%' },
    { width: '12%' },
  ];

  useEffect(() => {
    reset();
    setInitialData();
    fetchCompanyList();
    setSearchParam(SEARCH_QUERY_KEYS.SORT, 'createdDate');
    return () => searchReset();
  }, []);

  useEffect(() => {
    displayTypeOptions.length > 0 && filterOptions.length > 0 && setInitialParam();
  }, [displayTypeOptions, filterOptions])

  useEffect(() => {
    fetchProjectList();
  }, [searchParams])

  const setInitialData = () => {
    const options = [
      { label: 'Registration date', value: 'createdDate' },
      { label: 'Project name', value: 'projectName' },
      { label: 'Company name', value: 'companyName' },
    ];
    setDisplayTypeOptions(options);
    setSelectedOption(options[0]);
  }

  const fetchProjectList = async () => {
    if (!getSearchParam(SEARCH_QUERY_KEYS.SORT)) {
      setSearchParam(SEARCH_QUERY_KEYS.SORT, 'createdDate');
    }
    const params: ProjectListParams = {
      companyIds: getSearchParam(SEARCH_QUERY_KEYS.COMPANY)?.split(',') || [],//filterCompany,
      sort: getSearchParam(SEARCH_QUERY_KEYS.SORT) || 'createdDate',
      direction: getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) as OrderType || '',
      searchKeyword: getSearchParam(SEARCH_QUERY_KEYS.SEARCH_KEYWORD) as string || '',
      limitStartPeriod: getSearchParam(SEARCH_QUERY_KEYS.LIMIT_START) || '',
      limitEndPeriod: getSearchParam(SEARCH_QUERY_KEYS.LIMIT_END) || '',
    }

    const result = await getProjectList(params);

    if (result) {
      const list: ProjectData[] = [];
      result.content.forEach((data, i) => {
        const project: ProjectData = {
          no: setListNumber(i, result.content.length, getSearchParam(SEARCH_QUERY_KEYS.DIRECTION) === "ASC"),
          id: data.id,
          name: data.name,
          company: data.company.name,
          period: data.periodOfUse ? `${data.limitStartPeriod} ~ ${data.limitEndPeriod}` : 'Unlimit',
          createdDate: data.createDate
        };
        list.push(project);
      });
      setProjectList(list);
    }
  }

  const fetchCompanyList = async () => {
    const result = await getCompanyList();
    if (result) {
      const filterQueryParam = getSearchParam('Company')?.split(',');
      const updatedFilterOptions: FilterDatas[] = [
        {
          title: 'Company',
          values: [
          ]
        }
      ];
      result.forEach((company) => {
        const value: CommonOptionType = {
          label: company.name,
          value: company.id,
          checked: filterQueryParam?.includes(String(company.id)) || false
        };
        updatedFilterOptions.find(option => option.title === 'Company')?.values.push(value);
      });
      setFilterOptions(updatedFilterOptions);
    }
  }

  return (
    <div className={styles["project__container"]}>
      <TitleContainer title="Project" textType={TextType.h2}
        rightComponent={<Button color={BUTTON_COLOR.blue} text="Registration" size={"size80"} iconPath={ICON.ICON_PLUS} width={106} onClick={() => navigateToReplacePath(RoutePath.projectDetailRegist)}></Button>}
      ></TitleContainer>
      <SearchContainer>
        {
          <SearchFilter calendarPlaceholder="Period"></SearchFilter>
        }
      </SearchContainer>
      <ListContainer>
        <ListHeader total={projectList.length}></ListHeader>
        <List columns={columns} datas={projectList} colStyles={colStyles}></List>
      </ListContainer>
    </div>
  )
}