import { CommonContentType, OrderType } from "types/common/common";
import { TaskType, TaskViewType } from "../project/project";

export type AiCoreParams = {
  id: number;
  modelName: string;
  description: string;
  updatedBy?: number;
}

export type AiCoreResponse = {
  id: number;
  isDeleted: boolean;
  task: AiCoreTask;
  coreName: string;
  modelId: string;
  description: string;
}

export type AiCoreTask = {
  name: TaskViewType;
  code: TaskType;
}

export type AiCoreVersionParams = {
  aiCoreId: number;
  id?: number; //version id
  version: string;
  releaseNote: string;
  updatedBy?: number;
}

export type AiCoreVersionResponse = {
  id: number;
  version: string;
  releaseNote: string;
}

export type AiCoreListParams = {
  page?: number | null;
  size?: number | null;
  sort?: string | null; //'updatedDate' | 'createdDate' | 'version' | 'model'
  direction?: OrderType;
  task: TaskType[];
  status: StatusType[];
  searchKeyword?: string;
}

export type AiCoreListResponse = CommonContentType<AiCoreContent> & {
  
}

type AiCoreContent = {
  id: number;
  isDeleted: boolean;
  task: string;
  modelName: string;
  version: string;
  updatedDate: string;
  createdDate: string;
}

export type AiCoreRegistParams = {
  task: string;
  modelName: string;
  modelId: string;
  description: string;
  updatedBy?: number;
}

export type AiCoreVersion = {
  id: number;
  version: string;
  releaseNote: string;
  isDeleted: boolean;
}

export const STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
} as const;

export type StatusType = (typeof STATUS_TYPE)[keyof typeof STATUS_TYPE];

export const STATUS_VIEW_TYPE = {
  ACTIVE: 'Active',
  DELETED: 'Deleted',
} as const;

export type StatusViewType = (typeof STATUS_VIEW_TYPE)[keyof typeof STATUS_VIEW_TYPE];