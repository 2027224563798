import Title from "components/Title/Title";
import styles from "./TitleContainer.module.scss";
import { TitleContainerProps } from "./TitleContainer.type";

export default function TitleContainer({
  title,
  textType,
  rightComponent
}: TitleContainerProps) {

  return (
    <div className={styles["title-container__container"]}>
      <Title text={title} textType={textType} />
      {rightComponent}
    </div>
  );
};
