import { useAxios } from 'api/useAxios';
import { AxiosResponse } from 'axios';
import { EndPoint } from 'data/endPoint';
import { AiCoreTask } from 'types/feature/aiCore/aiCore';
import { CompanyListResponse, ProjectContent, ProjectListParams, ProjectListResponse, ProjectMemberResponse, ProjectRegistResponse, ProjectRequestCodeResponse, ProjectRequestDetailResponse, ProjectRequestListParams, ProjectRequestListResponse, ProjectRequestModelsResponse, ProjectRequestParams, ProjectRequestResponse } from 'types/feature/project/project';
import { setArrayParam } from 'utils/common-utils';


const COMPANY_KEY = 'DABEEO-COMPANY-ID';
export const useProject = () => {
  const { api, mediaApi } = useAxios();

  //Project 목록 조회
  const getProjectList = async ({ companyIds, limitStartPeriod, limitEndPeriod, page, size, direction, sort, searchKeyword }: ProjectListParams) => {
    const result = await api.get<ProjectListResponse>(`${EndPoint.projects}?${setArrayParam(COMPANY_KEY, companyIds)}&sort=${sort}&direction=${direction}&searchKeyword=${searchKeyword}&limitStartPeriod=${limitStartPeriod}&limitEndPeriod=${limitEndPeriod}`);
    return result;
  }

  //Project 등록
  const postProject = async (projectForm: FormData) => {
    const result = await mediaApi.post<AxiosResponse<ProjectRegistResponse>>(`${EndPoint.projects}`, projectForm);
    return result;
  }

  //Project 수정
  const updateProject = async (projectId: number, projectForm: FormData) => {
    const result = await mediaApi.patch<ProjectRegistResponse>(`${EndPoint.project.replace(':projectId', String(projectId))}`, projectForm);
    return result;
  }

  //Project 삭제
  const deleteProject = async (projectId: number) => {
    const result = await api.delete(`${EndPoint.project.replace(':projectId', String(projectId))}`);
    return result;
  }

  //Project 상세 조회
  const getProjectDetail = async (projectId: number) => {
    const result = await api.get<ProjectContent>(`${EndPoint.project.replace(':projectId', String(projectId))}`);
    return result;
  }

  //Company 목록 조회
  const getCompanyList = async () => {
    const result = await api.get<CompanyListResponse[]>(`${EndPoint.projectCompanies}`);
    return result;
  }

  //Request 리스트 조회
  const getProjectRequestList = async (projectId: number, { sort, direction, status = '', task = '', searchKeyword = '', searchStartDate = '', searchEndDate = '' }: ProjectRequestListParams) => {
    const result = await api.get<ProjectRequestListResponse[]>(`${EndPoint.requestProject.replace(':projectId', String(projectId))}?sort=${sort}&direction=${direction}&status=${status}&task=${task}&searchKeyword=${searchKeyword}&searchStartDate=${searchStartDate}&searchEndDate=${searchEndDate}`);
    return result;
  }

  //Request 등록
  const postProjectRequest = async (projectId: number, { name, aiCoreVersionId, imageDataList, }: ProjectRequestParams) => {
    const result = await api.post<ProjectRequestResponse>(`${EndPoint.requestProject.replace(':projectId', String(projectId))}`, { name, aiCoreVersionId, imageDataList });
    return result;
  }

  //Request 조회
  const getProjectRequest = async (projectId: number, requestId: number) => {
    const result = await api.get<ProjectRequestDetailResponse>(`${EndPoint.request.replace(':projectId', String(projectId)).replace(':requestId', String(requestId))}`);
    return result;
  }

  //Request Image Type 리스트 조회
  const getProjectRequestImageType = async () => {
    const result = await api.get<ProjectRequestCodeResponse[]>(`${EndPoint.requestImageType}`);
    return result;
  }

  //Request Image Classification 리스트 조회
  const getProjectRequestImageClassification = async () => {
    const result = await api.get<ProjectRequestCodeResponse[]>(`${EndPoint.requestImageType}`);
    return result;
  }

  //Request Task 리스트 조회
  const getProjectRequestCore = async () => {
    const result = await api.get<AiCoreTask[]>(`${EndPoint.requestCoreTask}`);
    return result;
  }

  //Request Task Model 리스트 조회
  const getProjectRequestModels = async (task: string) => {
    const result = await api.get<ProjectRequestModelsResponse[]>(`${EndPoint.requestCoreList}?task=${task}`);
    return result;
  }

  //Member 검색 조회
  const getProjectMembers = async (companyId: number, searchKeyword: string) => {
    const result = await api.get<ProjectMemberResponse[]>(`${EndPoint.projectMembers.replace(':companyId', String(companyId))}?searchKeyword=${searchKeyword}`);
    return result;
  }

  return {
    getProjectList,
    postProject,
    updateProject,
    deleteProject,
    getProjectDetail,
    getCompanyList,
    getProjectRequestList,
    postProjectRequest,
    getProjectRequest,
    getProjectRequestImageType,
    getProjectRequestImageClassification,
    getProjectRequestCore,
    getProjectRequestModels,
    getProjectMembers
  }
}