import axios, { AxiosError, HttpStatusCode } from "axios";
import { HTTP_HEADERS } from "constants/auth";
import { EndPoint } from "data/endPoint";
import { RoutePath } from "data/router/paths";
import { authStore } from "stores/auth-stores";
import { LoginInfo } from "types/feature/login/login";
import { extractUserRolesAtToken } from "utils/auth/authorization-utils";
import { logoutLocalStorage } from "utils/auth/localStorage-utils";

const useHandleError = () => {
  const { setAuthenticationInfo } = authStore();


  const handleResponseError = (error: AxiosError) => {
    if (!error.response) return;
    const errorResponse = error.response;
    if (errorResponse.status === HttpStatusCode.Unauthorized) {
      // 토큰 만료 (401 INVALID_CREDENTIALS)
      return handleInvalidError(error);
    }
  
    return Promise.reject(error);
  };
  
  const handleInvalidError = async (error: AxiosError) => {
    try {
      const { data, headers } = await axios.post<LoginInfo>(`${process.env.REACT_APP_BASE_URL}${EndPoint.refresh}`, {}, { withCredentials: true });
      storeAuthenticationInfo(data, {
        memberId: headers[HTTP_HEADERS.DABEEO_MEMBER_ID],
        companyId: headers[HTTP_HEADERS.DABEEO_COMPANY_ID],
        companyUrl: headers[HTTP_HEADERS.DABEEO_COMPANY_URL]
      });
      const originalRequest = error.config;
      if (!originalRequest) return;
      originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`;
      return axios(originalRequest);
    } catch (e) {
        logoutLocalStorage();        
        if (window.location.pathname.indexOf(RoutePath.login) > -1)  return;
        window.location.href = RoutePath.login;
    }
  };

  const handleOtherErrors = (error: AxiosError) => {
    if (!error.response) return;

    const errorResponse = error.response;

    if(errorResponse.status === 400) {
      //Bad Request
    }
  }

  const storeAuthenticationInfo = (loginInfo: LoginInfo, { memberId, companyId, companyUrl }: { memberId: number, companyId: number, companyUrl: string }) => {
    setAuthenticationInfo({
      accessToken: loginInfo.access_token,
      userRoles: extractUserRolesAtToken(loginInfo.access_token),
      memberId,
      companyId,
      companyUrl,
    });
  }

  return { handleResponseError };
}

export default useHandleError;