
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { PERIOD_TYPE, PeriodType } from "types/feature/project/project";
import { create } from "zustand";

type ProjectStoreState = {
  projectName: string;
  projectCompanyId: number | undefined;
  projectCompany: CommonOptionType | undefined;
  fileName: string;
  fileURL: string;
  periodValue: PeriodType;
  periodDateValue: (string | null)[];
  periodStartDate: (Date | undefined);
  periodEndDate: (Date | undefined);
  editImageDataIds: number[];
  imageDataIds: number[];
  projectId: number | undefined;

  selectedTask: CommonOptionType | undefined;
  selectedModel: CommonOptionType | undefined;
  selectedVersion: CommonOptionType | undefined
}

type ProjectStoreActions = {
  setProjectName: (projectName: string) => void;
  setProjectCompanyId: (companyId: number) => void;
  setProjectCompany: (projectCompany: CommonOptionType | undefined) => void;
  setFileName: (fileName: string) => void;
  setFileURL: (fileURL: string) => void;
  setPeriodValue: (periodValue: PeriodType) => void;
  setPeriodDateValue: (periodDateValue: (string | null)[]) => void;
  setPeriodStartDate: (periodStartDate: (Date | undefined)) => void;
  setPeriodEndDate: (periodEndDate: (Date | undefined)) => void;
  setEditImageDataIds: (editImageDataIds: number[]) => void;
  setImageDataIds: (imageDataIds: number[]) => void;
  setProjectId: (projectId: number) => void;
  getProjectCompany: () => CommonOptionType | undefined;

  setSelectedTask: (selectedTask: CommonOptionType | undefined) => void;
  setSelectedModel: (selectedModel: CommonOptionType | undefined) => void;
  setSelectedVersion: (selectedVersion: CommonOptionType | undefined) => void; 
  reset: () => void;
};

const initialState: ProjectStoreState = {
  projectName: '',
  projectCompanyId: undefined,
  projectCompany: undefined,
  fileName: '',
  fileURL: '',
  periodValue: PERIOD_TYPE.unlimit,
  periodDateValue: [],
  periodStartDate: undefined,
  periodEndDate: undefined,
  editImageDataIds: [],
  imageDataIds: [],
  projectId: undefined,

  selectedTask: undefined,
  selectedModel: undefined,
  selectedVersion: undefined
}

export const projectStore = create<ProjectStoreState & ProjectStoreActions>((set, get) => ({
  projectName: initialState.projectName,
  setProjectName: (projectName) => set({ projectName }),
  projectCompanyId: initialState.projectCompanyId,
  setProjectCompanyId: (projectCompanyId) => set({ projectCompanyId }),
  projectCompany: initialState.projectCompany,
  setProjectCompany: (projectCompany) => set({ projectCompany }),
  fileName: initialState.fileName,
  setFileName: (fileName) => set({ fileName }),
  fileURL: initialState.fileURL,
  setFileURL: (fileURL) => set({ fileURL }),
  periodValue: initialState.periodValue,
  setPeriodValue: (periodValue) => set({ periodValue }),
  periodDateValue: initialState.periodDateValue,
  setPeriodDateValue: (periodDateValue) => set({ periodDateValue }),
  periodStartDate: initialState.periodStartDate,
  setPeriodStartDate: (periodStartDate) => set({ periodStartDate }),
  periodEndDate: initialState.periodEndDate,
  setPeriodEndDate: (periodEndDate) => set({ periodEndDate }),
  editImageDataIds: initialState.editImageDataIds,
  setEditImageDataIds: (editImageDataIds) => set({ editImageDataIds }),
  imageDataIds: initialState.imageDataIds,
  setImageDataIds: (imageDataIds) => set({ imageDataIds }),
  projectId: initialState.projectId,
  setProjectId: (projectId) => set({ projectId }),
  getProjectCompany: () => get().projectCompany,
  
  selectedTask: initialState.selectedTask,
  setSelectedTask: (selectedTask) => set( {selectedTask}),
  selectedModel: initialState.selectedModel,
  setSelectedModel: (selectedModel) => set({ selectedModel }),
  selectedVersion: initialState.selectedVersion,
  setSelectedVersion: (selectedVersion) => set({ selectedVersion }),
  reset: () => {
    set(initialState)
  }
}));
