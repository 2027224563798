import classNames from 'classnames';
import { ICON } from 'constants/icons';
import { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from "./SelectBox.module.scss";
import { CommonOptionType, SelectBoxProps } from './SelectBox.type';

export default function SelectBox({
  selected,
  setSelected,
  disabled,
  options,
  onChangeSelect,
  width = 120,
  direction = 'DOWN',
  placeholder,
  selectBoxStyle
}: SelectBoxProps) {
  const [openSelectBox, setOpenSelectBox] = useState(false);

  const toggleSelectBox = () => {
    if (disabled) {
      return;
    }

    setOpenSelectBox(!openSelectBox);
  };

  const handleClickOption = async (option: CommonOptionType) => {
    if (option.disabled) return;
    toggleSelectBox();
    setSelected && setSelected(option);
    onChangeSelect?.call(null, option);
  };

  const handleOutsideClick = () => {
    if (disabled) {
      return;
    }

    openSelectBox && setOpenSelectBox(false);
  }

  useEffect(() => {
  }, [selected])

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <div className={classNames(styles["select__container"], styles[`${selectBoxStyle}`])} style={{ width: width }}>
        <button
          disabled={disabled}
          onClick={toggleSelectBox}
          className={styles["select__button"]}
        >
          {selected ?
            <>
              {selected?.iconUrl && <img src={selected.iconUrl ?? ''} alt='' />}
              <span>{selected?.label}</span>
            </>
            :
            <>
              <span className={styles["-placeholder"]}>{placeholder ? placeholder : ""}</span>
            </>
          }

          {disabled ? (
            <img src={ICON.ICON_ARROW} alt='disable-arrow' />
          ) : (
            <img src={openSelectBox ? ICON.ICON_ARROW_ACTIVE_UP : ICON.ICON_ARROW_ACTIVE_DOWN} alt='arrow' />
          )}
        </button>

        {openSelectBox && (
          <div className={styles["-select-list"]}>
            <ul>
              {options && options.map((option, i) => (
                <li
                  key={i}
                  id={option.label}
                  onClick={() => handleClickOption(option)}
                  value={option.value}
                  className={classNames({ [styles["disabled"]]: option.disabled })}
                >
                  <div className={styles["-select-item"]}>
                    {option.iconUrl && <img src={option.iconUrl} alt='' />}
                    <span className={classNames({ [styles["-bold"]]: selected?.value === option.value })}>{option.label}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>

        )}
      </div>
    </OutsideClickHandler>

  );
};

