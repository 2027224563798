import classNames from 'classnames';
import Header from 'components/Header/Header';
import Loading from 'components/Loading/Loading';
import Sidebar from 'components/Sidebar/Sidebar';
import { TOKEN_ROLES } from 'constants/auth';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKey';
import ScrollToTop from 'hooks/common/useScrollTop';
import { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { compStore } from 'stores/comp-stores';
import { getLocalStorage } from 'utils/auth/localStorage-utils';
import styles from './Layout.module.scss';
import { LayoutProps } from './Layout.type';
import { authStore } from 'stores/auth-stores';

export default function Layout({ layoutType }: LayoutProps) {
  const { setContentWrap } = compStore();
  const wrapRef = useRef<HTMLDivElement>(null);
  const { userRoles } = authStore();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsAdmin(userRoles.includes(TOKEN_ROLES.PROJECT_ADMIN || TOKEN_ROLES.SUPER_ADMIN));
  }, [userRoles])

  useEffect(() => {
    if (wrapRef.current)
      setContentWrap(wrapRef.current);
  }, [wrapRef.current]);

  return (
    <>
      {isAdmin ?
        <div className={styles["layout__container"]}>
          <ScrollToTop />
          <Header />
          <div className={styles["body__wrap"]}>
            <Sidebar />
            <div className={classNames({ [styles["content_list__container"]]: layoutType === 'list' }, { [styles["content_detail__container"]]: layoutType === 'detail' })} ref={wrapRef}>
              <div className={styles["content__wrap"]}>
                <Outlet></Outlet>
              </div>
            </div>
          </div>
          <Loading></Loading>
        </div> : <></>
      }
    </>
  );
};

