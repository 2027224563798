
export type TitleProps = {
  text: string;
  textType: TextType;
  color?: string;
};

export enum TextType {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
}