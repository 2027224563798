import classNames from "classnames";
import { useState } from "react";
import styles from "./TextArea.module.scss";
import { TextAreaProps } from "./TextArea.type";

export default function TextArea({
  value,
  defaultValue,
  onChange,
  name,
  disabled = false,
  placeholder,
  width,
  height = 98,
  maxLength,
}: TextAreaProps) {

  const subStringMaxLength = () => {
    if (!textLength || !maxLength) return;
    if (textLength > maxLength) {
      value?.substring(0, maxLength);
    }
  }

  const [textValue, setTextValue] = useState(value);
  const [textLength, setTextLength] = useState(value?.length);

  const handleChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextLength(e.target.value?.length);
    setTextValue(e.target.value);
    onChange && onChange(e);
  }

  return (
    <label className={classNames(styles["textarea__container"])}
      style={{ width: width, height: height }}>
      <div className={styles["textarea__area"]}>
        <textarea
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          onChange={(e) => { subStringMaxLength(); handleChangeText(e) }}
          defaultValue={defaultValue}
          value={textValue}
          maxLength={maxLength}
        />

        {maxLength && (
          <div className={styles["length__container"]}>
            <span className={classNames({ [styles["-bold"]]: Number(textLength) > 0, [styles["-empty"]]: !value || Number(textLength) === 0 })}>
              {(textLength && textLength.toLocaleString()) || 0}
            </span>
            <span className={classNames({ [styles["-normal"]]: Number(textLength) > 0, [styles["-empty"]]: !value || Number(textLength) === 0 })}>/</span>
            <span className={classNames({ [styles["-normal"]]: Number(textLength) > 0, [styles["-empty"]]: !value || Number(textLength) === 0 })}>{(maxLength && maxLength.toLocaleString()) || 0}</span>
          </div>
        )}
      </div>
    </label>
  )
}