import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ContentContainer from "components/Container/Content/ContentContainer/ContentContainer";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import BlockTitleContainer from "components/Container/Title/BlockTitleContainer/BlockTitleContainer";
import RequestInformation from "components/Information/Request/RequestInformation";
import RequestImageDataSet from "components/RequestDetail/ImageDataSet/RequestImageDataSet";
import RequestProgress from "components/RequestDetail/Progress/RequestProgress";
import { TextType } from "components/Title/Title.type";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useProject } from "hooks/feature/project/useProject";
import { useEffect, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { projectStore } from "stores/project-stores";
import { AiCoreTask } from "types/feature/aiCore/aiCore";
import { ProjectRequestParams, TASK_TYPE } from "types/feature/project/project";
import styles from "./ProjectRequestDetail.module.scss";

export default function ProjectRequestDetail() {
  const { requestImageDataSet, idfPathList } = imageDataSetStore();
  const { selectedVersion, selectedTask, projectId } = projectStore();
  const { navigateToReplacePath, navigateToBack } = useCustomNavigate();
  const { postProjectRequest } = useProject();

  const [requestName, setRequestName] = useState('');
  const [isErrorRequestName, setIsErrorRequestName] = useState(false);
  const [isRequestDisabled, setIsRequestDisabled] = useState(true);


  useEffect(() => {
    const imageCount = getSelectedImage().map(i => { return i.information_original_file }).flat().length;
    setIsRequestDisabled(!requestName || isErrorRequestName || !selectedVersion || requestImageDataSet.length === 0 || (((selectedTask?.value) as AiCoreTask).code === TASK_TYPE.CHANGE_DETECTION && imageCount !== 2));
  }, [requestName, isErrorRequestName, selectedVersion, requestImageDataSet, selectedTask]);


  const handleRequestClick = async () => {
    const result = await fetchPostRequest();
    if (result) {
      navigateToReplacePath(`${RoutePath.projectDetail}?${URL_QUERY.PROJECT_ID}=${projectId}`);
    }
  }

  const handleCancelClick = () => {
    navigateToReplacePath(`${RoutePath.projectDetail}?${URL_QUERY.PROJECT_ID}=${projectId}`);
  }

  const fetchPostRequest = async () => {
    if (!selectedVersion || !projectId) return;

    const param: ProjectRequestParams = {
      name: requestName,
      aiCoreVersionId: [Number(selectedVersion.value.id)],
      imageDataList: getSelectedImage()
    };

    const result = await postProjectRequest(projectId, param);
    return result;
  }

  const getSelectedImage = () => {
    const imageSetDataIdxs = requestImageDataSet.map(item => item.pathIdx);

    const targetList = idfPathList.filter(item => imageSetDataIdxs.includes(item.idx));
    const newDataList = targetList.map(item => {
      const filteredFiles = item.information_original_file?.filter(
        file => requestImageDataSet.find(data => data.pathIdx === item.idx && data.images.some(img => img.idx === file.idx))
      );
      return {
        ...item,
        information_original_file: filteredFiles
      };
    })

    return newDataList;
  }

  return (
    <>
      <BlockTitleContainer title="Request" textType={TextType.h2} rightComponent={
        <div className={styles["block_title_button__wrap"]}>
          <Button color={BUTTON_COLOR.white} size={BUTTON_SIZE[80]} text="Cancel" onClick={handleCancelClick}></Button>
          <Button color={BUTTON_COLOR.blue} size={BUTTON_SIZE[80]} text="Request" disabled={isRequestDisabled} onClick={handleRequestClick}></Button>
        </div>
      } backButton onClickBackButton={navigateToBack}></BlockTitleContainer>
      <ContentContainer>
        <ContentWrap>
          <RequestInformation requestName={requestName} setRequestName={setRequestName} isErrorRequestName={isErrorRequestName} setIsErrorRequestName={setIsErrorRequestName}></RequestInformation>
          <RequestProgress></RequestProgress>
          <RequestImageDataSet></RequestImageDataSet>
        </ContentWrap>
      </ContentContainer>
    </>
  )
}