import Authorization from "components/Authorization/Authorization";
import Layout from "components/Layout/Layout";
import LoginRoutes from "components/Layout/Routes/LoginRoutes";
import AccountPassword from "pages/Account/Password/AccountPassword";
import AICore from "pages/AICore/AICore";
import AICoreDetail from "pages/AICore/Detail/AICoreDetail";
import AOI from "pages/AOI/AOI";
import Company from "pages/Company/Company";
import CompanyDetail from "pages/Company/Detail/CompanyDetail";
import Home from "pages/Home/Home";
import ImageData from "pages/ImageData/ImageData";
import Login from "pages/Login/Login";
import ProjectDetail from "pages/Project/Detail/ProjectDetail";
import Project from "pages/Project/Project";
import ProjectRequestDetail from "pages/Project/RequestDetail/ProjectRequestDetail";
import Setting from "pages/Setting/Setting";
import UiKitComponent from "pages/UiKitComponent/UiKitComponent";
import { createBrowserRouter } from "react-router-dom";
import { RoutePath } from "./paths";

export const router = createBrowserRouter([
  {
    element: <Authorization />,
    children: [
      {
        element: <Layout layoutType="list" />,
        children: [
          // { path: RoutePath.enterprise, element: <Enterprise /> },
          { path: RoutePath.company, element: <Company /> },
          { path: RoutePath.aicore, element: <AICore /> },
          { path: RoutePath.imagedata, element: <ImageData /> },
          { path: RoutePath.project, element: <Project /> },
          { path: RoutePath.aoi, element: <AOI /> },
          { path: RoutePath.setting, element: <Setting /> },
        ],
      },
      {
        element: <Layout layoutType="detail" />,
        children: [
          { path: RoutePath.aicoreDetail, element: <AICoreDetail /> },
          { path: RoutePath.aicoreDetailRegist, element: <AICoreDetail /> },
          { path: RoutePath.projectDetailRegist, element: <ProjectDetail /> },
          { path: RoutePath.projectDetail, element: <ProjectDetail /> },
          { path: RoutePath.projectRequestDetail, element: <ProjectRequestDetail />, },
          { path: RoutePath.companyDetail, element: <CompanyDetail /> },
          { path: RoutePath.companyDetailRegist, element: <CompanyDetail /> },
          // { path: RoutePath.enterpriseDetail, element: <EnterpriseDetail /> },
          // { path: RoutePath.administratorSetting, element: <AdminSetting /> },
        ],
      },
      { path: RoutePath.uikit, element: <UiKitComponent /> },
      { path: RoutePath.accountPassword, element: <AccountPassword /> },
    ],
  },
  {
    element: <Authorization isRedirectToProject />,
    children: [
      { path: RoutePath.home, element: <Home /> },
      {
        element: <LoginRoutes />,
        children: [{ path: RoutePath.login, element: <Login /> }],
      },
    ],
  },
]);
