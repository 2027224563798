import styles from "./InformationControl.module.scss";
import { InformationControlProps } from "./InformationControl.type";

export default function InformationControl({ name, value, control }: InformationControlProps) {
  return (
    <div className={styles["info-control__wrap"]}>
      <div className={styles["-name"]}>{name}</div>
      {value ?
        <div className={styles["-value"]}>{value}</div> : <>{control}</>
      }
    </div>
  );
}
