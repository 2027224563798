import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import Alert from "components/Popup/Alert/Alert";
import { TextType } from "components/Title/Title.type";
import Tooltip from "components/Tooltip/Tooltip";
import { ICON } from "constants/icons";
import { useMember } from "hooks/feature/member/useMember";
import { ReactElement, useEffect, useState } from "react";
import { commonStore } from "stores/common-stores";
import styles from "styles/module/editrow.module.scss";
import { MemberParams, UpdateMemberParams } from "types/feature/member/member";
import MemberRow from "./EditRow/MemberRow";
import { MemberType } from "./EditRow/MemberRow.type";
import { MemberProps } from "./Member.type";

export default function Member({ targetId, type }: MemberProps) {
  const { getCompanyMembers, postCompanyMember, deleteCompanyMember, updateCompanyMember, resetMemberPassword, getEnterpriseMembers, postEnterpriseMember, deleteEnterpriseMember, updateEnterpriseMember } = useMember();
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<ReactElement>(<></>);
  const [deleteTargetId, setDeleteTargetId] = useState<number | undefined>();
  const [memberData, setMemberData] = useState<MemberType[]>([]);
  const [showTooltipID, setShowTooltipID] = useState(false);
  const [showTooltipEmail, setShowTooltipEmail] = useState(false);

  const { setIsLoading } = commonStore();

  const [columns, setColumns] = useState<Column[]>([
    { header: 'No', field: 'no', align: ALIGN_TYPE.center, required: false },
    { header: 'User name', field: 'nickname', align: ALIGN_TYPE.left, required: true },
    { header: 'User ID', field: 'username', align: ALIGN_TYPE.left, required: true, isTooltip: true, tooltipText: `• ID must use 5~15 characters consisting of small letter, number and special charaters.\n• Inputable Special Characters: -, _, .\n• Unable to enter spaces.` },
    { header: 'E-mail', field: 'email', align: ALIGN_TYPE.left, required: true, isTooltip: true, tooltipText: `• Follow the @Email format.\n• Unable to enter spaces` },
    { header: '', field: 'buttons', align: ALIGN_TYPE.left, required: false },
  ]);

  // const columns: Column[] = [
  //   { header: 'No', field: 'no', align: ALIGN_TYPE.center, required: false },
  //   { header: 'User name', field: 'nickname', align: ALIGN_TYPE.left, required: true },
  //   { header: 'User ID', field: 'username', align: ALIGN_TYPE.left, required: true, isTooltip: true, tooltipText: `• ID must use 5~15 characters consisting of small letter, number and special charaters.\n• Inputable Special Characters: -, _, .\n• Unable to enter spaces.` },
  //   { header: 'E-mail', field: 'email', align: ALIGN_TYPE.left, required: true, isTooltip: true, tooltipText: `• Follow the @Email format.\n• Unable to enter spaces` },
  //   { header: '', field: 'buttons', align: ALIGN_TYPE.left, required: false },
  // ];

  useEffect(() => {
    if (!targetId) return;
    fetchMemberList();
  }, [targetId]);

  useEffect(() => {
    if (type !== 'admin') return;
    const updatedColumns = [...columns];
    updatedColumns.splice(1, 0, { header: 'Grade', field: 'grade', align: ALIGN_TYPE.center, required: false });
    setColumns(updatedColumns);

  }, [type])

  const fetchMemberList = async () => {
    if (!targetId || type !== "company") return;
    const memberList: MemberType[] = [];
    // const result = isCompany ? await getCompanyMembers(targetId) : await getEnterpriseMembers(targetId);
    const result = await getCompanyMembers(targetId);
    result.forEach((member, i) => {
      const item: MemberType = {
        no: i + 1,
        id: member.id,
        userName: member.nickname,
        userID: member.username,
        email: member.email,
        editMode: false,
        isSaved: true
      }
      memberList.push(item);
    });
    setMemberData(memberList);
  }

  const handleClickAddMember = () => {
    const newID = memberData.length > 0 ? memberData[memberData.length - 1].no + 1 : 1;
    const newMember: MemberType = {
      no: newID,
      editMode: true,
      isSaved: false
    }
    let updatedMemberData = [...memberData, newMember];
    setMemberData(updatedMemberData);
  }

  const handleSaveMember = async (nickname: string, username: string, email: string) => {
    if (!targetId) return;
    const param: MemberParams = {
      companyId: targetId,
      enterpriseId: targetId,
      nickname: nickname,
      username: username,
      email: email
    };

    const result = type === "company" ? await postCompanyMember(param) : await postEnterpriseMember(param);
    if (result) {
      await fetchMemberList();
    }
  }

  const fetchDeleteMember = async () => {
    if (!targetId) return;
    const memberId = deleteTargetId !== undefined ? deleteTargetId : targetId;
    if (memberId === undefined) {
      setIsShowConfirm(false);
      return;
    }

    type === "company" ? await deleteCompanyMember(targetId, memberId) : deleteEnterpriseMember(targetId, memberId);
    await fetchMemberList();
    setIsShowConfirm(false);
    setDeleteTargetId(undefined);
  }

  const handleClickDeleteMember = (isSaved: boolean, id?: number, index?: number) => {
    if (id) {
      setDeleteTargetId(id);
      if (isSaved) {
        setIsShowConfirm(true);
      }
    } else if (index !== undefined) {
      let updatedMemberData = [...memberData];
      updatedMemberData.splice(index, 1);
      setMemberData(updatedMemberData);
      setIsShowConfirm(false);
      setDeleteTargetId(undefined);
    }

  }

  const handleUpdateMember = async (member: MemberType) => {
    if (!member.id || !member.userID || !member.email || !member.userName || !targetId) return;
    const updatedMember: UpdateMemberParams = {
      id: member.id,
      companyId: targetId,
      enterpriseId: targetId,
      nickname: member.userName,
      email: member.email,
    }

    const result = type === "company" ? await updateCompanyMember(member.id, updatedMember) : await updateEnterpriseMember(member.id, updatedMember);
    if (result) {
      fetchMemberList();
    }
  }

  const handleResetPassword = async (memberId?: number) => {
    if (!memberId || !targetId) return;
    setIsLoading(true);
    const result = await resetMemberPassword(targetId, memberId);
    setIsLoading(false);
    showAlert(result ? <span>User password has been reset.<br />Please check user e-mail</span> : <span>Internal server error</span>)
  }

  const showAlert = (message: ReactElement) => {
    setAlertMessage(message);
    setIsShowAlert(true);
  }

  const initTooltipArea = (field: 'username' | 'email' | string, tooltipText: string) => {
    switch (field) {
      case 'username':
        return (
          <>
            <img src={ICON.ICON_INFO} alt="" onMouseEnter={() => setShowTooltipID(true)} onMouseLeave={() => setShowTooltipID(false)} />
            {showTooltipID &&
              <div className={styles["tooltip__area"]}>
                <Tooltip text={tooltipText} arrowAlign="top" textAlign="left"></Tooltip>
              </div>
            }
          </>
        )
      case 'email':
        return (
          <>
            <img src={ICON.ICON_INFO} alt="" onMouseEnter={() => setShowTooltipEmail(true)} onMouseLeave={() => setShowTooltipEmail(false)} />
            {showTooltipEmail &&
              <div className={styles["tooltip__area"]}>
                <Tooltip text={tooltipText} arrowAlign="top" textAlign="left"></Tooltip>
              </div>
            }
          </>
        )
    }
  }

  return (
    <>
      <ContentWrap>
        <SubTitleContainer
          title="Member"
          length={memberData.filter(i => i.isSaved).length}
          textType={TextType.h3}
          iconPath={ICON.ICON_MEMBER}
          rightComponent={
            <div className={styles["btn__area"]}>
              <Button
                color={BUTTON_COLOR.black}
                text="Add Member"
                size={"size80"}
                iconPath={ICON.ICON_PLUS}
                width={112}
                onClick={handleClickAddMember}
              ></Button>
            </div>
          }
        ></SubTitleContainer>
        <ContentBox>
          <table className={styles["table-company-detail"]}>
            <colgroup>
              {type === 'admin' &&
                <>
                  <col width="6%"></col>
                  <col width="15%"></col>
                  <col width="15%"></col>
                  <col width="15%"></col>
                  <col width="41%"></col>
                  <col width="8%"></col>
                </>
              }
              {type !== 'admin' &&
                <>
                  <col width="6%"></col>
                  <col width="18%"></col>
                  <col width="18%"></col>
                  <col width="50%"></col>
                  <col width="8%"></col>
                </>
              }

            </colgroup>
            <thead>
              <tr className={styles["list-row-tr"]}>
                {columns.map((column, index) => (
                  <th
                    key={index}
                    style={column.align && { textAlign: column.align }}
                  >

                    <div className={styles["tr__wrap"]}>
                      {column.isTooltip && (
                        <div className={styles["info__wrap"]}>
                          {initTooltipArea(column.field, String(column.tooltipText))}
                        </div>
                      )}
                      {column.header}
                      {column.required && (
                        <span className={styles["-required"]}> *</span>
                      )}

                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {memberData.length === 0 && (
                <tr className={styles["-no-data"]}>
                  <td colSpan={columns.length}>
                    <div className={styles["-no-data-div"]}>
                      No data available
                    </div>
                  </td>
                </tr>
              )}
              {memberData.map((data, i) => (
                <MemberRow
                  key={i}
                  index={i + 1}
                  member={data}
                  onSave={handleSaveMember}
                  onDelete={() =>
                    handleClickDeleteMember(
                      data.isSaved ? data.isSaved : false,
                      data.id,
                      i
                    )
                  }
                  onUpdate={handleUpdateMember}
                  onResetPassword={handleResetPassword}
                  setDeleteTargetId={setDeleteTargetId}
                  type={type}
                ></MemberRow>
              ))}
            </tbody>
          </table>
        </ContentBox>
        <div className={styles["split-line"]}></div>
      </ContentWrap>
      {isShowAlert &&
        <Alert
          onClosePopup={() => setIsShowAlert(false)}
          firstButton={
            <Button
              color={BUTTON_COLOR.black}
              text="Confirm"
              onClick={() => setIsShowAlert(false)}
              width={120}
            ></Button>
          }
        >{alertMessage}</Alert>
      }
      {isShowConfirm && (
        <Alert
          onClosePopup={() => setIsShowConfirm(false)}
          firstButton={
            <Button
              color={BUTTON_COLOR.white}
              text="Cancel"
              onClick={() => setIsShowConfirm(false)}
              width={120}
            ></Button>
          }
          secondButton={
            <Button
              color={BUTTON_COLOR.black}
              text="Confirm"
              onClick={() => fetchDeleteMember()}
            ></Button>
          }
        >
          <span>
            If you delete, you can’t restore it.
            <br />
            Do you want to proceed?
          </span>
        </Alert>
      )}
    </>
  );
}