import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import ModalContainer from "components/Container/Modal/ModalContainer";
import IconButton from "components/IconButton/IconButton";
import { idfParams } from "constants/auth";
import { ICON } from 'constants/icons';
import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import { useIdf } from "hooks/feature/idf/useIdf";
import { useEffect, useMemo, useState } from "react";
import { imageDataSetStore } from "stores/image-dataset-stores";
import { projectStore } from "stores/project-stores";
import { ImageryInfoParams } from "types/feature/idf/idf";
import { getLocalStorage } from "utils/auth/localStorage-utils";
import Popup from "../Popup";
import styles from '../Popup.module.scss';
import IDFPathItem from "./IDFPathItem/IDFPathItem";
import { IDFPopupProps, ImagePathType } from "./IDFPopup.type";

const IDFPopup = ({ onClosePopup }: IDFPopupProps) => {
  const { getIdfToken, getImageryInfo } = useIdf();
  const { imagePathList, setImagePathList } = imageDataSetStore();
  const { editImageDataIds, setEditImageDataIds } = projectStore();
  const [count, setCount] = useState<number>(0);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    setCount(imagePathList.filter(i => i.checked).length);
  }, [imagePathList]);

  useEffect(() => {
    fetchImageryList();
  }, []);

  const handleSelectClick = () => {
    const idxList: number[] = imagePathList.filter(i => i.checked).map(i => { return i.idx });
    setEditImageDataIds(idxList);
    onClosePopup();
  }

  const fetchIdfToken = async () => {
    await getIdfToken(idfParams);
  }

  const fetchImageryList = async (isReload?: boolean) => {
    let accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    if (!accessKey) {
      await fetchIdfToken();
      accessKey = getLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
    }
    const params: ImageryInfoParams = {
      access_key: accessKey,
      bbox: null,
    }

    const result = await getImageryInfo(params);

    if (!result || !result.data) return;
    const pathList: ImagePathType[] = [];
    result.data.filter(i => i.status === 1 && i.information_original_file && i.information_original_file.length > 0).forEach((data, i) => {
      const path: ImagePathType = {
        idx: data.idx,
        name: data.data_name || '',
        checked: isReload ? false : editImageDataIds.includes(data.idx)
      }
      pathList.push(path);
    });
    setImagePathList(pathList);
    setIsLoad(true);
  }

  const handleReloadClick = async () => {
    setCount(0);
    await fetchImageryList(true);
  }

  const initFileList = useMemo(() => {
    return imagePathList.map((path, i) => {
      return <IDFPathItem path={path} index={i + 1} key={path.idx}></IDFPathItem>
    })
  }, [imagePathList]);

  return (
    <ModalContainer>
      <Popup headerText="IDF image data set" onClosePopup={onClosePopup} bottomButtons={[
        <Button color={BUTTON_COLOR.white} text="Cancel" width={120} onClick={onClosePopup}></Button>,
        <Button color={BUTTON_COLOR.black} text="Select" width={200} onClick={handleSelectClick} disabled={count === 0}></Button>
      ]}>
        <div className={styles["idf-select__body"]}>
          <div className={styles["select-top__area"]}>
            <div className={styles["label__wrap"]}>
              <span>Selected </span>
              <span className={classNames(styles["-count"], { [styles["-blue"]]: count > 0 })}>{count}</span>
            </div>
            <div className={styles["button__wrap"]}>
              <IconButton iconPath={ICON.ICON_RELOAD_BLACK} iconWidth={18} iconHeight={18} buttonStyle="border-black" onClick={handleReloadClick}></IconButton>
            </div>
          </div>
          <div className={styles["select-content__area"]}>
            <div className={classNames(styles["path-list__container"], styles["-pathonly"])}>
              <div className={styles["title__wrap"]}>IDF</div>
              <div className={styles["list__wrap"]}>
                {isLoad && initFileList}
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </ModalContainer>
  );
};

export default IDFPopup;