import { MENUS } from "data/menu/menus";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { authStore } from "stores/auth-stores";
import { checkRole, isAdminstrator } from "utils/auth/authorization-utils";
import { useAuth } from "../login/useAuth";

const excludePaths = [RoutePath.accountPassword];

export const useAuthority = (isRedirectToProject?: boolean) => {
  const { pathname } = useLocation();
  const { navigateToReplacePath } = useCustomNavigate();
  const { userRoles, companyUrl } = authStore();
  const { postRefresh, redirectUser } = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    issueToken();
  }, []);

  useEffect(() => {    
    navigatePages();
  }, [userRoles, companyUrl]);

  useEffect(() => {
    if (userRoles.length === 0) return;
    checkPermissionPath();
  }, [userRoles, pathname]);

  const issueToken = async () => {    
    const result = await postRefresh();    
    setInitialized(true);
    if (typeof result === "undefined") return;
  };

  const navigatePages = () => {
    if (isAdminstrator(userRoles)) {
      isRedirectToProject && navigateToReplacePath(RoutePath.company);
      return;
    }

    if (!companyUrl) return;
    !matchExcludePaths() && redirectUser(companyUrl);
  };

  const matchExcludePaths = () => {
    let isMatched = false;
    for (const excludePath of excludePaths) {
      isMatched = Boolean(matchPath({ path: excludePath }, pathname));
      if (isMatched) break;
    }

    return isMatched;
  };

  const checkPermissionPath = () => {
    const menu = MENUS.find((menu) => pathname.indexOf(menu.path) > -1);
    if (!menu || menu.roles.length === 0) return;
    checkRole({ userRoles, menuRoles: menu.roles }) || navigateToReplacePath(RoutePath.company);
  };

  return {
    initialized,
  };
};
