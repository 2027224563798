import { useAuthority } from "hooks/feature/authority/useAuthority";
import { Outlet } from "react-router-dom";
import { AuthorizationProps } from "./Authorization.type";

export default function Authorization({
  isRedirectToProject,
}: AuthorizationProps) {
  const { initialized } = useAuthority(isRedirectToProject);
  return initialized ? <Outlet></Outlet> : <></>;
}
