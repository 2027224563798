import classNames from 'classnames';
import { useState } from 'react';
import styles from './CheckBox.module.scss';
import { CheckBoxProps } from './CheckBox.type';

export default function Checkbox({ checked, label, disabled = false, onChange, colorMode = 'bright', id, labelWidth, isToopTip, width, height }: CheckBoxProps) {
  const [isShowToolTip, setIsShowToolTip] = useState(false);
  const handleLabelMouseEnter = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    if (!isToopTip || isShowToolTip) return;
    setIsShowToolTip(true);
  }

  const handleLabelMouseLeave = () => {
    setIsShowToolTip(false);
  }

  return (
    <div className={classNames(styles["checkbox__container"], styles[`${colorMode}`])} style={{ width: width, height: height }}>
      <input
        onChange={onChange}
        disabled={disabled}
        value={label || ''}
        id={`checkbox${id}`}
        type='checkbox'
        className={styles["-checkbox"]}
        checked={checked as boolean}
        readOnly={!onChange}
      />
      <label htmlFor={`checkbox${id}`} className={styles["-label"]} style={{ width: labelWidth }}
        onMouseLeave={handleLabelMouseLeave}
        onMouseEnter={(e) => handleLabelMouseEnter(e)}>
        {label}
      </label>
      {isToopTip && isShowToolTip &&
        <div className={styles["-tooltip"]} >{label}</div>
      }
    </div>
  );
};