import styles from "./ListHeader.module.scss";
import { ListHeaderProps } from "./ListHeader.type";

export default function ListHeader({ total }: ListHeaderProps) {
  return (
    <div className={styles["header__container"]}>
      <div className={styles["total__wrap"]}>
        <div className={styles["-total"]}>Total</div>
        <div className={styles["-num"]}>{total}</div>
      </div>
    </div>
  )
}