import { TOKEN_ROLES } from "constants/auth";
import { TokenRoles } from "types/auth/auth";

export function decodeAccessToken(token: string) {
  return JSON.parse(atob(token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/")));
}

export function isAdminstrator(userRoles: TokenRoles[]) {
  return userRoles.includes(TOKEN_ROLES.PROJECT_ADMIN || TOKEN_ROLES.SUPER_ADMIN);
}

export function extractUserRolesAtToken(accessToken: string) {
  if (!accessToken) return [];

  return decodeAccessToken(accessToken)
          .resource_access["dev-client"]
          .roles
}

export function checkRole({
  userRoles,
  menuRoles,
}: {
  userRoles: TokenRoles[];
  menuRoles: TokenRoles[];
}) {
  let isPossible = false;
  if (menuRoles.length === 0) return true;
  for (const role of menuRoles) {
    const included = userRoles.includes(role);
    if (included) {
      isPossible = true;
      break;
    }
  }

  return isPossible;
}
