import classNames from "classnames";
import styles from "./ChipButton.module.scss";
import { ChipButtonProps } from "./ChipButton.type";

export default function ChipButton({
  text,
  textColor = 'white',
  onClick,
  disabled,
  color = 'blue',
  type = 'button',
  buttonStyle,
  iconPath,
  width,
  iconWidth,
  iconHeight,
}: ChipButtonProps) {

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles['chip_button__container'], styles[`${color}`], styles[`-text-${textColor}`], styles[`${buttonStyle}`], { [styles["-icon"]]: iconPath && text })}
      style={{ width: width }}>
      <>
        {text && text}
        {iconPath && <img src={iconPath} alt='' style={{ width: iconWidth ? iconWidth : 'auto', height: iconHeight ? iconHeight : 'auto' }} />}
      </>
    </button>
  )
}