export type ImageDataset = {
  no?: number,
  name?: string,
  type?: string,
  classification?: string,
  resolution?: string,
  size?: string,
  provider?: string,
  status?: string,
  createDate?: string,
  updateDate?: string
}

export const IMAGE_DATASET_IMAGE_TYPE = {
  1: 'Drone',
  2: 'Satellite',
  3: 'Aerial'
}

export const IMAGE_DATASET_IMAGE_STATUS = {
  0: 'Disabled',
  1: 'Activation',
}

export const IMAGE_DATASET_IMAGE_CLASSIFICATION = {
  1: 'Raw',
  2: 'Orthophoto'
}


export const IMAGE_DATASET_TYPE_TYPE = {
  aerial: 'aerial',
  drone: 'drone',
  satellite: 'satellite',
} as const;

export const IMAGE_DATASET_RESOLUTION_TYPE = {
  10: '10',
  20: '20',
  30: '30',
  40: '40'
} as const;

export const IMAGE_DATASET_STATUS_TYPE = {
  disabled: 'disabled',
  activation: 'activation'
} as const;

export const IMAGE_DATASET_CLASSIFICATION_TYPE = {
  raw: 'raw',
  orthophoto: 'orthophoto'
}

/* VIEW CONSTANTS */
export const IMAGE_DATASET_TYPE_VIEW_TYPE = {
  satellite: 'Satellite',
  aerial: 'Aerial',
  drone: 'Drone',
};

export const IMAGE_DATASET_STATUS_VIEW_TYPE = {
  activation: 'Activation',
  disabled: 'Disabled',
};

export const IMAGE_DATASET_CLASSIFICATION_VIEW_TYPE = {
  raw: 'Raw',
  orthophoto: 'Orthophoto'
}

export const IMAGE_DATASET_RESOLUTION_VIEW_TYPE = {
  10: '~ 10cm',
  20: '~ 20cm',
  30: '~ 30cm',
  40: '~ 40cm',
} as const;



// export type ImageDatasetTypeType = (typeof IMAGE_DATASET_TYPE_TYPE)[keyof typeof IMAGE_DATASET_TYPE_TYPE];
// export type ImageDatasetResolutionType = (typeof IMAGE_DATASET_RESOLUTION_TYPE)[keyof typeof IMAGE_DATASET_RESOLUTION_TYPE];
// export type ImageDatasetStatusType = (typeof IMAGE_DATASET_STATUS_TYPE)[keyof typeof IMAGE_DATASET_STATUS_TYPE];
