import classNames from "classnames";
import styles from "./Button.module.scss";
import { ButtonProps } from "./Button.type";

export default function Button({
  loading,
  text,
  onClick,
  size = 'size120',
  disabled,
  color = 'black',
  type = 'button',
  children,
  buttonStyle,
  id,
  iconPath,
  width,
  iconWidth,
  iconHeight,
}: ButtonProps) {

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles['button__container'], styles[`${color}`], styles[`${size}`], styles[`${buttonStyle}`], { [styles["-icon"]]: iconPath && text })}
      style={{ width: width ? width : (!text && iconPath) ? 36 : size === 'size120' ? 120 : size === 'size80' ? 80 : 45, height: (!text && iconPath) ? 36 : size === 'size120' ? 52 : size === 'size80' ? 36 : 24 }}>
      <>
        {iconPath && <img src={iconPath} alt='' style={{ width: iconWidth ? iconWidth : 'auto', height: iconHeight ? iconHeight : 'auto' }} />}
        {text && text}
        {children && children}
      </>
    </button>
  )
}