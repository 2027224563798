import { AxiosError, HttpStatusCode } from "axios";
import Button from "components/Button/Button";
import { BUTTON_COLOR } from "components/Button/Button.type";
import Header from "components/Header/Header";
import Input from "components/Input/Input";
import { ICON } from "constants/icons";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAuth } from "hooks/feature/login/useAuth";
import { useAccountMember } from "hooks/feature/member/useAccountMember";
import { ReactElement, useEffect, useState } from "react";
import { authStore } from "stores/auth-stores";
import { commonStore } from "stores/common-stores";
import { isAdminstrator } from "utils/auth/authorization-utils";
import styles from "./AccountPassword.module.scss";

export default function AccountPassword() {
  const { redirectUser } = useAuth();
  const { setIsLoading } = commonStore();
  const { userRoles, memberId, companyUrl } = authStore();
  const { navigateToReplacePath } = useCustomNavigate();
  const { updatePassword } = useAccountMember();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isActiveButton, setActiveButton] = useState(false);
  const [waring, setWaring] = useState<{
    old: boolean;
    new: boolean;
    confirm: boolean;
  }>({ old: false, new: false, confirm: false });
  const [message, setMessage] = useState<ReactElement>(<></>);

  useEffect(() => {
    if (memberId) return;
    window.location.href = RoutePath.login;
  }, [memberId]);

  useEffect(() => {
    setActiveButton(
      Boolean(oldPassword) && Boolean(newPassword) && Boolean(confirmPassword)
    );
  }, [oldPassword, newPassword, confirmPassword]);

  const validatePasswords = (): boolean => {
    if (oldPassword === newPassword) {
      setWaring({ old: true, new: true, confirm: false });
      setMessage(
        <div>
          You entered the same password
          <br />
          Please enter a new password
        </div>
      );
      return false;
    }

    // if (!isValidPassword(newPassword)) {
    //   setWaring({ old: false, new: true, confirm: false });
    //   setMessage(<div>Please check the password policy</div>);
    //   return false;
    // }

    if (newPassword !== confirmPassword) {
      setWaring({ old: false, new: true, confirm: true });
      setMessage(
        <div>
          Change password and confirmation password are entered differently
        </div>
      );
      return false;
    }

    return true;
  };

  const initializeWaring = () => {
    setWaring({ old: false, new: false, confirm: false });
    setMessage(<></>);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    initializeWaring();
    if (!validatePasswords()) return;

    setIsLoading(true);
    const result = await updatePassword(memberId!, {
      oldPassword,
      newPassword,
      confirmPassword,
    });

    setIsLoading(false);

    if (typeof result === "boolean") {
      isAdminstrator(userRoles)
        ? navigateToReplacePath(RoutePath.company)
        : redirectUser(companyUrl);
    } else {
      const error = result as AxiosError;
      if (error.response?.status === HttpStatusCode.BadRequest) {
        // 비밀번호 형식 틀림
        setWaring({ ...waring, new: true });
        setMessage(<div>Please check the password policy</div>);
        return;
      } else if (error.response?.status === HttpStatusCode.Unauthorized) {
        // 현재 비밀번호 틀림
        setWaring({ ...waring, old: true });
        setMessage(<div>Please check your password</div>);
        return;
      }
    }
  };

  return (
    <div className={styles["account-password__container"]}>
      <Header menusVisible={false}></Header>
      <div className={styles["account-password__body"]}>
        <form className={styles["form__wrap"]} onSubmit={handleSubmit}>
          <div className={styles["title__area"]}>
            <img
              src={ICON.LOGO_EARTHEYE_LOGIN}
              style={{ width: 197, height: 68 }}
              alt=""
            />
            <span className={styles["-title"]}>Change Password</span>
          </div>
          <div className={styles["input__area"]}>
            <Input
              type="password"
              width={360}
              colorMode="dark"
              placeholder="Old password"
              id="oldPassword"
              value={oldPassword}
              showWarning={waring.old}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <Input
              type="password"
              width={360}
              colorMode="dark"
              placeholder="New password"
              id="newPassword"
              value={newPassword}
              showWarning={waring.new}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <Input
              type="password"
              width={360}
              colorMode="dark"
              placeholder="Confirm password"
              id="confirmPassword"
              value={confirmPassword}
              showWarning={waring.confirm}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className={styles["info__area"]}>
              <ul>
                <li>
                  Password must use 8~16 characters consisting of letters,
                  numbers, and special characters
                </li>
                <li>
                  Inputable Special Characters: <br />
                  {"~ ` ! @ # $ % ^ ( ) _ - = { } [ ] | ; : < > , . ? /"}
                </li>
                <li>Unable to enter spaces</li>
              </ul>
            </div>
            <div className={styles["message_area"]}>{message}</div>
            <Button
              color={BUTTON_COLOR.blue}
              width={360}
              text="Change Password"
              type="submit"
              disabled={!isActiveButton}
            />
          </div>
        </form>
      </div>
      <div className={styles["footer__area"]}>
        <img
          className={styles["bottom-logo"]}
          src={ICON.LOGO_DABEEO}
          style={{ width: 80, height: 17.4 }}
          alt=""
        />
      </div>
    </div>
  );
}
