import classNames from "classnames";
import IconButton from "components/IconButton/IconButton";
import Input from "components/Input/Input";
import { ICON } from "constants/icons";
import { MouseEvent, useEffect, useState } from "react";
import styles from "../SelectBox.module.scss";
import { CommonOptionType, SelectBoxProps } from "../SelectBox.type";

export default function EditableSelectBox({
  selected,
  setSelected,
  disabled,
  options,
  setOptions,
  onChangeSelect,
  width = 120,
  direction = 'DOWN',
  placeholder,
  addText = 'Add task',
  maxLength
}: SelectBoxProps) {
  const [openSelectBox, setOpenSelectBox] = useState(false);
  const [isAddOption, setIsAddOption] = useState(false);
  const [addInputValue, setAddInputValue] = useState('');
  const handleAddInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setAddInputValue(word);
  };

  const handleEditInputValue = (e: React.ChangeEvent<HTMLInputElement>, option: CommonOptionType) => {
    if (!options) return;
    const word = e.target.value;
    const updatedOptions = options.map(opt => opt === option ? { ...opt, editValue: word } : opt);
    if (setOptions) {
      setOptions(updatedOptions);
    }
  };

  useEffect(() => {
    if (!openSelectBox)
      closeAddInput();
  }, [openSelectBox])
  const toggleSelectBox = () => {
    if (disabled) {
      return;
    }

    setOpenSelectBox(!openSelectBox);
  };

  const handleClickOption = async (option: CommonOptionType) => {
    toggleSelectBox();
    setSelected && setSelected(option);
  };

  const handleClickEdit = (e: MouseEvent<HTMLButtonElement>, option: CommonOptionType) => {
    if (!options) return;
    e.stopPropagation();
    const updatedOptions = options.map(opt => opt === option ? { ...opt, isEditing: true } : opt);
    if (setOptions) {
      setOptions(updatedOptions);
    }
  }

  const handleEnterEditOption = (e: React.KeyboardEvent<HTMLInputElement>, option: CommonOptionType) => {
    if (!options) return;
    if (e.key === 'Enter') {
      const currentValue = option.editValue as string;
      const updatedOptions = options.map(opt => opt === option ? { ...opt, isEditing: false, label: currentValue, value: currentValue } : opt);
      if (setOptions) {
        setOptions(updatedOptions);
      }
    }
  }

  const handleEnterAddOption = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!options) return;
    if (e.key === 'Enter') {
      const newOption: CommonOptionType = {
        label: addInputValue,
        value: addInputValue,
        editValue: addInputValue
      }
      if (setOptions) {
        setOptions([newOption, ...options]);
        closeAddInput();
      }
    }
    if (e.key === 'Escape') {
      closeAddInput();
    }
  }

  const closeAddInput = () => {
    setAddInputValue('');
    setIsAddOption(false);
  }

  return (
    <div className={styles["select__container"]} style={{ width: width }}>
      <button
        disabled={disabled}
        onClick={toggleSelectBox}
        className={styles["select__button"]}
      >
        {selected ?
          <>
            {selected?.iconUrl && <img src={selected.iconUrl ?? ''} alt='' />}
            <span>{selected?.label}</span>
          </>
          :
          <>
            <span className={classNames(styles["-placeholder"], styles["-editable"])}>{placeholder ? placeholder : ""}</span>
          </>
        }

        {disabled ? (
          <img src={ICON.ICON_ARROW} alt='disable-arrow' />
        ) : (
          <img src={openSelectBox ? ICON.ICON_ARROW_ACTIVE_UP : ICON.ICON_ARROW_ACTIVE_DOWN} alt='arrow' />
        )}
      </button>
      {openSelectBox && (
        <div className={styles["-select-list"]}>
          <ul>
            {isAddOption &&
              <li>
                <div className={styles["-add-option__area"]}>
                  <Input height={24} maxLength={maxLength} placeholder="Please input task name" labelStyle="-editable" value={addInputValue} onChange={handleAddInputValue} onKeyPressDown={handleEnterAddOption}></Input>
                </div>
              </li>
            }
            {options && options.map(option => (
              <li
                key={option.value}
                id={option.label}
                onClick={() => !option.isEditing && handleClickOption(option)}
                value={option.value}
              >
                {option.isEditing &&
                  <>
                    <div className={styles["-add-option__area"]}>
                      <Input height={24} maxLength={maxLength} placeholder="Please input task name" labelStyle="-editable" value={option.editValue} onChange={(e) => handleEditInputValue(e, option)} onKeyPressDown={(e) => handleEnterEditOption(e, option)}></Input>
                    </div>
                  </>
                }
                {!option.isEditing &&
                  <>
                    <div className={styles["-select-item"]}>
                      {option.iconUrl && <img src={option.iconUrl} alt='' />}
                      <span className={classNames({ [styles["-bold"]]: selected?.value === option.value })}>{option.label}</span>
                    </div>
                    <div className={styles["icon__area"]}>
                      <IconButton iconPath={ICON.ICON_EDIT} iconWidth={18} iconHeight={18} onClick={(e) => handleClickEdit(e, option)}></IconButton>
                    </div>
                  </>
                }
              </li>
            ))}
            <li>
              <div className={classNames(styles["-add-item"], styles["-select-item"])} onClick={() => setIsAddOption(true)}>
                <span>{`+ ${addText}`}</span>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

