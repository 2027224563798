import styles from "./ContentContainer.module.scss";
import { ContentContainerProps } from './ContentContainer.type';

export default function ContentContainer({ children }: ContentContainerProps) {

  return (
    <div className={styles["content__container"]}>
      {children}
    </div>
  );
};
