import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ChipButton from "components/Button/ChipButton/ChipButton";
import { CHIP_TEXT_COLOR } from "components/Button/ChipButton/ChipButton.type";
import Calendar from "components/Calendar/Calendar";
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import Filter from "components/Filter/Filter";
import Input from "components/Input/Input";
import List from "components/List/List";
import { ALIGN_TYPE, Column } from "components/List/List.type";
import SelectBox from "components/SelectBox/SelectBox";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useProject } from "hooks/feature/project/useProject";
import styles from "pages/Project/Detail/ProjectDetail.module.scss";
import { useEffect, useState } from "react";
import { projectStore } from "stores/project-stores";
import { searchStore } from "stores/search-stores";
import { ProjectRequestListParams, ProjectRequestListResponse, TASK_TYPE, TASK_VIEW_TYPE, TaskType } from "types/feature/project/project";
import { getEE2UserDomain } from "utils/common-utils";
import { PROCESSING_TYPE, PROCESSING_VIEW_TYPE, ProcessingType, RequestType } from "./ProjectRequest.type";

export default function ProjectRequest() {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getProjectRequestList } = useProject();
  const { projectId, getProjectCompany } = projectStore();
  const { filterOptions, displayTypeOptions, selectedOption, isASC, searchValue, resetCalendar, selectedCalendarDay,
    setFilterOptions, setDisplayTypeOptions, setSelectedOption, setIsASC, setResetCalendar, setSearchValue, setSelectedCalendarDay, handleInputValue, searchReset
  } = searchStore();

  const [requestList, setRequestList] = useState<ProjectRequestListResponse[]>([]);

  /* Filter */
  const [filterStatus, setFilterStatus] = useState<ProcessingType[]>([]);
  const [filterTask, setFilterTask] = useState<TaskType[]>([]);

  /* List */
  const columns: Column[] = [
    { header: 'No', field: 'no', align: ALIGN_TYPE.center, required: false },
    { header: 'Task', field: 'coreName', align: ALIGN_TYPE.left, required: false },
    { header: 'Name', field: 'name', align: ALIGN_TYPE.left, required: false, className: ['request-name', 'blue-bold', 'ellipsis'] },
    { header: 'Processing', field: 'processing', align: ALIGN_TYPE.center, required: false, className: ['chip'] },
    { header: 'ImagesCount', field: 'imageCount', align: ALIGN_TYPE.center, required: false, className: ['image-count'] },
    { header: 'Request date', field: 'requestDate', align: ALIGN_TYPE.center, required: false },
    { header: '', field: 'button', align: ALIGN_TYPE.center, required: false },
  ];
  const colStyles: React.CSSProperties[] = [
    { width: '6%' },
    { width: '15%' },
    { width: '31%' },
    { width: '9%' },
    { width: '12%' },
    { width: '15%' },
    { width: '12%' },
  ];
  const [requestData, setRequestData] = useState<RequestType[]>([]);

  useEffect(() => {
    setInitialData();
  }, [])

  useEffect(() => {
    setFilterStatus(filterOptions.find(i => i.title === 'Status')?.values.filter(item => item.checked).map(item => item.value) as ProcessingType[]);
    setFilterTask(filterOptions.find(i => i.title === 'Task')?.values.filter(item => item.checked).map(item => item.value) as TaskType[]);
  }, [filterOptions]);

  useEffect(() => {
    fetchProjectRequestList();
  }, [projectId, selectedOption, isASC, selectedCalendarDay, filterStatus, filterTask]);

  useEffect(() => {
    const datas: RequestType[] = [];
    requestList.forEach((request, i) => {
      const data: RequestType = {
        no: i + 1,
        id: request.id,
        coreName: request.aiCore.coreName,
        name: request.name,
        processing: request.status,
        imageCount: request.requestImageDataList.length,
        isResult: request.status === PROCESSING_TYPE.COMPLETED,
        requestDate: request.createdDate,
        button: request.status === PROCESSING_TYPE.COMPLETED ?
          <ChipButton text="Result" textColor={CHIP_TEXT_COLOR.white} iconPath={ICON.ICON_ARROW_THANSIGN_RIGHT} onClick={(e) => { onGoTo(data.id) }}></ChipButton> :
          <ChipButton text="Result" textColor={CHIP_TEXT_COLOR.white} iconPath={ICON.ICON_ARROW_THANSIGN_RIGHT} disabled></ChipButton>
      }

      datas.push(data);
    });

    setRequestData(datas);
  }, [requestList]);

  const setInitialData = () => {
    const options = [
      { label: 'Request date', value: 'createdDate' },
    ];
    setDisplayTypeOptions(options);
    setSelectedOption(options[0]);
    setFilterOptions([
      {
        title: 'Status',
        values: [
          { label: PROCESSING_VIEW_TYPE.ANALYZING, value: PROCESSING_TYPE.ANALYZING, checked: false },
          { label: PROCESSING_VIEW_TYPE.COMPLETED, value: PROCESSING_TYPE.COMPLETED, checked: false },
          { label: PROCESSING_VIEW_TYPE.ON_STANDBY, value: PROCESSING_TYPE.ON_STANDBY, checked: false },
          { label: PROCESSING_VIEW_TYPE.FAILED, value: PROCESSING_TYPE.FAILED, checked: false },
        ]
      },
      {
        title: 'Task',
        values: [
          { label: TASK_VIEW_TYPE.OBJECT_DETECTION, value: TASK_TYPE.OBJECT_DETECTION, checked: false },
          { label: TASK_VIEW_TYPE.SEGMENTATION, value: TASK_TYPE.SEGMENTATION, checked: false },
          { label: TASK_VIEW_TYPE.CHANGE_DETECTION, value: TASK_TYPE.CHANGE_DETECTION, checked: false },
        ]
      },
    ])
  }

  const onGoTo = (id: number) => {
    const cUrl = getProjectCompany()?.value?.url;
    const domain = getEE2UserDomain();
    if (!domain || !cUrl || !projectId || !id) return;
    window.location.replace(`${domain}/${cUrl}?projectId=${projectId}&requestId=${id}`);
  }

  const handleClickAddRequest = () => {
    navigateToReplacePath(`${RoutePath.projectRequestDetail}?${URL_QUERY.PROJECT_ID}=${projectId}`);
  }

  const fetchProjectRequestList = async () => {
    if (!projectId || !selectedOption) return;
    const param: ProjectRequestListParams = {
      sort: selectedOption.value,
      direction: isASC ? 'ASC' : 'DESC',
      searchKeyword: searchValue,
      searchStartDate: selectedCalendarDay[0] || '',
      searchEndDate: selectedCalendarDay[1] || '',
      status: filterStatus,
      task: filterTask
    }

    const result = await getProjectRequestList(projectId, param);
    if (result) {
      setRequestList(result);
    }
  }

  const resetOptions = () => {
    const updatedFilterOptions = [...filterOptions];
    updatedFilterOptions.forEach(section => {
      section.values.forEach(item => {
        item.checked = false;
      })
    });
    setFilterOptions(updatedFilterOptions);
    setSearchValue('');
    setSelectedOption(displayTypeOptions[0]);
    setIsASC(false);
    setResetCalendar(!resetCalendar);
  }

  return (
    <>
      <ContentWrap>
        <SubTitleContainer title="Request" length={requestData.length} textType={TextType.h3} iconPath={ICON.ICON_REQUEST}
          rightComponent={
            <div className={styles["btn__area"]}>
              <Button color={BUTTON_COLOR.black} text="Registration" size={"size80"} iconPath={ICON.ICON_PLUS} width={112} onClick={handleClickAddRequest}></Button>
            </div>
          }></SubTitleContainer>
        <ContentBox>
          <div className={styles["request-content__header"]}>
            <Filter filterDatas={filterOptions} setFilterDatas={setFilterOptions} ></Filter>
            <div className={styles["btn-area"]}>
              <div className={styles["-gap6"]}>
                <SelectBox selectBoxStyle="border-bold" selected={selectedOption} setSelected={setSelectedOption} options={displayTypeOptions} width={180}></SelectBox>
                <Button iconPath={isASC ? ICON.ICON_ARROW_SORT_UP : ICON.ICON_ARROW_SORT_DOWN} color={BUTTON_COLOR.white} onClick={() => setIsASC(!isASC)}></Button>
              </div>
              <Calendar width={240} handleDate={setSelectedCalendarDay} resetDate={resetCalendar}></Calendar>
              <form>
                <Input type="text" width={280} placeholder="Search by name" onChange={handleInputValue} value={searchValue}
                ></Input>
                <div className={styles["-gap6"]}>
                  <Button type="submit" size={BUTTON_SIZE[80]} color={BUTTON_COLOR.black} text="Search" onClick={(e) => { e.preventDefault(); fetchProjectRequestList(); }}></Button>
                  <Button iconPath={ICON.ICON_RELOAD_BLACK} color={BUTTON_COLOR.white} iconWidth={18} iconHeight={18} onClick={resetOptions}></Button>
                </div>
              </form>
            </div>
          </div>
          <List columns={columns} datas={requestData} colStyles={colStyles}></List>
        </ContentBox>
      </ContentWrap>
    </>
  )
}