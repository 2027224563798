import { SEARCH_QUERY_KEYS, SEARCH_QUERY_KEYS_ARR } from "constants/searchQueryKey";
import { useSearchParams } from "react-router-dom";
import { searchStore } from "stores/search-stores";

export default function useCustomSearchParams () {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filterOptions, displayTypeOptions, setInitCalendarDay, selectedCalendarDay, setFilterOptions, setSelectedCalendarDay, setSelectedOption, setIsASC, setSearchValue } = searchStore();

  const setSearchParam = (key: string, value: string) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  }

  const getSearchParam = (key: string) => {
    return searchParams.get(key);
  };

  const setInitialParam = () => {
    SEARCH_QUERY_KEYS_ARR.forEach((key) => {
      const param = getSearchParam(key);
      if (!param) return;
      setStateValue(key, param);
    });
    setCalendarValue();
  }

  const setCalendarValue = () => {
    const updatedDay = [getSearchParam(SEARCH_QUERY_KEYS.LIMIT_START), getSearchParam(SEARCH_QUERY_KEYS.LIMIT_END)];
    setInitCalendarDay(updatedDay);
    setSelectedCalendarDay(updatedDay);
  }

  const setStateValue = (key: string, queryParamValue: string) => {
    switch (key) {
      case SEARCH_QUERY_KEYS.SORT: 
        const targetOption = displayTypeOptions.find(i => i.value === queryParamValue);
        targetOption && setSelectedOption(targetOption);
        break;
      case SEARCH_QUERY_KEYS.DIRECTION:
        setIsASC(queryParamValue === 'ASC');
        break;
      case SEARCH_QUERY_KEYS.SEARCH_KEYWORD:
        setSearchValue(queryParamValue);
        break;
    }
  }

  return { searchParams, setSearchParams, setSearchParam, getSearchParam, setInitialParam }
}