import { LOCAL_STORAGE_KEYS } from "constants/localStorageKey";
import { LocalStorageKeyType } from "types/auth/auth";

export const getLocalStorage = (key: LocalStorageKeyType) => {
  const itemStr = localStorage.getItem(key);

  if(!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if(item.expires && now.getTime() > item.expires) {
    removeLocalStorage(key);
    return null;
  }

  return item.value;
}

export const setLocalStorage = (key: LocalStorageKeyType, value: string, exp?: number) => {
  const now = new Date();
  const item = exp ? { value: value, expires: now.getTime() + exp * 60 * 1000 * 1000} : { value: value }
  localStorage.setItem(key, JSON.stringify(item));
}

export const removeLocalStorage = (key: LocalStorageKeyType) => {
  localStorage.removeItem(key);
}

export const logoutLocalStorage = () => {
  removeLocalStorage(LOCAL_STORAGE_KEYS.IDF_ACCESS_TOKEN);
}