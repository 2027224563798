import ContentContainer from "components/Container/Content/ContentContainer/ContentContainer";
import BlockTitleContainer from "components/Container/Title/BlockTitleContainer/BlockTitleContainer";
import ProjectInformation from "components/Information/Project/ProjectInformation";
import ProjectRequest from "components/Request/ProjectRequest";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { URL_QUERY } from "constants/urlQuery";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useProject } from "hooks/feature/project/useProject";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { projectStore } from "stores/project-stores";
import { PERIOD_TYPE } from "types/feature/project/project";

export default function ProjectDetail() {
  const location = useLocation();
  const { navigateToReplacePath, navigateToBack } = useCustomNavigate();
  const { getProjectDetail } = useProject();
  const { projectId, setProjectId, setProjectCompanyId, setProjectName, setFileName, setProjectCompany, setFileURL, setPeriodValue, setPeriodDateValue, setImageDataIds } = projectStore();

  const [isRegist, setIsRegist] = useState(location.pathname === RoutePath.projectDetailRegist);

  useEffect(() => {
    if (isRegist) {
      setFileName('dabeeo_logo.svg');
      setFileURL(ICON.LOGO_EARTHEYE_BLACK);
    }
  }, [isRegist])

  useEffect(() => {
    setIsRegist(location.pathname === RoutePath.projectDetailRegist);
    const url = new URLSearchParams(location.search);
    const projectId = url.get(URL_QUERY.PROJECT_ID);
    if (!isRegist && !projectId) {
      navigateToReplacePath(RoutePath.project);
    }
    setProjectId(Number(projectId));
  }, [location]);

  useEffect(() => {
    fetchProjectDetail();
  }, [projectId]);

  const fetchProjectDetail = async () => {
    if (!projectId) return;
    const result = await getProjectDetail(projectId);
    setProjectName(result.name);
    setProjectCompanyId(result.company.id);
    setProjectCompany({ label: result.company.name, value: result.company });
    setFileName(result.logoName ? result.logoName : 'dabeeo_logo.svg');
    setFileURL(result.logoUrl ? result.logoUrl : ICON.LOGO_EARTHEYE_BLACK);
    setPeriodValue(result.periodOfUse ? PERIOD_TYPE.limit : PERIOD_TYPE.unlimit);
    setPeriodDateValue([result.limitStartPeriod, result.limitEndPeriod]);
    setImageDataIds(result.imageDataSetList);
  }

  return (
    <>
      <BlockTitleContainer title={`Project ${isRegist ? 'Registration' : 'Detail'}`} textType={TextType.h2} backButton onClickBackButton={navigateToBack}></BlockTitleContainer>
      <ContentContainer>
        <ProjectInformation isRegist={isRegist}></ProjectInformation>
        {!isRegist &&
          <ProjectRequest></ProjectRequest>
        }
      </ContentContainer>
    </>
  )
}