import Title from "components/Title/Title";
import styles from "./SubTitleContainer.module.scss";
import { SubTitleContainerProps } from "./SubTitleContainer.type";

export default function SubTitleContainer({
  title,
  textType,
  rightComponent,
  iconHeight,
  iconWidth,
  iconPath,
  required,
  length
}: SubTitleContainerProps) {

  return (
    <div className={styles["sub-title-container__container"]}>
      <div className={styles["sub-title__wrap"]}>
        {iconPath && <img src={iconPath} alt='' style={{ width: iconWidth ? iconWidth : 'auto', height: iconHeight ? iconHeight : 'auto' }} />}
        <Title text={title} textType={textType} />
        {length !== undefined && <span className={styles["-length"]}>{length}</span>}
        {required && <span className={styles["-required"]}> *</span>}
      </div>
      {rightComponent}
    </div>
  );
};
