export const LAYER_PROPS_TYPES = {
  LAYER_TYPE: 'LAYER_TYPE',
  LAYER_TILE_DATA_NAME: 'LAYER_TILE_DATA_NAME'
} as const;

export const LAYER_TYPES = {
  AOI_LAYER: 'aoiLayer',
  BBOX_LAYER: 'bboxLayer',
  TILE_LAYER: 'tileLayer'
}

export const INTERACTION_PROPS_TYPES = {
  INTERACTION_TYPE: 'INTERACTION_TYPE'
}

export const INTERACTION_TYPES = {
  DRAW: 'draw',
  TRANSFORM: 'transform'
}