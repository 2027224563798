import { INTERACTION_PROPS_TYPES } from "constants/map/map";
import { Map } from "ol";
import { transform } from "ol/proj";
import { InteractionTypes } from "types/map/interaction";
import { LayerPropsType, LayerTypes } from "types/map/layer";

export function convert4326To3857(coordinates: number[]) {
  return transform(coordinates, "EPSG:4326", "EPSG:3857");
}

export function convert3857To4326(coordinates: number[]) {
  return transform(coordinates, "EPSG:3857", "EPSG:4326");
}

export function getLayerByProps(map: Map, propKey: LayerPropsType, value: LayerTypes | string) {
  return map?.getAllLayers().filter(layer => layer.get(propKey) === value);
}

export function getInteractionByProps(map: Map, type: InteractionTypes) {
  return map?.getInteractions().getArray().find(i => i.get(INTERACTION_PROPS_TYPES.INTERACTION_TYPE) === type);
}