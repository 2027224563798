import { ProcessingType } from "components/Request/ProjectRequest.type";
import { CommonContentType, OrderType } from "types/common/common";
import { AiCoreVersionResponse } from "../aiCore/aiCore";
import { ImageryInfoResponse } from "../idf/idf";

//Project 등록 응답 
export type ProjectRegistResponse = ProjectContent & {
  
}

//Project 목록 조회 파라미터
export type ProjectListParams = {
  companyIds: string[];
  limitStartPeriod?: string;
  limitEndPeriod?: string;
  page?: number;
  size?: number;
  direction?: OrderType;
  sort?: string;
  searchKeyword?: string;
}

//Project 목록 조회 응답
export type ProjectListResponse = CommonContentType<ProjectContent> & {
  
}

//Project 상세 조회 응답 - Content
export type ProjectContent = {
  id: number;
  name: string;
  company: ProjectCompany;
  limitStartPeriod: string;
  limitEndPeriod: string;
  periodOfUse: boolean;
  createDate: string;
  logoName: string;
  logoUrl: string;
  imageDataSetList: number[];
}

//Project 목록 조회 응답 - Company
type ProjectCompany = {
  id: number;
  name: string;
  logoImagePath: string;
  restrictProject: boolean;
  projectLimitCount: number;
  url: string;
}

//Company 목록 조회 응답
export type CompanyListResponse = {
  id: number;
  name: string;
  updateDate: string;
  createDate: string;
}

//Project Request 목록 조회 파라미터
export type ProjectRequestListParams = {
  page?: number;
  size?: number;
  sort?: 'createdDate';
  direction?: OrderType;
  status?: ProcessingType[] | '';
  task?: TaskType[] | '';
  searchKeyword?: string;
  searchStartDate?: string;
  searchEndDate?: string;
}

//Project Request 목록 조회 응답
export type ProjectRequestListResponse = {
  id: number;
  name: string;
  status: ProcessingType;
  aiCore: ProjectRequestAICore;
  requestImageDataList: ProjectRequestDetailImageData[];
  selectedStartPeriod: string;
  selectedEndPeriod: string;
  outputShape: string;
  createdDate: string;
}

//Project Request 등록 파라미터
export type ProjectRequestParams = {
  name: string;
  aiCoreVersionId: number[],
  imageDataList: ImageryInfoResponse[],
}

//Project Request 등록 응답
export type ProjectRequestResponse = {
  name: string;
  aiCoreId: number[],
  aiCoreVersionId: number,
  imageDataList: ProjectRequestImageData[]
}

//Project Request 등록 응답 - Image Data
export type ProjectRequestImageData = {
  id: number;
  type: string;
  name: string;
  mode: string;
  resolution: string;
  size: string;
  provider: string;
  createdDate: string;
}

//Project Request 상세 조회 응답
export type ProjectRequestDetailResponse = {
  id: number;
  name: string;
  status: string;
  aiCore: ProjectRequestAICore[];
  requestImageDataList: ProjectRequestDetailImageData[];
}

//Project Request 상세 조회 응답 - AICore
type ProjectRequestAICore = {
  id: number;
  coreName: string;
  description: string;
  task: string;
  options: string[];
  versions: AiCoreVersionResponse[];
}

//Project Request 상세 조회 응답 - Image Data
type ProjectRequestDetailImageData = {
  id: number;
  imageDataSetId: number;
  imageDataId: number;
  imageCreatedDate: string;
  position: string;
  path: string;
  imageTileUrl: string;
  swLat: number;
  swLng: number;
  neLat: number;
  neLng: number
}

//Project Image Type, Project Image Classification 조회 응답
export type ProjectRequestCodeResponse = {
  code: string;
  idfCode: string;
  name: string;
}

//Project Task Model 리스트 조회 응답
export type ProjectRequestModelsResponse = {
  id: number;
  task: string;
  modelName: string;
  versionLists: ModelVersion[];
}

//Project Member 검색 조회 응답
export type ProjectMemberResponse = {
  id: number;
  username: string;
  nickname: string;
  email: string;
}

export type ModelVersion = {
  id: number;
  version: string;
}

export const TASK_TYPE = {
  OBJECT_DETECTION: 'OBJECT_DETECTION',
  SEGMENTATION: 'SEGMENTATION',
  CHANGE_DETECTION: 'CHANGE_DETECTION',
  IMAGE_SUPER_RESOLUTION: 'IMAGE_SUPER_RESOLUTION'
} as const;

export type TaskType = (typeof TASK_TYPE)[keyof typeof TASK_TYPE];

export const TASK_VIEW_TYPE = {
  OBJECT_DETECTION: 'Object Detection',
  SEGMENTATION: 'Segmentation',
  CHANGE_DETECTION: 'Change Detection',
  IMAGE_SUPER_RESOLUTION: 'Image Super Resolution'
} as const;

export type TaskViewType = (typeof TASK_VIEW_TYPE)[keyof typeof TASK_VIEW_TYPE];

export const PERIOD_TYPE = {
  unlimit: 'unlimit',
  limit: 'limit'
} as const;

export type PeriodType = (typeof PERIOD_TYPE)[keyof typeof PERIOD_TYPE];

// export const MODEL_TYPE = {
//   palmTree: 'palmTree',
//   ship: 'ship',
//   carbonAbsorption: 'carbonAbsorption',
//   abiesTree: 'abiesTree',
//   car: 'car',
//   roadSign: 'roadSign',
// } as const;

// export type ModelType = (typeof MODEL_TYPE)[keyof typeof MODEL_TYPE];

// export const MODEL_VIEW_TYPE = {
//   palmTree: 'Palm tree',
//   ship: 'Ship',
//   carbonAbsorption: 'Carbon absorption',
//   abiesTree: 'Abies tree',
//   car: 'Car',
//   roadSign: 'Road sign',
// } as const;

// export type ModelViewType = (typeof MODEL_VIEW_TYPE)[keyof typeof MODEL_VIEW_TYPE];

