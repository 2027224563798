import classNames from "classnames";
import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import Checkbox from "components/CheckBox/CheckBox";
import ModalContainer from "components/Container/Modal/ModalContainer";
import Input from "components/Input/Input";
import { useProject } from "hooks/feature/project/useProject";
import { ChangeEvent, useEffect, useState } from "react";
import { projectStore } from "stores/project-stores";
import Popup from "../Popup";
import styles from "../Popup.module.scss";
import MemberItem from "./MemberItem/MemberItem";
import { MemberData, MemberPopupProps } from "./MemberPopup.type";

export default function MemberPopup({ onClosePopup, members, setMembers }: MemberPopupProps) {
  const { getProjectMembers } = useProject();
  const { projectCompany } = projectStore();
  const [editMembers, setEditMembers] = useState<MemberData[]>(members)
  const [totalChecked, setTotalChecked] = useState(false);
  const [searchMemberIds, setSearchMemberIds] = useState<number[]>([]);

  /* Search Title */
  const [searchValue, setSearchValue] = useState('');
  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setSearchValue(word);
  };

  useEffect(() => {
    setTotalChecked(editMembers.filter(i => i.checked).length === editMembers.length)
  }, [editMembers]);

  useEffect(() => {
    setEditMembers(members);
  }, [members]);

  useEffect(() => {
    fetchSearchMemberList();
  }, [searchValue]);

  const handleMemberClick = (e: ChangeEvent<HTMLInputElement>, member: MemberData) => {
    e.preventDefault();
    const index = editMembers.findIndex(i => i.id === member.id);

    const updatedData = { ...editMembers[index] };
    updatedData.checked = !updatedData.checked;

    const updatedMembers = [...editMembers];
    updatedMembers[index] = updatedData;
    setEditMembers(updatedMembers);
  }

  const handleTotalClick = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedMembers = [...editMembers];
    updatedMembers.map(i => i.checked = !totalChecked);
    setEditMembers(updatedMembers);
    setTotalChecked(!totalChecked);
  }

  const handleClickSave = () => {
    setMembers(editMembers);
    onClosePopup();
  }

  const fetchSearchMemberList = async () => {
    const result = await getProjectMembers(projectCompany?.value.id, searchValue);
    if (result) {
      setSearchMemberIds(result.map(i => { return i.id }))
    }
  }

  return (
    <ModalContainer>
      <Popup headerText="Member Setting" onClosePopup={onClosePopup} bottomButtons={[
        <Button color={BUTTON_COLOR.white} text="cancel" width={200} onClick={onClosePopup}></Button>,
        <Button color={BUTTON_COLOR.black} text="Save Changes" width={358} onClick={handleClickSave}></Button>
      ]}>
        <div className={styles["member-select__body"]}>
          <div className={styles["select-top__area"]}>
            <div className={styles["label__wrap"]}>
              <span>Selected</span>
              <span className={classNames(styles["-count"], { [styles["-blue"]]: editMembers.filter(i => i.checked).length > 0 })}>{editMembers.filter(i => i.checked).length}</span>
            </div>
            <div className={styles["button__wrap"]}>
              <Input type="text" width={320} placeholder="Please input name, user id,  e-mail" onChange={handleInputValue} value={searchValue}></Input>
              <Button color={BUTTON_COLOR.black} size={BUTTON_SIZE[80]} text="Search"></Button>
            </div>
          </div>
          <div className={styles["select-content__area"]}>
            <div className={classNames(styles["path-list__container"], styles["-member"])}>
              <div className={styles["title__wrap"]}>
                <div className={styles["-checkbox"]} style={{ width: 28 }}><Checkbox onChange={handleTotalClick} checked={totalChecked}></Checkbox></div>
                <div className={styles["-col"]} style={{ width: 140 }}><span>Name</span></div>
                <div className={styles["-col"]} style={{ width: 140 }}><span>User ID</span></div>
                <div className={styles["-col"]} style={{ width: 231 }}><span>E-mail</span></div>
              </div>
              <div className={styles["list__wrap"]}>
                {editMembers.filter(i => searchMemberIds.includes(i.id)).map((member) => {
                  return <MemberItem member={member} onClickMemberName={handleMemberClick} key={member.id} searchValue={searchValue} ></MemberItem>
                })}
              </div>
            </div>
          </div>

        </div>
      </Popup>
    </ModalContainer>

  )
}