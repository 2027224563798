import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import Calendar from "components/Calendar/Calendar";
import Filter from "components/Filter/Filter";
import Input from "components/Input/Input";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { DATE_FORMAT } from "constants/common";
import { ICON } from "constants/icons";
import { SEARCH_QUERY_KEYS } from "constants/searchQueryKey";
import dayjs from "dayjs";
import useCustomSearchParams from "hooks/common/useCustomSearchParams";
import { useMemo } from "react";
import { searchStore } from "stores/search-stores";
import styles from "./SearchFilter.module.scss";
import { SearchFilterProps } from "./SearchFilter.type";

export default function SearchFilter({ isFilter = true, isOrder = true, isDirection = true, isCalendar = true, isSearchValue = true, calendarPlaceholder }: SearchFilterProps) {
  const { filterOptions, displayTypeOptions, selectedOption, isASC, resetCalendar, searchValue, initCalendarDay,
    setFilterOptions, setSelectedOption, setIsASC, setResetCalendar, setSearchValue, handleInputValue, setSelectedCalendarDay,
  } = searchStore();
  const { searchParams, setSearchParam, setSearchParams } = useCustomSearchParams();

  const handleDirectionButtonClick = () => {
    setSearchParam(SEARCH_QUERY_KEYS.DIRECTION, !isASC ? 'ASC' : 'DESC');
    setIsASC(!isASC);
  }

  const resetOptions = () => {
    const updatedFilterOptions = [...filterOptions];
    updatedFilterOptions.forEach(section => {
      section.values.forEach(item => {
        item.checked = false;
      })
    });
    setFilterOptions(updatedFilterOptions);
    setSearchValue('');
    setSelectedOption(displayTypeOptions[0]);
    setIsASC(false);
    setResetCalendar(!resetCalendar);
    setSearchParams({});
  }

  const handleSearchClick = () => {
    setSearchParam(SEARCH_QUERY_KEYS.SEARCH_KEYWORD, searchValue || '');
  }

  const handleOrderClick = (option: CommonOptionType) => {
    setSearchParam(SEARCH_QUERY_KEYS.SORT, option.value);
    setSelectedOption(option);
  }

  const handleDateChange = (dates: (string | null)[]) => {
    setSearchParam(SEARCH_QUERY_KEYS.LIMIT_START, dates[0] || "");
    setSearchParam(SEARCH_QUERY_KEYS.LIMIT_END, dates[1] || "");
    setSelectedCalendarDay(dates);
  }
  const initInput = useMemo(() => {
    return <Input type="text" width={280} placeholder="Search by name" onChange={handleInputValue} value={searchValue}></Input>
  }, [searchValue])

  const initCalendar = useMemo(() => {
    return (
      <Calendar startDate={initCalendarDay[0] ? dayjs(initCalendarDay[0], DATE_FORMAT).toDate() : null} endDate={initCalendarDay[1] ? dayjs(initCalendarDay[1], DATE_FORMAT).toDate() : null} width={240} handleDate={handleDateChange} resetDate={resetCalendar} placeholder={calendarPlaceholder} useQuery></Calendar>
    )
  }, [resetCalendar, searchParams]);

  return (
    <>
      {isFilter && <Filter></Filter>}
      <div className={styles["search__right_wrap"]}>
        {isOrder && <SelectBox selected={selectedOption} setSelected={setSelectedOption} options={displayTypeOptions} width={180} onChangeSelect={handleOrderClick}></SelectBox>}
        {isDirection && <Button iconPath={isASC ? ICON.ICON_ARROW_SORT_UP : ICON.ICON_ARROW_SORT_DOWN} color={BUTTON_COLOR.white} onClick={handleDirectionButtonClick}></Button>}
        {isCalendar && initCalendar}
        {isSearchValue && <form>
          {initInput}
          <Button type="submit" size={BUTTON_SIZE[80]} color={BUTTON_COLOR.black} text="Search" onClick={(e) => { e.preventDefault(); handleSearchClick(); }}></Button>
        </form>}
        <Button iconPath={ICON.ICON_RELOAD_BLACK} color={BUTTON_COLOR.white} iconWidth={18} iconHeight={18} onClick={resetOptions}></Button>
      </div>
    </>
  )
}