
import ContentBox from "components/Container/Content/ContentBox/ContentBox";
import ContentWrap from "components/Container/Content/ContentWrap/ContentWrap";
import FormControl from "components/Container/Content/FormControl/FormControl";
import SubTitleContainer from "components/Container/Title/SubTitleContainer/SubTitleContainer";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import { TextType } from "components/Title/Title.type";
import { ICON } from "constants/icons";
import { useProject } from "hooks/feature/project/useProject";
import styles from "pages/Project/RequestDetail/ProjectRequestDetail.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import { projectStore } from "stores/project-stores";
import { AiCoreTask } from "types/feature/aiCore/aiCore";
import { ProjectRequestModelsResponse } from "types/feature/project/project";
// import { MODEL_TYPE, MODEL_VIEW_TYPE, TASK_TYPE, TASK_VIEW_TYPE } from "./RequestProgress.type";

export default function RequestProgress() {
  const { selectedTask, selectedModel, selectedVersion, setSelectedTask, setSelectedModel, setSelectedVersion } = projectStore();
  const { getProjectRequestCore, getProjectRequestModels } = useProject();
  const [taskOptions, setTaskOptions] = useState<CommonOptionType[]>([]);
  const [modelOptions, setModelOptions] = useState<CommonOptionType[]>([]);
  const [versionOptions, setVersionOptions] = useState<CommonOptionType[]>([]);

  const [tasks, setTasks] = useState<AiCoreTask[]>([]);
  const [models, setModels] = useState<ProjectRequestModelsResponse[]>([]);

  useEffect(() => {
    fetchTasks();
    return () => {
      setSelectedTask(undefined);
    }
  }, []);

  useEffect(() => {
    setSelectedVersion(undefined);
  }, [selectedModel]);


  useEffect(() => {
    if (!selectedTask) return;
    setSelectedModel(undefined);
    setSelectedVersion(undefined);
    fetchModels();
  }, [selectedTask]);

  useEffect(() => {
    const options: CommonOptionType[] = [];
    tasks.forEach((task) => {
      const option: CommonOptionType = {
        label: task.name, value: task,
      };
      options.push(option);
    })
    setTaskOptions(options);
  }, [tasks]);

  useEffect(() => {
    const options: CommonOptionType[] = [];
    models.forEach((model) => {
      const option: CommonOptionType = {
        label: model.modelName, value: model,
      };
      options.push(option);
    })
    setModelOptions(options);
  }, [models]);

  useEffect(() => {
    const options: CommonOptionType[] = [];
    const targetModel = models.find(i => i.id === selectedModel?.value.id);
    targetModel?.versionLists.forEach((version) => {
      const option: CommonOptionType = {
        label: version.version, value: version,
      };
      options.push(option);
    });
    options.sort((a, b) => { return Number(b.label) - Number(a.label); })
    setVersionOptions(options);

  }, [selectedModel]);

  const [checkedList, setCheckedList] = useState<string[]>([]);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...checkedList];
    if (e?.target.checked) {
      updatedList = [...checkedList, e.target.value];
    } else {
      updatedList.splice(checkedList.indexOf(e?.target.value), 1);
    }
    setCheckedList(updatedList);
  }

  const fetchTasks = async () => {
    const result = await getProjectRequestCore();
    if (result) {
      setTasks(result);
    }
  }

  const fetchModels = async () => {
    const result = await getProjectRequestModels(selectedTask?.value.code);
    if (result) {
      setModels(result);
    }
  }

  return (
    <>
      <ContentWrap>
        <SubTitleContainer title="Progress" textType={TextType.h3} iconPath={ICON.ICON_SETTING} required={true}></SubTitleContainer>
        <ContentBox>
          <FormControl name="Progress"
            control={
              <div className={styles["request-detail-progress__wrap"]}>
                <div className={styles["select-box__wrap"]}>
                  <SelectBox selected={selectedTask} setSelected={setSelectedTask} options={taskOptions} placeholder="Select task" width={160}></SelectBox>
                  <SelectBox selected={selectedModel} setSelected={setSelectedModel} options={modelOptions} placeholder="Select model" width={210} disabled={selectedTask === undefined}></SelectBox>
                  <SelectBox selected={selectedVersion} setSelected={setSelectedVersion} options={versionOptions} placeholder="Version" width={100} disabled={selectedModel === undefined}></SelectBox>
                </div>
                {/* {selectedTask !== undefined && selectedTask.value === TASK_TYPE.SEGMENTATION && selectedVersion !== undefined &&
                  <div className={styles["options__wrap"]}>
                    <Checkbox id={1} label="Option 1" onChange={handleCheck} width={140} height={36}></Checkbox>
                    <Checkbox id={2} label="Option 2" onChange={handleCheck} width={140} height={36}></Checkbox>
                    <Checkbox id={3} label="Option 3" onChange={handleCheck} width={140} height={36}></Checkbox>
                  </div>
                } */}
              </div>
            }
          ></FormControl>
        </ContentBox >
      </ContentWrap >
    </>

  )
}