import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { compStore } from "stores/comp-stores";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const { contentWrap } = compStore();

    useEffect(() => {
      if(contentWrap) {
        contentWrap.scrollTop = 0;
      }
    }, [pathname]);

    return null;
};