import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import Calendar from "components/Calendar/Calendar";
import Checkbox from "components/CheckBox/CheckBox";
import Filter from "components/Filter/Filter";
import { FilterDatas } from "components/Filter/Filter.type";
import IconButton from "components/IconButton/IconButton";
import Input from "components/Input/Input";
import Radio from "components/Radio/Radio";
import EditableSelectBox from "components/SelectBox/EditableSelectBox/EditableSelectBox";
import SelectBox from "components/SelectBox/SelectBox";
import { CommonOptionType } from "components/SelectBox/SelectBox.type";
import TextArea from "components/TextArea/TextArea";
import { ICON } from "constants/icons";
import { ChangeEvent, useState } from "react";
import styles from "./UiKitComponent.module.scss";

export default function UiKitComponent() {
  const [inputValue, setInputValue] = useState('');
  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const word = e.target.value;
    setInputValue(word);
  };

  const [textAreaValue, setTextAreaValue] = useState('');
  const handleTextAreaValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const word = e.target.value;
    setTextAreaValue(word);
  };

  const DISPLAY_TYPE_OPTIONS: CommonOptionType[] = [
    { label: 'label1', value: 'test1' },
    { label: 'label2', value: 'value2' },
  ];
  const [selectedOption, setSelectedOption] = useState<CommonOptionType>(DISPLAY_TYPE_OPTIONS[0]);
  const [selectedOption2, setSelectedOption2] = useState<CommonOptionType | undefined>(undefined);

  const [editableTypeOptions, setEditableTypeOptions] = useState<CommonOptionType[]>([]);
  const [editableSelectedOption, setEditableSelectedOption] = useState<CommonOptionType | undefined>(undefined);
  const handleChangeDisplayType = (option: CommonOptionType) => {

  };

  const [radioValue, setRadioValue] = useState('4');
  const [radioOptions, setRadioOptions] = useState([
    { label: "Text1", value: "1", disabled: false },
    { label: "Text2", value: "2", disabled: false },
    { label: "Text3", value: "3", disabled: true },
    { label: "Text4", value: "4", disabled: true },
  ])

  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [checkOptions, setCheckOptions] = useState<CommonOptionType[]>([
    { label: "Text1", checked: true, disabled: false },
    { label: "Text2", checked: false, disabled: false },
    { label: "Text3", checked: false, disabled: true },
    { label: "Text4", checked: true, disabled: true },
  ])

  const [filterOptions, setFilterOptions] = useState<FilterDatas[]>(
    [
      {
        title: 'Type',
        values: [
          { label: 'SAR', },
          { label: 'Aerial', },
          { label: 'Drone', },
          { label: 'Orthophoto', }
        ]
      },
      {
        title: 'Provider',
        values: [
          { label: 'Airbus', },
          { label: 'NGII', },
          { label: 'Dabeeo', },
        ]
      },
      {
        title: 'Resolution',
        values: [
          { label: '~ 10cm', },
          { label: '~ 20cm', },
          { label: '~ 30cm', },
          { label: '~ 40cm', }
        ]
      },
      {
        title: 'Status',
        values: [
          { label: 'Normal', },
          { label: 'Deleted', },
          { label: 'Destruction', disabled: true },
        ]
      },
    ]
  );

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    let updatedList = [...checkedList];
    if (e?.target.checked) {
      updatedList = [...checkedList, e.target.value];
    } else {
      updatedList.splice(checkedList.indexOf(e?.target.value), 1);
    }
    setCheckedList(updatedList);
  }

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [selectedCalendarDay, setSelectedCalendarDay] = useState<(string | null)[]>([]);
  const handleDate = (dates: (string | null)[]) => {
    setSelectedCalendarDay(dates);
  };

  return (
    <div className={styles["uikit__container"]}>
      <div className={styles["uikit__wrap"]}>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Buttons 120</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.blue} text="버튼"></Button>
              <Button color={BUTTON_COLOR.blue} disabled={true} text="버튼"></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.black} text="버튼"></Button>
              <Button color={BUTTON_COLOR.black} disabled={true} text="버튼"></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.white} text="버튼"></Button>
              <Button color={BUTTON_COLOR.white} disabled={true} text="버튼"></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.red} text="버튼"></Button>
              <Button color={BUTTON_COLOR.red} disabled={true} text="버튼"></Button>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Buttons 80</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.black} text="새로고침" size={BUTTON_SIZE[80]} iconPath={ICON.ICON_RELOAD_WHITE} width={85}></Button>
              <Button color={BUTTON_COLOR.black} disabled={true} text="새로고침" size={BUTTON_SIZE[80]} iconPath={ICON.ICON_RELOAD_WHITE} width={85}></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.black} text="새로고침" size={BUTTON_SIZE[80]}></Button>
              <Button color={BUTTON_COLOR.black} disabled={true} text="새로고침" size={BUTTON_SIZE[80]}></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.white} text="새로고침" size={BUTTON_SIZE[80]}></Button>
              <Button color={BUTTON_COLOR.white} disabled={true} text="새로고침" size={BUTTON_SIZE[80]}></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.white} text="새로고침" size={BUTTON_SIZE[80]} iconPath={ICON.ICON_RELOAD_BLACK} width={86}></Button>
              <Button color={BUTTON_COLOR.white} disabled={true} text="새로고침" size={BUTTON_SIZE[80]} iconPath={ICON.ICON_RELOAD_BLACK} width={86}></Button>
            </div>
            <div className={styles["-col"]}>
              <Button color={BUTTON_COLOR.red} text="새로고침" size={BUTTON_SIZE[80]}></Button>
              <Button color={BUTTON_COLOR.red} disabled={true} text="새로고침" size={BUTTON_SIZE[80]}></Button>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Input</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Input type="text" maxLength={50} placeholder="제목을 입력해주세요" onChange={handleInputValue} value={inputValue}
              ></Input>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>TextArea</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <TextArea name='example' maxLength={300} placeholder="상세정보를 입력해주세요" onChange={handleTextAreaValue} value={textAreaValue}></TextArea>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["uikit__wrap"]}>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Select Box</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <SelectBox selected={selectedOption} setSelected={setSelectedOption} options={DISPLAY_TYPE_OPTIONS} onChangeSelect={handleChangeDisplayType}></SelectBox>
              <SelectBox selected={selectedOption2} setSelected={setSelectedOption2} options={DISPLAY_TYPE_OPTIONS} onChangeSelect={handleChangeDisplayType} placeholder={"Select"}></SelectBox>
            </div>
            <div className={styles["-col"]}>
              <EditableSelectBox width={360} selected={editableSelectedOption} setSelected={setEditableSelectedOption} options={editableTypeOptions} setOptions={setEditableTypeOptions} onChangeSelect={handleChangeDisplayType} placeholder="Please input task name"></EditableSelectBox>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Radio</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              {radioOptions.map(option => {
                return (
                  <Radio key={option.value} value={option.value} checked={radioValue === option.value} onChange={() => { setRadioValue(option.value) }} label={option.label} disabled={option.disabled} ></Radio>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Check Box</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              {checkOptions.map((option, i) => {
                return (
                  <Checkbox key={i} id={i} label={option.label} disabled={option.disabled} onChange={handleCheck} checked={option.checked as boolean}></Checkbox>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Filter</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Filter filterDatas={filterOptions} setFilterDatas={setFilterOptions}></Filter>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Calendar</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Calendar width={240} startDate={startDate} handleDate={handleDate}></Calendar>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Button Only Button</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <Button iconPath={ICON.ICON_ARROW_SORT_DOWN} color={BUTTON_COLOR.white} iconWidth={5.2} iconHeight={10.3}></Button>
            </div>
            <div className={styles["-col"]}>
              <Button iconPath={ICON.ICON_RELOAD_BLACK} color={BUTTON_COLOR.white} iconWidth={18} iconHeight={18}></Button>
            </div>
          </div>
        </div>
        <div className={styles["component__area"]}>
          <div className={styles["-header"]}>Icon Button</div>
          <div className={styles["-body"]}>
            <div className={styles["-col"]}>
              <IconButton iconPath={ICON.ICON_EDIT} iconWidth={18} iconHeight={18}></IconButton>
            </div>
            <div className={styles["-col"]}>
              <IconButton iconPath={ICON.ICON_EDIT} iconWidth={18} iconHeight={18} disabled></IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}