import classNames from "classnames";
import { ICON } from "constants/icons";
import styles from "./Popup.module.scss";
import { PopupProps } from "./Popup.type";


export default function Popup({ headerText, children, onClosePopup, bottomButtons, isAlert = false }: PopupProps) {
  return (
    <div className={classNames(styles["popup__container"], { [styles["-alert"]]: isAlert })}>
      <div className={styles["popup__header"]}>
        {!isAlert &&
          <span className={styles["-header-text"]}>{headerText}</span>
        }
        <button onClick={onClosePopup}><img src={ICON.ICON_CLOSE_POPUP} alt=""></img></button>
      </div>
      <div className={styles["popup__body"]}>
        {children}
      </div>
      {bottomButtons &&
        <div className={styles["popup__bottom"]}>
          {bottomButtons.map((button, i) => (
            <div key={i}>{button}</div>
          ))}
        </div>
      }

    </div>
  );
};
