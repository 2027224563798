
import styles from './SearchContainer.module.scss';
import { SearchContainerProps } from './SearchContainer.type';
export default function SearchContainer({ children }: SearchContainerProps) {
  return (
    <div className={styles['search__container']}>
      {children}
    </div>
  );
};
