import classNames from "classnames";
import { ICON } from "constants/icons";
import { MENUS } from "data/menu/menus";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { authStore } from "stores/auth-stores";
import { checkRole } from "utils/auth/authorization-utils";
import styles from "./Header.module.scss";
import { HeaderProps } from "./Header.type";

export default function Header({
  menusVisible = true
}: HeaderProps) {
  const location = useLocation();
  const { userRoles } = authStore();
  const { navigateToReplacePath } = useCustomNavigate();
  const [selectedMenu, setSelectedMenu] = useState('');
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    setShowLogo(location.pathname !== RoutePath.accountPassword);
    setSelectedMenu(location.pathname);
  }, [location])

  const handleClickMenu = (path: string) => {
    navigateToReplacePath(path);
  }


  return (
    <div className={styles["header__container"]}>
      {showLogo && <img className={styles["-logo"]} src={ICON.LOGO_EARTHEYE} alt="" onClick={() => navigateToReplacePath(RoutePath.company)} />}
      {menusVisible && (
        <div className={styles["menu__wrap"]}>
          {MENUS.map(menu => {
            return checkRole({ userRoles, menuRoles: menu.roles })
              ? (<div key={menu.path} className={classNames(styles["-menu"], { [styles["-clicked"]]: menu.children.includes(selectedMenu) })} onClick={() => handleClickMenu(menu.path)}>{menu.name}</div>)
              : <></>
          })}
        </div>
      )}

    </div>
  )
}