import styles from "./ContentWrap.module.scss";
import { ContentWrapProps } from './ContentWrap.type';

export default function ContentWrap({ children }: ContentWrapProps) {

  return (
    <div className={styles["content__wrap"]}>
      {children}
    </div>
  );
};
