import { create } from "zustand";

type CompStore = {
  contentWrap: HTMLDivElement | null;
  setContentWrap: (contentWrap: HTMLDivElement) => void;
};

export const compStore = create<CompStore>((set) => ({
  contentWrap: null,
  setContentWrap: (contentWrap) => set({ contentWrap }),
}));