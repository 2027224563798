import classNames from "classnames";
import { ICON } from "constants/icons";
import styles from "./Tooltip.module.scss";
import { TooltipProps } from "./Tooltip.type";

export default function Tooltip({ text, arrowAlign = 'top', textAlign = 'center' }: TooltipProps) {

  return (
    <div className={classNames(styles["tooltip__wrap"], styles[`${arrowAlign}`])}>
      {(arrowAlign === 'left' || arrowAlign === 'top') &&
        <img src={ICON.ICON_ARROW_TOAST_BUBBLE} alt=""></img>
      }
      <div className={styles["tooltip-box"]} style={{ textAlign: textAlign }}>
        {text}
      </div>
      {(arrowAlign === 'right' || arrowAlign === 'bottom') &&
        <img src={ICON.ICON_ARROW_TOAST_BUBBLE} alt=""></img>
      }
    </div>
  );
};
