import Button from "components/Button/Button";
import { BUTTON_COLOR, BUTTON_SIZE } from "components/Button/Button.type";
import ModalContainer from "components/Container/Modal/ModalContainer";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAccountMember } from "hooks/feature/member/useAccountMember";
import { useEffect, useState } from "react";
import { authStore } from "stores/auth-stores";
import { MyPage } from "types/feature/member/accountMember";
import Popup from "../Popup";
import InformationControl from "./InformationControl/InformationControl";
import styles from "./MyPagePopup.module.scss";
import { MyPagePopupProps } from "./MyPagePopup.type";

export default function MyPagePopup({ onClosePopup }: MyPagePopupProps) {
  const { navigateToReplacePath } = useCustomNavigate();
  const { getInfo } = useAccountMember();
  const { memberId } = authStore();
  const [info, setInfo] = useState<MyPage>({
    nickname: "",
    username: "",
    email: "",
  });

  useEffect(() => {
    if (!memberId) return;
    handleFetchMyPage();
  }, [memberId]);

  const handleFetchMyPage = async () => {
    setInfo(await getInfo(memberId!));
  };

  const handleClickChangePassword = () => {
    navigateToReplacePath(RoutePath.accountPassword);
  };

  return (
    <ModalContainer>
      <Popup
        headerText="My page"
        onClosePopup={onClosePopup}
        // bottomButtons={[
        //   <Button color={BUTTON_COLOR.white} text="Cancel" width={120}></Button>,
        //   <Button color={BUTTON_COLOR.black} text="Save" width={370}></Button>
        // ]}
      >
        <div className={styles["reset-password__body"]}>
          <div className={styles["-title"]}>Information</div>
          <InformationControl
            name="Name"
            value={info.nickname}
          ></InformationControl>
          <InformationControl
            name="ID"
            value={info.username}
          ></InformationControl>
          <InformationControl
            name="E-mail"
            value={info.email}
          ></InformationControl>
          <InformationControl
            name="Password"
            control={
              <Button
                text="Change Password"
                width={144}
                size={BUTTON_SIZE[80]}
                color={BUTTON_COLOR.white}
                onClick={handleClickChangePassword}
              />
            }
          />
        </div>
      </Popup>
    </ModalContainer>
  );
}
