import Checkbox from 'components/CheckBox/CheckBox';
import Highlighting from 'components/Highlighting/Highlighting';
import { useEffect, useState } from 'react';
import styles from './MemberItem.module.scss';
import { MemberItemProps } from './MemberItem.type';

export default function MemberItem({ member, onClickMemberName, searchValue }: MemberItemProps) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(member.checked);
  }, [member.checked]);



  return (
    <div className={styles["member-item__container"]}>
      <div className={styles["member-item__wrap"]}>
        <div className={styles["-checkbox"]}>
          <Checkbox onChange={(e) => { onClickMemberName(e, member) }} checked={checked}></Checkbox>
        </div>
        <div className={styles["-name"]}><Highlighting value={member.name} keyword={searchValue}></Highlighting></div>
        <div className={styles["-userId"]}><Highlighting value={String(member.userId)} keyword={searchValue}></Highlighting></div>
        <div className={styles["-email"]}><Highlighting value={member.email} keyword={searchValue}></Highlighting></div>
      </div>
    </div>
  );
};