import ModalContainer from "components/Container/Modal/ModalContainer";
import Popup from "../Popup";
import styles from "./Alert.module.scss";
import { AlertProps } from "./Alert.type";

export default function Alert({ children, firstButton, secondButton, onClosePopup }: AlertProps) {
  return (
    <ModalContainer>
      <Popup onClosePopup={onClosePopup} bottomButtons={[
        firstButton ? firstButton : <></>, secondButton ? secondButton : <></>
      ]} isAlert>
        <div className={styles["alert__body"]}>
          <div className={styles["alret-text"]}>
            {children}
          </div>
        </div>
      </Popup>
    </ModalContainer>
  )
}