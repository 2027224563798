export const MAP_OPTIONS = {
  MIN_ZOOM: 4,
  MAX_ZOOM: 21,
  INITIAL_ZOOM: 9,
  MIN_SCALE_WIDTH: 50,
  MAX_SCALE_WIDTH: 80,
};

export const AIS_MIN_ZOOM = 8.5;

export const MAP_SCALES: { value: number; displayValue: string }[] = [
  {
    value: 2,
    displayValue: "2 m",
  },
  {
    value: 5,
    displayValue: "5 m",
  },
  {
    value: 10,
    displayValue: "10 m",
  },
  {
    value: 20,
    displayValue: "20 m",
  },
  {
    value: 50,
    displayValue: "50 m",
  },
  {
    value: 100,
    displayValue: "100 m",
  },
  {
    value: 200,
    displayValue: "200 m",
  },
  {
    value: 500,
    displayValue: "500 m",
  },
  {
    value: 1000,
    displayValue: "1 km",
  },
  {
    value: 2000,
    displayValue: "2 km",
  },
  {
    value: 5000,
    displayValue: "5 km",
  },
  {
    value: 10000,
    displayValue: "10 km",
  },
  {
    value: 20000,
    displayValue: "20 km",
  },
  {
    value: 50000,
    displayValue: "50 km",
  },
  {
    value: 100000,
    displayValue: "100 km",
  },
  {
    value: 200000,
    displayValue: "200 km",
  },
  {
    value: 500000,
    displayValue: "500 km",
  },
  {
    value: 1000000,
    displayValue: "1000 km",
  },
  {
    value: 2000000,
    displayValue: "2000 km",
  },
  {
    value: 5000000,
    displayValue: "5000 km",
  },
];
