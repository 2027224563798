import classNames from 'classnames';
import { DATE_FORMAT } from 'constants/common';
import { ICON } from 'constants/icons';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from "./Calendar.module.scss";
import { CalendarProps } from './Calendar.type';
import "./react-datepicker.css";

export default function Calendar({ calendarDays, startDate, endDate, handleDate, width = 240, disabled, resetDate, placeholder, useQuery = false }: CalendarProps) {
  const [calendarStartDate, setCalendarStartDate] = useState<Date | null>(null);
  const [calendarEndDate, setCalendarEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setCalendarStartDate(startDate || null);
  }, [startDate])

  useEffect(() => {
    setCalendarEndDate(endDate || null);
  }, [endDate])

  useEffect(() => {
    setCalendarStartDate(null);
    setCalendarEndDate(null);
  }, [resetDate])

  const getDayClassName = (date: Date) => {
    const day = date.getDay();

    if (day === 6) { // 토요일
      return 'saturday';
    } else if (day === 0) { // 일요일
      return 'sunday';
    }
    return '';
  };

  const formatWeekDay = (name: string) => {
    return name.toUpperCase().substr(0, 3);
  };

  const onChangeRaw = (event?: React.FocusEvent<HTMLInputElement>) => {
    if (!event || !(event.currentTarget instanceof HTMLInputElement)) return;
    event.preventDefault();
  }

  const handleChangeRange = (dateRange: (Date | null)[]) => {
    setCalendarStartDate(dateRange[0]);
    setCalendarEndDate(dateRange[1]);
    const newDateRange = dateRange.map(date => {
      const newDate = dayjs(date).format(DATE_FORMAT);

      return newDate === 'Invalid Date' ? null : newDate;
    });
    handleDate && handleDate(newDateRange);
  }

  return (
    <div style={{ width: width }} className={classNames([`${disabled ? 'calendar-disabled' : ''}`, styles["container"]])}>
      <i className={classNames([styles.ico, styles['-calendar']])}></i>
      <DatePicker
        disabled={disabled}
        placeholderText={placeholder ? placeholder : `${DATE_FORMAT} ~ ${DATE_FORMAT}`}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="custom_header">
            <div className="button_area">
              <button className="header-btn -left" onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                <img src={ICON.ICON_ARROW_CALENDAR_DOUBLE_LEFT} alt=""></img>
              </button>
              <button className="header-btn -left" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <img src={ICON.ICON_ARROW_CALENDAR_LEFT} alt=""></img>
              </button>
            </div>

            <div className="header-date">
              {dayjs(date).format('YYYY-MM')}
            </div>
            <div className="button_area">
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <img src={ICON.ICON_ARROW_CALENDAR_RIGHT} alt=""></img>
              </button>
              <button onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                <img src={ICON.ICON_ARROW_CALENDAR_DOUBLE_RIGHT} alt=""></img>
              </button>
            </div>
          </div>
        )}
        onChangeRaw={onChangeRaw}
        dateFormat={'YYYY-MM-dd'}
        selectsRange={true}
        startDate={calendarStartDate}
        endDate={calendarEndDate}
        onChange={handleChangeRange}
        isClearable={true}
        dayClassName={date => getDayClassName(date)}
        formatWeekDay={formatWeekDay}
        renderDayContents={(day, date) => {
          return <span className="day__text">{day}</span>;
        }}
      />
    </div>
  );
};

