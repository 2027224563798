import classNames from 'classnames';
import _ from "lodash";
import styles from "./Input.module.scss";
import { InputProps } from './Input.type';

export default function Input({
  width,
  height = 36,
  placeholder,
  type,
  readOnly = false,
  value,
  defaultValue,
  min,
  max,
  maxLength,
  onChange,
  id,
  autoComplete,
  inputRef,
  name,
  textStyle = 'h4',
  disabled = false,
  icon,
  showWarning,
  required,
  labelStyle,
  inputStyle,
  containerStyle,
  onKeyPressDown,
  onKeyPressUp,
  textAlign = 'left',
  colorMode = 'bright',
  warningText,
  pattern,
  step,
  debounce,
}: InputProps) {
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  }

  const debounceAction = _.debounce((value) => {
    handleChangeText(value);
  }, 500);

  return (
    <div className={classNames(styles["input__container"], styles[`${containerStyle}`])}>
      <label
        className={classNames(styles["input-label"], styles[`${labelStyle}`], styles[`${colorMode}`], { [styles["-warning"]]: showWarning }, { [styles["-readOnly"]]: readOnly }, { [styles["-disabled"]]: disabled })}
        style={{ width: width, height: height }}
      >
        {icon && <div className='pl-2'>{icon}</div>}
        {value !== undefined ?
          <input
            className={styles[`${inputStyle}`]}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autoComplete}
            id={id}
            min={min}
            max={max}
            maxLength={maxLength}
            onChange={debounce ? debounceAction : handleChangeText}
            readOnly={readOnly}
            type={type ?? 'text'}
            value={value || ""}
            ref={inputRef}
            name={name}
            required={required}
            onKeyDown={onKeyPressDown}
            onKeyUp={onKeyPressUp}
            style={{ textAlign: textAlign }}
            pattern={pattern}
            step={step}
          /> : <input
            className={styles[`${inputStyle}`]}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autoComplete}
            id={id}
            min={min}
            max={max}
            maxLength={maxLength}
            onChange={debounce ? debounceAction : handleChangeText}
            defaultValue={defaultValue || ""}
            readOnly={readOnly}
            type={type ?? 'text'}
            ref={inputRef}
            name={name}
            required={required}
            onKeyDown={onKeyPressDown}
            onKeyUp={onKeyPressUp}
            style={{ textAlign: textAlign }}
            pattern={pattern}
            step={step}
          />
        }


        {maxLength && value && (
          <div className={styles["length__container"]}>
            <span className={classNames({ [styles["-bold"]]: Number(value.length) > 0, [styles["-empty"]]: value.length === undefined || Number(value.length) === 0 })}>
              {(value && value.length && value.length.toLocaleString()) || 0}
            </span>
            <span className={classNames({ [styles["-normal"]]: Number(value.length) > 0, [styles["-empty"]]: value.length === undefined || Number(value.length) === 0 })}>/</span>
            <span className={classNames({ [styles["-normal"]]: Number(value.length) > 0, [styles["-empty"]]: value.length === undefined || Number(value.length) === 0 })}>{(maxLength && maxLength.toLocaleString()) || 0}</span>
          </div>
        )}
      </label>
      {showWarning &&
        <span className={styles["-warning-text"]}>{warningText}</span>
      }
    </div>

  );
};