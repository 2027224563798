import Loading from "components/Loading/Loading";
import { TOKEN_ROLES } from "constants/auth";
import { RoutePath } from "data/router/paths";
import useCustomNavigate from "hooks/common/useCustomNavigate";
import { useAuth } from "hooks/feature/login/useAuth";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { authStore } from "stores/auth-stores";

export default function LoginRoutes() {
  const { navigateToReplacePath } = useCustomNavigate();
  const { accessToken, userRoles, companyUrl } = authStore();
  const { redirectUser } = useAuth();

  useEffect(() => {
    if (!accessToken || userRoles.length === 0) return;
    if (userRoles.includes(TOKEN_ROLES.PROJECT_ADMIN || TOKEN_ROLES.SUPER_ADMIN)) { // admin
      navigateToReplacePath(RoutePath.company);
    } else { //user
      redirectUser(companyUrl);
    }
  }, [accessToken, userRoles, companyUrl]);


  return (
    accessToken ? <></> :
      <>
        <Outlet></Outlet>
        <Loading></Loading>
      </>
  )
}
